<template>
  <div class="metric-form-range">
    <div class="_form-item">
      <div class="_label">
        {{ $t('metricForm.start') }}
      </div>
      <m-input-number
        :value="goal.start"
        :disabled="props.goal.progressMeasurement === goalProgressMeasurement.alignedItems"
        class="_number-input _input-field"
        :placeholder="$t('general.empty')"
        :read-only="props.readOnly"
        :formatter="formatter"
        :parser="parser"
        @change="updateStartEnd($event, goal.end)"
        @input="validateStartEnd($event, goal.end)"
      />
      <div
        v-if="startError !== ''"
        class="_error"
      >
        {{ $t(`metricForm.${startError}`) }}
      </div>
    </div>
    <div class="_form-item">
      <div class="_label">
        {{ $t('metricForm.end') }}
      </div>
      <m-input-number
        :value="goal.end"
        :disabled="props.goal.progressMeasurement === goalProgressMeasurement.alignedItems"
        class="_number-input _input-field"
        :placeholder="$t('general.empty')"
        :read-only="props.readOnly"
        :formatter="formatter"
        :parser="parser"
        @change="updateStartEnd(goal.start, $event)"
        @input="validateStartEnd(goal.start, $event)"
      />
      <div
        v-if="endError !== ''"
        class="_error"
      >
        {{ $t(`metricForm.${endError}`) }}
      </div>
    </div>
    <div class="_form-item">
      <div class="_label">
        {{ $t('metricForm.metric') }}
      </div>

      <m-text-field
        class="_input-field"
        :value="goal.metric"
        :disabled="props.goal.progressMeasurement === goalProgressMeasurement.alignedItems"
        :max-length="metricMaxLen"
        :read-only="props.readOnly"
        @change="updateMetric($event)"
        @input="validateMetric($event)"
      />
    </div>
  </div>
  <div
    v-if="continuousError !== ''"
    class="_error"
  >
    {{ $t(`metricForm.${continuousError}`) }}
  </div>
  <div
    v-if="metricError !== ''"
    class="_error"
  >
    {{ $t(`metricForm.${metricError}`, {length: metricMaxLen}) }}
  </div>
</template>

<script setup>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProgressMeasurement from '@/composables/goal/progress-measurement';
import { computed, ref } from 'vue';
import { goalProgressMeasurement, numberFormat } from 'shared/constants.json';
import { numberFormatter, numberParser } from '@/lib/props/number';

const props = defineProps({
  goal: { type: Object, required: true },
  readOnly: { type: Boolean, default: false },
});

const emit = defineEmits(['update-metric', 'update-start-end']);

const metricMaxLen = 16;

const { userLang } = useLoggedInUser();

const formatter = numberFormatter(numberFormat.number, userLang);
const parser = numberParser(numberFormat.number, userLang);

const startError = ref('');
const endError = ref('');
const metricError = ref('');
const continuousError = ref('');

const progressMeasurementSvc = useProgressMeasurement();

const goal = computed(() => ({
  start: progressMeasurementSvc.goalStart(props.goal),
  end: progressMeasurementSvc.goalEnd(props.goal),
  metric: progressMeasurementSvc.goalMetric(props.goal),
}));

const validateStartEnd = (start, end) => {
  if (goal.value.start === '' || goal.value.start === null || Number.isNaN(start)) {
    startError.value = 'notEmpty';
    return false;
  }

  if (goal.value.end === '' || goal.value.end === null || Number.isNaN(end)) {
    endError.value = 'notEmpty';
    return false;
  }

  if (start === end) {
    continuousError.value = 'notEqual';
    return false;
  }

  startError.value = '';
  endError.value = '';
  continuousError.value = '';
  return true;
};

const validateMetric = (value) => {
  if (goal.value.progressMeasurement === goalProgressMeasurement.alignedItems) {
    return false;
  }

  if (value === metricMaxLen) {
    metricError.value = 'metricMaxLen';
    return false;
  }

  metricError.value = '';
  return true;
};

const updateStartEnd = (start, end) => {
  if (validateStartEnd(start, end)) {
    emit('update-start-end', start, end);
  }

  startError.value = '';
  endError.value = '';
  continuousError.value = '';
};

const updateMetric = (value) => {
  if (validateMetric(value)) {
    emit('update-metric', value);
  }

  metricError.value = '';
};

</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .metric-form-range {
    width: 100%;
    display: flex;

    ._input-field {
        width: 10rem;
        margin-right: .8rem;
      }

    ._label {
        margin-bottom: .4rem;
        font-size: $font-size-2;
        color: $font-color-tertiary;
      }
  }

  ._error {
     margin-top: .8rem;
     font-size: $font-size-2;
     color: $error-color;
   }
</style>
