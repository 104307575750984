import { ERRORS } from '@/lib/constants';
import { accessPolicyType, validationError } from 'shared/constants.json';
import { indexToIdList } from '@/lib/sort-operation';
import { intersection } from 'lodash-es';
import { sortByArray } from 'shared/lib/sort';

export default function useGoalOperationValidator(collection, elReader, goalSelector, parentValidator, viewsService) {
  const violatesRule = (parentId, childrenIds) => {
    const parent = goalSelector.selectSingle(parentId);
    const toRealign = goalSelector.selectMultiple(childrenIds);
    if (parent === undefined) {
      return true;
    }

    if (toRealign.length !== childrenIds.length) {
      return true;
    }

    for (let i = 0; i < toRealign.length; i++) {
      const errs = parentValidator.validate({ toValidate: parent, selectRulesFrom: toRealign[i] });
      if (Object.keys(errs).length > 0) {
        return true;
      }
    }

    return false;
  };

  const isValidRealign = (operation) => {
    const { toRealignIds: childrenIds, newParentId } = operation;
    if (childrenIds.length === 0) {
      return { isValid: false, message: 'no node to realign' };
    }

    const childrenNodes = collection.value.filter((el) => childrenIds.includes(elReader.getId(el)));
    if (childrenNodes.find((node) => elReader.isParent(node)) !== undefined) {
      return { isValid: false, message: 'disabled/unfiltered node cant be realigned' };
    }
    if (childrenNodes.find((node) => !elReader.canWrite(node)) !== undefined) {
      return { isValid: false, message: 'non writable node cant be realigned' };
    }

    if (newParentId === null || newParentId === 0) {
      return { isValid: true };
    }

    const parentNodes = collection.value.filter((el) => [newParentId].includes(elReader.getId(el)));
    if (parentNodes.length === 0) {
      return { isValid: false, message: 'new parent is not in the collection' };
    }

    for (let i = 0; i < parentNodes.length; i++) {
      const parentChain = indexToIdList(elReader.getDragId(parentNodes[i]));
      if (intersection(childrenIds, parentChain).length > 0) {
        return { isValid: false, message: validationError.circularReferenceNotAllowed };
      }
    }

    if (violatesRule(newParentId, childrenIds)) {
      return { isValid: false, message: 'violates rule' };
    }

    return { isValid: true };
  };

  const isValidListSort = ({ list, anchor, toSort, sortAfter }) => {
    if (toSort.length > 1) {
      return { isValid: true };
    }

    if (anchor === toSort[0]) {
      return { isValid: false, message: 'no-op anchor == self' };
    }

    const aIdx = list.findIndex((e) => e === anchor);
    if (sortAfter === false && aIdx === 0) {
      return { isValid: true };
    }
    if (sortAfter === true && aIdx === list.length - 1) {
      return { isValid: true };
    }

    const target = sortAfter ? list[aIdx + 1] : list[aIdx - 1];
    if (target !== toSort[0]) {
      return { isValid: true };
    }

    return { isValid: false, message: 'no-op moving next to self' };
  };

  const isValidViewSort = (operation) => {
    if (!viewsService.canEditCurrentView.value) {
      return { isValid: false, message: ERRORS.SORT.VIEW_NOT_EDITABLE };
    }

    const list = collection.value.filter((el) => elReader.getDepth(el) === 0).map((el) => elReader.getId(el));
    const { anchorId: anchor, toSortIds: toSort, sortAfter } = operation;
    return isValidListSort({ list, anchor, toSort, sortAfter });
  };

  const isValidGoalChildrenSort = (operation) => {
    if (collection.value.find((el) => operation.toSortIds.includes(elReader.getId(el)) && elReader.isParent(el)) !== undefined) {
      return { isValid: false, message: 'disabled/unfiltered node cant be sorted as a goal children' };
    }

    const parent = goalSelector.selectSingle(operation.newParentId);
    if (parent === undefined) {
      throw new Error('cannot find parent in store');
    }
    if (![accessPolicyType.full, accessPolicyType.write].includes(parent.accessRight)) {
      return { isValid: false, message: ERRORS.SORT.PARENT_NOT_EDITABLE };
    }

    const list = parent.children.sort(sortByArray(parent.childrenSort)).map((c) => c.uid);
    const { anchorId: anchor, toSortIds: toSort, sortAfter } = operation;
    return isValidListSort({ list, anchor, toSort, sortAfter });
  };

  const isValidSort = (operation) => {
    const anchor = collection.value.find((el) => elReader.getId(el) === operation.anchorId);
    const dragDepth = elReader.getDepth(anchor);

    if (dragDepth === 0) {
      return isValidViewSort(operation);
    }
    return isValidGoalChildrenSort(operation);
  };

  const isValidDrag = (operation) => {
    const validators = [];
    if (operation.isRealign) {
      validators.push(isValidRealign(operation));
    }
    if (operation.isSort) {
      validators.push(isValidSort(operation));
    }

    return validators.reduce((res, next) => {
      if (res.isValid === false) {
        return res;
      }
      return next;
    }, { isValid: true });
  };

  return { isValidDrag };
}
