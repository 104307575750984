import useRepo from '@/nebula/repo';
import { RESULT, UID } from 'shared/api/query/constants';
import { customDatasourceProperty } from 'shared/constants.json';
import {
  dataSource as dataSourceConfig,
} from 'shared/api/query/configs.json';
import { ref } from 'vue';

export default function useMSPlannerDatasourceRepo() {
  const repo = useRepo(dataSourceConfig.model);

  const getSingleLoading = ref(false);
  const getSingle = (entity) => {
    getSingleLoading.value = true;
    return repo.query([{
      alias: RESULT,
      func: { name: UID },
      model: dataSourceConfig.model,
      uid: [entity.uid],
      children: [
        { attr: 'uid' },
        {
          attr: customDatasourceProperty.msPlannerPlans,
          filter: { func: { name: 'eq', attr: 'planId', args: [{ value: entity[customDatasourceProperty.msPlannerPlans][0].id }] } },
        },
      ],
    }]).then(() => repo.selectSingle(entity.uid)).finally(() => {
      getSingleLoading.value = false;
    });
  };

  return {
    getSingleLoading,
    getSingle,
  };
}
