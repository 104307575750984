import { doOAuthLogin } from '@/api';
import { eventRemover, eventer, messageCloser, messageEvent } from '@/lib/window-events';
import { fontSizes } from 'shared/font-sizes';
import { h } from 'vue';

export const windowMessage = {
  oauthInstall: 'oauthInstall',
  slackInstall: 'slackInstall',
  oauthLogin: 'oauthLogin',
};

export const initOAuthLogin = (connectUrl) => initOAuth(connectUrl, windowMessage.oauthLogin);
export const initOAuthInstall = (connectUrl) => initOAuth(connectUrl, windowMessage.oauthInstall);

const initOAuth = (connectUrl, listenTo) => new Promise((resolve, reject) => {
  const win = window.open(connectUrl, '', 'width=700,height=700');
  messageCloser(win).then(() => reject());

  const eventHandler = () => (event) => {
    if (event.data.message !== listenTo) {
      reject();
      return;
    }
    eventRemover()(messageEvent(), eventHandler);
    resolve(event);
  };

  eventer()(messageEvent(), eventHandler());
});

export default {
  name: 'OAuthRedirect',
  render() {
    const missingOpener = [
      h(
        'div',
        { style: { padding: '4rem', display: 'flex', flexDirection: 'column', alignItems: 'center' } },
        [
          h('div', { style: { textAlign: 'center', paddingLeft: '1.2rem', fontSize: fontSizes.h2 } }, [this.$t('oauth.missingOpener')]),
          h('div', { style: { paddingLeft: '1.2rem' } }, [h('m-btn', { props: { large: true, icon: 'reload', href: '/#/login', target: '_blank', color: 'primary' } }, [this.$t('oauth.retry')])]),
        ],
      ),
    ];

    return h('div', {}, !this.missingOpener ? [] : missingOpener);
  },
  data() {
    return { missingOpener: false, started: false };
  },
  methods: {
    start() {
      if (typeof this.$route.query.action !== 'undefined') {
        this.handle(this.$route.query.action);
        return;
      }

      if (typeof this.$route.query.oauthRedirect !== 'undefined') {
        window.location = this.$route.query.oauthRedirect;
        return;
      }

      if (typeof this.$route.query.datasource_install_status !== 'undefined') {
        this.handleSheets();
        return;
      }
      if (typeof this.$route.query.jira_install_status !== 'undefined') {
        this.handleJira();
        return;
      }
      if (typeof this.$route.query.slack_bot_install_status !== 'undefined') {
        this.handleSlack();
      }
    },
    login(provider) {
      doOAuthLogin({ originURL: window.location.href }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        window.location.href = response.data.urls[provider];
      });
    },
    handle(action) {
      switch (action) {
        case 'login':
          this.login(this.$route.query.provider);
          break;
        case 'confirm-login':
          if (window.opener === null) {
            this.missingOpener = true;
            return;
          }

          window.opener.postMessage({
            message: windowMessage.oauthLogin,
            provider: decodeURIComponent(this.$route.query.provider),
            error: decodeURIComponent(this.$route.query.error),
            email: decodeURIComponent(this.$route.query.email),
          }, window.opener.location);
          window.close();
          break;
        default:
          break;
      }
    },
    handleSheets() {
      window.opener.postMessage({ message: windowMessage.oauthInstall, status: this.$route.query.datasource_install_status }, window.opener.location);
      window.close();
    },
    handleJira() {
      window.opener.postMessage({ message: windowMessage.oauthInstall, status: this.$route.query.jira_install_status }, window.opener.location);
      window.close();
    },

    handleSlack() {
      window.opener.postMessage({ message: windowMessage.slackInstall, status: this.$route.query.slack_bot_install_status }, window.opener.location);
      window.close();
    },
  },
  created() {
    this.start();
  },
};
