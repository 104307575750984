<template>
  <div class="ms-planner-form">
    <m-content
      v-if="!getPlanDetailsLoading && hasError"
      :padding-top="0"
      :padding-x="11"
      :padding-bottom="2"
    >
      <data-source-query-error
        :data-source="dataSource"
        :error="error"
      />
    </m-content>
    <template v-if="!hasConnectionError">
      <m-content
        :padding-top="0"
        :padding-x="11"
        :padding-bottom="2"
      >
        <div class="_item">
          <div class="_label">
            {{ $t('msPlannerForm.aggregationLabel') }}
          </div>
          <m-select
            v-model:value="localAggregation"
            :items="aggregationModes"
            :placeholder="$t('general.all')"
            return-object
            full-width
            match-trigger-width
            has-tooltip
            tooltip-placement="right"
          >
            <template #item-tooltip="{ item }">
              {{ $t(item.item.tooltip) }}
            </template>
          </m-select>
        </div>
        <div class="_item">
          <div class="_label">
            {{ $t('msPlannerForm.selectHint') }}
          </div>
          <div class="_item">
            <div class="_label">
              {{ $t('msPlannerForm.selectPlanLabel') }}
            </div>
            <m-select
              v-model:value="localQuery.planId"
              :items="dataSource[customDatasourceProperty.msPlannerPlans]"
              value-key="id"
              item-text="name"
              full-width
              match-trigger-width
              show-search
              @change="changePlan"
            />
          </div>
          <template
            v-if="selectedPlan !== null && dataSource[customDatasourceProperty.msPlannerPlans].length > 0"
          >
            <div class="_item">
              <div class="_label">
                {{ $t('msPlannerForm.selectBucketsLabel') }}
              </div>
              <m-select
                v-model:value="localQuery.bucketIDs"
                :items="buckets"
                :loading="getPlanDetailsLoading"
                :placeholder="$t('general.all')"
                value-key="id"
                item-text="name"
                full-width
                match-trigger-width
                multiple
              />
            </div>
            <div class="_item">
              <div class="_label">
                {{ $t('msPlannerForm.selectLabelsLabel') }}
              </div>
              <m-select
                v-model:value="localQuery.labelIDs"
                :items="labels"
                :loading="getPlanDetailsLoading"
                :placeholder="$t('general.all')"
                value-key="id"
                item-text="name"
                full-width
                match-trigger-width
                show-search
                multiple
              />
            </div>
          </template>
        </div>
        <div
          v-if="localQuery.planId !== null"
          class="_item"
          :style="{ marginTop: '2rem' }"
        >
          <always-sync-checkbox v-model:value="localQuery.alwaysSync" />
        </div>
      </m-content>
      <m-divider xs />
      <template v-if="localQuery.uid === undefined">
        <m-content padding-small>
          <div class="_actions">
            <div class="_btns">
              <m-btn
                class="_btn"
                @click="$emit('close')"
              >
                {{ $t('general.cancel') }}
              </m-btn>
              <m-btn
                color="primary"
                class="_btn"
                :loading="mutateLoading"
                :disabled="!isValid"
                @click="save"
              >
                {{ $t('general.save') }}
              </m-btn>
            </div>
          </div>
        </m-content>
      </template>
      <template v-else>
        <m-card-item
          icon="save"
          :loading="mutateLoading"
          :padding-x="8"
          :disabled="!isValid"
          @click="save"
        >
          {{ $t('general.save') }}
        </m-card-item>
        <m-card-item
          icon="sync"
          :loading="syncLoading"
          :padding-x="8"
          :disabled="!isValid || hasError"
          @click="syncNow"
        >
          {{ $t('dataSource.syncNow') }}
        </m-card-item>
        <m-card-item
          icon="delete"
          :padding-x="8"
          :loading="deleteLoading"
          @click="deleteMsPlannerQuery"
        >
          {{ $t('general.delete') }}
        </m-card-item>
      </template>
    </template>
  </div>
</template>

<script setup>
import AlwaysSyncCheckbox from '@/components/datasource/AlwaysSyncCheckbox.vue';
import DataSourceQueryError from '@/components/datasource/DataSourceQueryError.vue';
import useConfirmDialog from '@/composables/confirm-dialog';
import useMSPlanner from '@/composables/integrations/ms-planner/ms-planner';
import useSnackBar from '@/composables/snackbar';
import { camelCase } from 'lodash-es';
import { computed, ref, watch } from 'vue';
import { copy } from 'shared/lib/copy';
import { customDatasourceProperty, dataSourceQueryGenericError, msPlannerAggregationMode, msPlannerAggregationResource } from 'shared/constants.json';
import { isEmpty, isNullOrUndefined } from 'shared/lib/object/object';
import { logCatch } from '@/lib/logger/logger';
import { useI18n } from 'vue-i18n';

const snackBar = useSnackBar();
const { t } = useI18n();

const props = defineProps({
  dataSource: {
    type: Object,
    required: true,
  },
  query: {
    type: Object,
    default: () => null,
  },
  goal: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['close', 'deleted']);

const {
  createQuery,
  createQueryLoading,
  updateQuery,
  updateQueryLoading,
  deleteQuery,
  deleteQueryLoading: deleteLoading,
  getPlanDetails,
  getPlanDetailsLoading,
} = useMSPlanner();
const mutateLoading = computed(() => createQueryLoading.value || updateQueryLoading.value);

const syncLoading = ref(false);

const aggregationModes = [
  { aggregationMode: msPlannerAggregationMode.percentage, aggregationResource: msPlannerAggregationResource.task },
  { aggregationMode: msPlannerAggregationMode.percentage, aggregationResource: msPlannerAggregationResource.checklist },
  { aggregationMode: msPlannerAggregationMode.absolute, aggregationResource: msPlannerAggregationResource.task },
  { aggregationMode: msPlannerAggregationMode.absolute, aggregationResource: msPlannerAggregationResource.checklist },
].map((e) => ({
  value: e,
  text: t(`msPlanner.aggregationMode.${camelCase(e.aggregationMode)}.${camelCase(e.aggregationResource)}.label`),
  tooltip: t(`msPlanner.aggregationMode.${camelCase(e.aggregationMode)}.${camelCase(e.aggregationResource)}.description`),
}));
const localAggregation = ref(aggregationModes[0]);

const defaultMSPlannerQuery = () => ({
  aggregationMode: msPlannerAggregationMode.percentage,
  aggregationResource: msPlannerAggregationResource.task,
  planId: null,
  planName: null,
  bucketIDs: [],
  bucketNames: [],
  labelIDs: [],
  labelNames: [],
});
const localQuery = ref(defaultMSPlannerQuery());
if (props.query !== null) {
  localQuery.value = copy(props.query);
  localAggregation.value = aggregationModes.find(({ value }) => value.aggregationMode === props.query.aggregationMode && value.aggregationResource === props.query.aggregationResource);
}

const hasConnectionError = computed(() => [dataSourceQueryGenericError.incompleteSetup, dataSourceQueryGenericError.unauthenticated].includes(props.dataSource[customDatasourceProperty.dataSourceError]));
const hasDataSourceError = computed(() => !isNullOrUndefined(props.dataSource[customDatasourceProperty.dataSourceError]));
const hasError = computed(() => hasDataSourceError.value || !isNullOrUndefined(props.query.error));
const error = computed(() => {
  if (hasDataSourceError.value) {
    return props.dataSource[customDatasourceProperty.dataSourceError];
  }
  if (hasError.value) {
    return props.query.error;
  }
  return null;
});

const selectedPlan = computed(() => {
  if (localQuery.value.planId === null) {
    return null;
  }

  const plan = props.dataSource[customDatasourceProperty.msPlannerPlans].find((r) => r.id === localQuery.value.planId);
  if (plan === undefined) {
    return null;
  }

  return plan;
});

const changePlan = () => {
  localQuery.value = {
    ...localQuery.value,
    ...defaultMSPlannerQuery(),
    aggregationMode: localQuery.value.aggregationMode,
    aggregationResource: localQuery.value.aggregationResource,
    planId: localQuery.value.planId,
  };
};

watch(selectedPlan, (newValue, oldValue) => {
  if (isNullOrUndefined(newValue)) {
    return;
  }
  if (!isNullOrUndefined(oldValue) && newValue.id === oldValue.id) {
    return;
  }
  getPlanDetails(props.dataSource, newValue).catch(() => {
    snackBar.error();
  });
}, { immediate: true });

const buckets = computed(() => {
  if (selectedPlan.value === null) {
    return [];
  }
  if (isNullOrUndefined(selectedPlan.value.buckets)) {
    return [];
  }
  return selectedPlan.value.buckets;
});
const labels = computed(() => {
  if (selectedPlan.value === null) {
    return [];
  }
  if (isNullOrUndefined(selectedPlan.value.labels)) {
    return [];
  }
  return selectedPlan.value.labels.map((label) => {
    if (isEmpty(label.name)) {
      return { ...label, name: t(`msPlannerForm.labels.${label.id}`) };
    }
    return label;
  });
});
const selectedBuckets = computed(() => {
  if (localQuery.value.bucketIDs.length === 0) {
    return [];
  }
  return buckets.value.filter((b) => localQuery.value.bucketIDs.includes(b.id));
});
const selectedLabels = computed(() => {
  if (localQuery.value.labelIDs.length === 0) {
    return [];
  }
  return labels.value.filter((b) => localQuery.value.labelIDs.includes(b.id));
});

const isValid = computed(() => selectedPlan.value !== null);

const save = () => {
  if (localQuery.value.uid === undefined) {
    createMsPlannerQuery(localQuery.value);
    return;
  }

  updateMsPlannerQuery(localQuery.value);
};
const createMsPlannerQuery = (query) => {
  createQuery({
    ...query,
    ...localAggregation.value.value,
    planName: selectedPlan.value.name,
    bucketNames: selectedBuckets.value.map(({ name }) => name),
    labelNames: selectedLabels.value.map(({ name }) => name),
    dataSource: { uid: props.dataSource.uid },
    goal: { uid: props.goal.uid },
  }).then((query) => {
    localQuery.value = copy(query);
  }).catch(logCatch(() => {
    snackBar.error();
  }));
};
const updateMsPlannerQuery = (query) => {
  updateQuery({
    ...query,
    ...localAggregation.value.value,
    planName: selectedPlan.value.name,
    bucketNames: selectedBuckets.value.map(({ name }) => name),
    labelNames: selectedLabels.value.map(({ name }) => name),
    dataSource: { uid: props.dataSource.uid },
  }).then((query) => {
    localQuery.value = copy(query);
  }).catch(logCatch(() => {
    snackBar.error();
  }));
};
const syncNow = () => {
  syncLoading.value = true;
  updateQuery(props.goal.msPlannerQuery).then((query) => {
    localQuery.value = copy(query);
    snackBar.success(t('dataSource.successfullySynced'));
  }).catch(logCatch(() => {
    snackBar.error();
  })).finally(() => {
    syncLoading.value = false;
  });
};

const confirmDialog = useConfirmDialog();
const deleteMsPlannerQuery = () => {
  const deleteMethod = () => deleteQuery(props.goal.msPlannerQuery.uid).then(() => {
    emit('deleted');
    emit('close');
  }).catch(() => {
    snackBar.error();
  });

  confirmDialog.confirm({
    title: t('dataSource.deleteConnectionPrompt'),
    onOk() {
      deleteMethod();
    },
  });
};

</script>

<style scoped lang="scss" type="text/scss">
.ms-planner-form {
  ._two-col {
    display: grid;
    grid-template-columns: auto auto;
  }

  ._item {
    margin-bottom: 1.4rem;

    ._label {
      margin-bottom: .4rem;
      color: $font-color-secondary;

      &.-flex {
        display: flex;
        align-items: center;
        gap: .4rem;
      }

      ._icon {
        margin-left: .4rem;
      }
    }

    ._label-value {
      color: $font-color-primary;
    }
  }

  ._actions {
    display: flex;

    ._btns {
      display: flex;
      margin-left: auto;

      ._btn {
        margin-left: .4rem;
      }
    }
  }
}
</style>
