import { MANUAL_SORTING } from '@/lib/constants';
import { computed } from 'vue';
import { guid } from 'shared/lib/uuid';
import { idFromIndex } from '@/lib/sort-operation';
import { initIdList } from '@/lib/sort';

export default function useGoalTree(viewsService, viewParamsService, filterService, sort, goalSelector, goals, expandSvc, childrenKey) {
  const goalTreeUid = guid();

  const calculateChildren = (parent) => {
    if (parent.children === undefined) {
      return [];
    }

    const children = parent.children.reduce((res, next) => {
      const goal = goalSelector.selectSingle(next.uid);
      if (goal === undefined) {
        return res;
      }

      if (childGoalInFilter(goal, parent)) {
        res.push(goal);
        return res;
      }

      return res;
    }, []);
    return sort(
      children,
      [
        ...viewParamsService.order.value,
        {
          attr: MANUAL_SORTING,
          sorting: initIdList(parent.childrenSort.map(({ uid }) => (uid)), parent.children.map((c) => c.uid)).map((uid) => ({ uid })),
        },
      ],
    );
  };

  const hasChildren = (goal) => {
    if (goal.children === undefined) {
      return false;
    }
    for (let i = 0; i < goal.children.length; i++) {
      const child = goalSelector.selectSingle(goal.children[i].uid);
      if (child === undefined || child.completelyLoaded !== true) {
        return true;
      }

      if (childGoalInFilter(child, goal)) {
        return true;
      }
    }

    return false;
  };

  const childGoalInFilter = (child, parent) => {
    if (!filterService.inDefaultFilter(child)) {
      return false;
    }
    if (viewParamsService.applyFilterOnFirstLevelOnly.value && !parent.isParent) {
      return true;
    }
    return filteredStateGoals.value[child.uid] !== undefined;
  };

  const filteredStateGoals = computed(() => goalsWithParents.value.reduce((res, next) => {
    if (!filterService.inFilter(next)) {
      return res;
    }

    res[next.uid] = next;
    return res;
  }, {}));

  const goalsWithParents = computed(() => goals.value
    .filter((g) => g.completelyLoaded === true)
    .map((g) => ({
      ...g,
      parents: g.parents.map((p) => {
        const parent = goals.value.find((g) => g.uid === p.uid);
        if (parent === undefined) {
          return p;
        }
        return parent;
      }),
    })));

  const rootElements = computed(() => {
    const parentsInFilter = (parents) => {
      const parentIds = parents.map((p) => p.uid);
      const stateParents = goalsWithParents.value.filter((g) => parentIds.includes(g.uid));
      if (stateParents.length === 0) {
        // The `goalCascadeList` query retrieves all parents of root elements. Hence, if parents are not
        // in the state, we have to assume that they were not loaded because the goal is not a root element.
        // Therefore, I return true so the goal is not treated as a root element.
        return true;
      }

      for (let i = 0; i < stateParents.length; i++) {
        if (filterService.inFilter(stateParents[i])) {
          return true;
        }
      }

      return false;
    };

    return goalsWithParents.value.reduce((res, next) => {
      if (!filterService.inFilter(next)) {
        return res;
      }

      if (next.parents.length === 0) {
        res[next.uid] = { ...next, isParent: false };
        return res;
      }

      if (parentsInFilter(next.parents)) {
        return res;
      }

      if (!getShowParent(next)) {
        res[next.uid] = { ...next, isParent: false };
        return res;
      }

      const parents = next.parents.map((p) => goals.value.find((g) => g.uid === p.uid));
      parents.forEach((p) => {
        if (p === undefined) {
          return;
        }

        if (res[p.uid] === undefined) {
          res[p.uid] = {
            ...p,
            isParent: true,
          };
        }
      });
      return res;
    }, {});
  });
  const getShowParent = (goal) => {
    if (!viewParamsService.showParents.value) {
      return false;
    }

    return goal.parents.map((p) => goals.value.find((g) => g.uid === p.uid)).some((p) => p !== undefined);
  };

  const goalTree = computed(() => {
    const getIndex = (parentIndex, goal) => `${parentIndex}_${goal.uid}`;
    const buildTree = ({ parentIndex, indentation, show, length }) => (res, goal, i) => {
      if (!show) {
        return res;
      }

      const index = getIndex(parentIndex, goal);
      const parentId = idFromIndex(parentIndex, indentation - 1);
      const parents = parentId === 0 ? [] : [parentId];

      const next = {
        hasChildren: hasChildren(goal),
        isParent: goal.isParent === true,
        parents,
        entity: { uid: goal.uid },
        isLastItem: i === length - 1,
        isFirstItem: i === 0,
        indentation,
        [childrenKey]: [],
        index,
        guid: `${index}-${goalTreeUid}`,
      };

      const expanded = expandSvc.getExpand({ index }).value;
      if (!expanded) {
        res.push(next);
        return res;
      }

      const children = calculateChildren(goal);
      if (children.length === 0) {
        res.push(next);
        return res;
      }

      next[childrenKey] = children.reduce(buildTree({
        indentation: indentation + 1,
        show: true,
        parentIndex: index,
        length: children.length,
      }), []);
      res.push(next);
      return res;
    };

    const elements = Object.values(rootElements.value);
    return sort(elements, [...viewParamsService.order.value, {
      attr: MANUAL_SORTING,
      sorting: initIdList(viewsService.currentView.value.goalSort.map(({ uid }) => (uid)), elements.map((c) => c.uid)).map((uid) => ({ uid })),
    }], viewParamsService.showParents.value).reduce(buildTree({
      indentation: 0,
      show: true,
      parentIndex: 'root',
      length: elements.length,
    }), []);
  });

  return { goalTree };
}
