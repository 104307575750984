<template>
  <scroll-container class="temp-goal-list">
    <div
      ref="wrapperHeader"
      class="goals-list-wrapper-header"
    >
      <m-content
        :padding-x="6"
      >
        <goals-list-header
          ref="header"
          :slots="slots"
          :selected-goal-cycles="selectedCycles"
          :change-selected-goal-cycles="changeSelectedGoalCycles"
          :filter-props="defaultProps"
          :data-loading="dataLoading"
          :read-only="readOnly"
          :is-cycle-locked-on-view="false"
        />
      </m-content>
    </div>
    <div
      class="_table"
    >
      <div class="_header-portal-target">
        <div :ref="(el) => headerPortalTarget = el" />
      </div>
      <m-selectable
        v-model:value="selectedGoalIds"
        class="_cascade-table"
        :selector-class="selectorClass"
        scroll-container-class="temp-goal-list"
        select-area-class="temp-goal-list"
      >
        <r-goals-cascade-table
          v-if="headerPortalTarget !== null"
          :header-portal-target="headerPortalTarget"
          :header-width="headerWidth"
          :selected-cycles="selectedCycles"
          :base-filter="publishedAtFilter"
          :goal-fetcher-options="goalFetcherOptions"
          :on-item-click="onClick"
          :selected-goal-ids="selectedGoalIds"
          :item-class="selectorClass"
          :read-only="readOnly"
          :show-side-menu="showSideMenu"
          :show-right-click-menu="showRightClickMenu"
          :inline-editable="inlineEditable"
          show-errors
          :expand-local-storage-key-maker="expandLocalStorageKeyMaker"
          @data-loading="dataLoading = true"
          @data-loaded="dataLoading = false"
          @selection-right-click="handleSelectionRightClick"
          @show-menu="handleShowMenuClick"
        />
        <goals-context-menu
          v-if="!readOnly"
          ref="contextMenu"
          :goal-ids="selectedGoalIds"
          show-expand-buttons
        />
      </m-selectable>
    </div>
  </scroll-container>
</template>

<script>
import GoalsContextMenu from '@/components/goal/GoalsContextMenu.vue';
import GoalsListHeader from '@/components/goal/GoalsListHeader.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalProperty from '@/composables/property/goal-property';
import useInMemoryGoalCycle from '@/composables/goal-cycle/in-memory-goal-cycle';
import useInMemoryViews from '@/composables/saved-views/in-memory-views';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useOpenPeekMode from '@/composables/goal/open-peek-mode';
import usePersistedGoalCycle from '@/composables/goal-cycle/persisted-goal-cycle';
import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import useViewCascadeExpandKeyMaker from '@/composables/local-storage/view-cascade-expand-key-maker';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { computed, provide } from 'vue';
import { copy } from 'shared/lib/copy';
import { createPropsList } from '@/lib/props';
import { directProperties } from '@/lib/goal/properties';
import { guid } from 'shared/lib/uuid';
import { useI18n } from 'vue-i18n';

export default {
  name: 'TempGoalList',
  props: {
    id: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      default: () => null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    inlineEditable: {
      type: Boolean,
      default: false,
    },
    showSideMenu: {
      type: Boolean,
      default: false,
    },
    showRightClickMenu: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ScrollContainer,
    GoalsListHeader,
    GoalsContextMenu,
  },
  data() {
    return {
      headerWidth: 0,
      dataLoading: false,
      selectedGoalIds: [],
      selectorClass: 'goal-item',
      slots: [{ name: SLOTS.CYCLE_SELECTOR }, { name: SLOTS.PROPERTIES }, { name: SLOTS.WRAP_CELLS }, { name: SLOTS.SHOW_PARENTS }],
      headerPortalTarget: null,
    };
  },
  setup(props) {
    const { publishedAtFilter } = usePublishedAtFilter();
    const { composedSelectedCycles: persistedSelectedCycles } = usePersistedGoalCycle();
    const { composedSelectedCycles: selectedCycles, changeSelectedGoalCycles } = useInMemoryGoalCycle();
    changeSelectedGoalCycles(persistedSelectedCycles.value);

    const { loggedInUser } = useLoggedInUser();

    const { goalCycles } = useGoalCycle();
    const { t } = useI18n();
    const { properties: goalProperties } = useGoalProperty();

    const defaultProps = computed(() => createPropsList({
      properties: goalProperties.value,
      directProperties: directProperties((key) => t(key), goalCycles.value),
      userLang: loggedInUser.value.lang,
    }));

    const defaultView = {
      isTemp: true,
      params: {
        filter: copy(props.filter),
        order: [],
        props: defaultProps.value,
        applyFilterOnFirstLevelOnly: false,
        key: 'key',
      },
    };

    const application = computed(() => {
      if (props.id === '') {
        return guid();
      }

      return props.id.replace(/[^a-zA-Z 0-9]/g, '').replace(' ', '_');
    });

    const inMemoryViewsSvc = useInMemoryViews({
      defaultProps,
      defaultView,
      application: `temp_goal_list_${application.value}`,
    });

    provide(VIEWS_SERVICE, inMemoryViewsSvc);

    const expandLocalStorageKeyMaker = useViewCascadeExpandKeyMaker(inMemoryViewsSvc.currentView);

    const goalFetcherOptions = {
      includeChildren: true,
      includeParents: true,
      includeGrandparents: true,
    };

    const peekModeSvc = useOpenPeekMode();

    return {
      selectedCycles,
      changeSelectedGoalCycles,
      publishedAtFilter,
      goalFetcherOptions,
      defaultProps,
      currentView: inMemoryViewsSvc.currentView,
      expandLocalStorageKeyMaker,
      peekModeSvc,
    };
  },
  methods: {
    handleSelectionRightClick(event) {
      this.$refs.contextMenu.show(event);
    },
    onClick({ goalId }) {
      this.peekModeSvc.openGoal({ goalId });
    },
    setHeaderWidth() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.headerWidth = this.$refs.header.$el.clientWidth;
        });
      });
    },
    handleShowMenuClick() {
      this.$refs.wrapperHeader.scrollIntoView({ behavior: 'instant' });
      setTimeout(() => {
        this.$refs.header.openMenu();
      }, 100);
    },
  },
  mounted() {
    window.addEventListener('resize', this.setHeaderWidth);
    this.setHeaderWidth();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setHeaderWidth);
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  @import 'shared/assets/scss/padding';

  .temp-goal-list {
    position: relative;
    float: left;
    width: 100%;
    min-height: inherit;
    max-height: inherit;
    overflow: auto;

    .goals-list-wrapper-header {
      position: sticky;
      left: 0;
      background-color: white;
      z-index: 2;
      margin: 4rem 0 .6rem 0;

      @include layoutPaddingX();
    }

    ._table {
      display: inline-table;
      min-width: 100%;

      ._header-portal-target {
        position: sticky;
        top: 0;
        z-index: 2;
      }

      @include layoutPaddingX();
    }
  }
</style>
