import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useResourceSettings from '@/composables/logged-in-user-account/resource-settings';
import { computed } from 'vue';

export default function useGridPageAllowance() {
  const { accountSettings } = useAccountSettings();
  const { resourceSettings } = useResourceSettings();

  const privatePagesAllowance = computed(() => accountSettings.value.volumePrivateDashboards);
  const publicPagesAllowance = computed(() => accountSettings.value.volumePublicDashboards);

  const privatePagesCount = computed(() => resourceSettings.value.privateDashboardsCount);
  const publicPagesCount = computed(() => resourceSettings.value.publicDashboardsCount);

  const hasPrivateAllowance = computed(() => privatePagesAllowance.value < 0 || privatePagesAllowance.value > privatePagesCount.value);
  const hasPublicAllowance = computed(() => publicPagesAllowance.value < 0 || publicPagesAllowance.value > publicPagesCount.value);

  const privateAllowanceAvailable = computed(() => privatePagesAllowance.value - privatePagesCount.value);
  const publicAllowanceAvailable = computed(() => publicPagesAllowance.value - publicPagesCount.value);

  return {
    privatePagesCount,
    publicPagesCount,
    privatePagesAllowance,
    publicPagesAllowance,
    hasPrivateAllowance,
    hasPublicAllowance,
    privateAllowanceAvailable,
    publicAllowanceAvailable,
  };
}
