import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useRepo from '@/nebula/repo';
import useResourceSettings from '@/composables/logged-in-user-account/resource-settings';
import { automaticColor } from 'shared/lib/color';
import { computed, ref } from 'vue';
import { findInArray } from 'shared/lib/array/array';
import { optionColor } from 'shared/constants.json';
import { shallowCopy } from 'shared/lib/copy';
import { sortByArray } from 'shared/lib/sort';
import {
  space as spaceConfig,
} from 'shared/api/query/configs.json';
import { treeToArray } from '@/lib/tree/tree';

export default function useSpaces(search = ref('')) {
  const maxLevel = 7;

  const repo = useRepo(spaceConfig.model);

  const { loggedInUserAccount } = useLoggedInUserAccount();
  const { resourceSettings } = useResourceSettings();

  const spaceOrder = computed(() => {
    if (resourceSettings.value === undefined) {
      return [];
    }
    return resourceSettings.value.spaceOrder;
  });

  const spaces = computed(() => repo.entityList.value);
  const sortedSpaces = computed(() => shallowCopy(spaces.value).sort(sortByArray(spaceOrder.value)));

  const activeSpaces = computed(() => sortedSpaces.value.filter((s) => s.archivedAt === null)
    .map((s) => ({ ...s, parents: s.parents.filter((p) => repo.selectSingle(p.uid).archivedAt === null) })));
  const archivedSpaces = computed(() => sortedSpaces.value.filter((s) => s.archivedAt != null));

  const isMatch = (node, searchTerm) => node.title.toLowerCase().includes(searchTerm.toLowerCase());
  const hasMatch = (res, node) => node.match || res || node.children.reduce(hasMatch, false);

  const treeBuilder = (spaces) => {
    const mapChildMatches = (node) => ({
      ...node,
      containsMatch: node.children.reduce(hasMatch, false),
      children: node.children.map(mapChildMatches),
    });

    const mapChildren = (level, parentCollapsed) => (c) => {
      const node = findInArray({ haystack: spaces, needle: c.uid });
      if (node === null) {
        return c;
      }

      const collapsed = collapsedNodes.value.includes(node.uid);
      const children = spaces.filter((s) => s.parents.filter((p) => p.uid === node.uid).length > 0)
        .map(mapChildren(level + 1, collapsed || parentCollapsed));
      const childrenMaxLevel = children.reduce((acc, c) => (Math.max(acc, c.childrenMaxLevel)), level);
      return {
        ...node,
        children,
        level,
        childrenMaxLevel,
        match: isMatch(node, search.value),
        collapsed,
        parentCollapsed,
      };
    };

    const getIndex = (parentIndex, node) => `${parentIndex}_${node.uid}`;
    const mapIndex = (parentIndex) => (node) => {
      if (node.length === 0) {
        return {
          ...node,
          index: parentIndex,
          children: node.children.map(mapIndex(parentIndex)),
        };
      }
      const index = getIndex(parentIndex, node);
      return {
        ...node,
        index,
        children: node.children.map(mapIndex(index)),
      };
    };

    const mapSiblings = (node, index, array) => ({
      ...node,
      firstSibling: index === 0,
      lastSibling: index === array.length - 1,
      children: node.children.map(mapSiblings),
    });

    // TODO: transform this by using the tree lib
    // return arrayToTree(spaces)
    return spaces.filter((o) => o.parents.length === 0)
      .map(mapChildren(0, false))
      .map(mapChildMatches)
      .map(mapIndex('root'))
      .map(mapSiblings);
  };

  const collapsedNodes = ref([]);
  const toggleCollapse = (uid) => {
    if (collapsedNodes.value.includes(uid)) {
      collapsedNodes.value = collapsedNodes.value.filter((e) => e !== uid);
      return;
    }
    collapsedNodes.value.push(uid);
  };

  // This does not get re-calculated in vue 3.4. Most likely due to these changes: https://github.com/vuejs/core/pull/5912
  const activeSpacesTree = computed(() => treeBuilder(activeSpaces.value));
  const activeSpacesTreeFlat = computed(() => treeToArray(applyMappers(activeSpacesTree.value)));

  const allSpacesTree = computed(() => treeBuilder(sortedSpaces.value));
  const allSpacesTreeFlat = computed(() => treeToArray(applyMappers(allSpacesTree.value)));

  const mappers = ref([]);
  const setSpaceMappers = (array) => {
    mappers.value = array;
  };
  const applyMappers = (spaces) => {
    mappers.value.forEach((mapper) => {
      spaces = spaces.map(mapper);
    });
    return spaces;
  };

  const filteredSpaces = computed(() => activeSpacesTreeFlat.value.filter((s) => s.match === true));

  const colorfulSpaces = computed(() => activeSpaces.value.map((s) => {
    if (s.color === null) {
      return {
        ...s,
        color: automaticColor(s.title, optionColor.all),
      };
    }

    return s;
  }));

  const accountSpace = computed(() => activeSpacesTree.value.find((space) => space.title === loggedInUserAccount.value.companyName));

  const createSpace = (space) => repo.createSingle({
    ...space,
    account: { uid: loggedInUserAccount.value.uid },
  });

  const deleteSpace = (space) => repo.deleteSingle(space.uid);
  const deleteSpaces = (spaces) => repo.deleteMultiple(spaces.map((e) => e.uid));

  return {
    maxLevel,
    accountSpace,
    getList: repo.getList,
    selectSingle: repo.selectSingle,
    selectMultiple: repo.selectMultiple,
    createSpace,
    createSpaceLoading: repo.createLoading,
    updateSpace: repo.updateSingle,
    updateSpaces: repo.updateMultiple,
    updateSpaceLoading: repo.updateLoading,
    deleteSpace,
    deleteSpaces,
    deleteSpacesLoading: repo.deleteLoading,

    allSpaces: sortedSpaces,
    spaces: activeSpaces,
    archivedSpaces,

    allSpacesTreeFlat,
    activeSpacesTreeFlat,
    normalizedSpaces: activeSpacesTreeFlat,
    filteredSpaces,
    setSpaceMappers,
    toggleCollapse,

    colorfulSpaces,
  };
}
