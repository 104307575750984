import { computed, ref, watch } from 'vue';
import { difference, uniq } from 'lodash-es';

export default function useLoadExpanded(goalSelector, goalTree, cascadeExpand, goalFetcher) {
  const goalsToLoad = computed(() => {
    const val = goalTree.value.reduce((res, next) => {
      if (!cascadeExpand.getExpand(next).value) {
        return res;
      }

      const entity = goalSelector.selectSingle(next.entity.uid);
      const children = entity.children.filter((c) => {
        const g = goalSelector.selectSingle(c.uid);
        return g === undefined || !g.completelyLoaded;
      });

      if (children.length === 0) {
        return res;
      }

      res.showAsLoading.push(next.entity.uid);
      children.forEach((c) => {
        res.toLoad.push(c.uid);
      });

      return res;
    }, { toLoad: [], showAsLoading: [] });

    return { toLoad: uniq(val.toLoad), showAsLoading: uniq(val.showAsLoading) };
  });

  const showAsLoading = computed(() => goalsToLoad.value.showAsLoading);

  const toLoad = computed(() => goalsToLoad.value.toLoad);

  const itemsPerPage = 20;

  const currentlyLoading = ref([]);
  const queryChildren = (goalIds) => {
    currentlyLoading.value.push(...goalIds);
    goalFetcher.getGoalsByIds(goalIds, { page: 1, itemsPerPage }).then(() => {
      currentlyLoading.value = difference(currentlyLoading.value, goalIds);
    });
  };

  watch(() => toLoad.value, (newVal) => {
    const diff = difference(newVal, currentlyLoading.value).slice(0, itemsPerPage);
    if (diff.length === 0) {
      return;
    }
    queryChildren(diff);
  });

  watch(goalTree, (newVal) => {
    cascadeExpand.setDefaultValues(newVal);
  });

  return {
    showAsLoading,
    loadingGoals: toLoad,
  };
}
