<template>
  <m-dropdown
    v-model:value="showMenu"
    :title="$t('goalProgressMenu.optionLabel')"
    match-trigger-width
    block
  >
    <m-card-item
      icon="line-chart"
      no-padding
      list
    >
      {{ $t('goalProgressMenu.optionLabel') }}

      <template #right>
        <div class="_right">
          <div class="_text">
            {{ $t(`goalProgressMenu.progressDisplayOptions.${selectedOption}`) }}
          </div>
          <div class="_icon">
            <m-icon
              type="down"
              :color="$colors.grey.lighten1"
              size="11"
            />
          </div>
        </div>
      </template>
    </m-card-item>
    <template #overlay>
      <m-card
        no-padding
        list
      >
        <m-card-item
          v-for="item in options.all"
          :key="item"
          @click="changeDisplayOption(item)"
        >
          {{ $t(`goalProgressMenu.progressDisplayOptions.${item}`) }}
          <template #right>
            <m-icon
              v-if="item === selectedOption"
              type="check"
            />
          </template>
        </m-card-item>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script setup>
import { computed, ref } from 'vue';
import { progressDisplayOption as options } from 'shared/constants.json';

const showMenu = ref(false);

const props = defineProps({ progressDisplayOption: { type: [String, null], required: true } });

const emit = defineEmits(['input']);

const selectedOption = computed(() => {
  if (props.progressDisplayOption === undefined || props.progressDisplayOption === null) {
    return options.absolute;
  }

  return props.progressDisplayOption;
});

const changeDisplayOption = (val) => {
  if (!options.all.includes(val)) {
    return;
  }

  emit('input', val);
};

</script>

<style lang="scss" type="scss" scoped>
  ._right {
    display: flex;
    align-items: center;

    ._text {
      color: $font-color-secondary;
    }

    ._icon {
      margin-left: .4rem;
    }
  }
</style>
