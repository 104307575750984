// Sometimes we don't denormalize data, so we need to get the uid
import { addToDenormalize } from '@/nebula/denormalize';
import { createReferenceIndex, deleteReferenceEdge } from '@/nebula/references';
import { difference } from 'shared/lib/array/array';
import { getSchema, getType } from '@/nebula/type';
import { isEqual } from 'lodash-es';

const getUid = (value) => {
  if (typeof value === 'number') {
    return value;
  }

  if (value.uid !== undefined) {
    return value.uid;
  }

  throw new Error('uid not found');
};

const addReverseEdges = (state, references, schema, re, uid, e) => {
  if (state[re.model][uid] === undefined) {
    return;
  }

  const reSchema = getSchema(schema[re.model].schema[re.rEdge]);
  if (reSchema.simple) {
    state[getType(reSchema)][e.uid] = { uid: e.uid };
  }

  if (Array.isArray(state[re.model][uid][re.rEdge])) {
    if (state[re.model][uid][re.rEdge].includes(e.uid)) {
      return;
    }

    state[re.model][uid][re.rEdge].push(e.uid);
    createReferenceIndex(references, schema[re.model], [{ uid, [re.rEdge]: state[re.model][uid][re.rEdge] }]);
    return;
  }
  if (state[re.model][uid][re.rEdge] === e.uid) {
    return;
  }
  state[re.model][uid][re.rEdge] = e.uid;
  createReferenceIndex(references, schema[re.model], [{ uid, [re.rEdge]: state[re.model][uid][re.rEdge] }]);
};

const removeReverseEdges = (state, references, schema, re, uid, e) => {
  if (state[re.model][uid] === undefined || state[re.model][uid][re.rEdge] === undefined) {
    return;
  }
  if (Array.isArray(state[re.model][uid][re.rEdge])) {
    const i = state[re.model][uid][re.rEdge].findIndex((v) => v === e.uid);
    if (i === -1) {
      return;
    }
    state[re.model][uid][re.rEdge].splice(i, 1);
    deleteReferenceEdge(references, uid, e.uid, { edge: re.rEdge, type: re.model });
    return;
  }
  if (state[re.model][uid][re.rEdge] !== e.uid) {
    return;
  }
  state[re.model][uid][re.rEdge] = undefined;
  deleteReferenceEdge(references, uid, e.uid, { edge: re.rEdge, type: re.model });
};

const findCurrentReverseEdgeState = (references, reverseEdgeDefinition, uid) => {
  if (references[uid] === undefined) {
    return undefined;
  }
  const res = [];
  Object.values(references[uid]).forEach((next) => {
    if (next.edges.some((refEdge) => refEdge.edge === `~${reverseEdgeDefinition.rEdge}` && refEdge.type === reverseEdgeDefinition.model)) {
      res.push(next.uid);
    }
  });
  return res;
};

export const syncReverseEdges = (state, references, schema, toDenormalize, e, m) => {
  const reverseEdgesDefinition = schema[m].reverseEdges;
  if (reverseEdgesDefinition === undefined) {
    return;
  }
  const edges = Object.keys(reverseEdgesDefinition);
  for (let i = 0; i < edges.length; i++) {
    const edge = edges[i];
    const reverseEdgeDefinition = reverseEdgesDefinition[edge];
    const oldVal = findCurrentReverseEdgeState(references, reverseEdgeDefinition, e.uid);
    const newVal = e[edge];

    if (Array.isArray(newVal)) {
      const newValIds = newVal.map((n) => getUid(n));
      if (isEqual(newValIds, oldVal)) {
        continue;
      }
      if (oldVal === undefined || oldVal.length === 0) {
        for (let j = 0; j < newValIds.length; j++) {
          addReverseEdges(state, references, schema, reverseEdgeDefinition, newValIds[j], e);
          addToDenormalize(newValIds[j], reverseEdgeDefinition.model, toDenormalize);
        }
        continue;
      }
      const toAdd = difference(newValIds, oldVal);
      for (let j = 0; j < toAdd.length; j++) {
        addReverseEdges(state, references, schema, reverseEdgeDefinition, toAdd[j], e);
        addToDenormalize(toAdd[j], reverseEdgeDefinition.model, toDenormalize);
      }
      const toRemove = difference(oldVal, newValIds);
      for (let j = 0; j < toRemove.length; j++) {
        removeReverseEdges(state, references, schema, reverseEdgeDefinition, toRemove[j], e);
        addToDenormalize(toRemove[j], reverseEdgeDefinition.model, toDenormalize);
      }
      continue;
    }

    if (typeof newVal === 'object') {
      const newValId = getUid(newVal);
      if (oldVal === undefined) {
        addReverseEdges(state, references, schema, reverseEdgeDefinition, newValId, e);
        addToDenormalize(newValId, reverseEdgeDefinition.model, toDenormalize);
        continue;
      }
      if (newValId === oldVal[0]) {
        continue;
      }
      addReverseEdges(state, references, schema, reverseEdgeDefinition, newValId, e);
      removeReverseEdges(state, references, schema, reverseEdgeDefinition, oldVal[0], e);
      addToDenormalize(newValId, reverseEdgeDefinition.model, toDenormalize);
      addToDenormalize(oldVal[0], reverseEdgeDefinition.model, toDenormalize);
    }
    if (newVal === undefined && e.deletedAt !== undefined) {
      if (oldVal === undefined) {
        continue;
      }
      removeReverseEdges(state, references, schema, reverseEdgeDefinition, oldVal[0], e);
      addToDenormalize(oldVal[0], reverseEdgeDefinition.model, toDenormalize);
    }
  }
};
