<template>
  <div
    ref="root"
    :class="['m-draggable', dragging ? '-dragging' : '']"
  >
    <slot />
    <teleport
      v-if="true"
      to="#drag"
    >
      <div
        :id="key"
        :style="{ top: `${clientY - offsetY}px`, left: `${clientX - offsetX}px` }"
        class="_ghost-item"
      />
    </teleport>
  </div>
</template>

<script setup>
import colors from 'shared/colors';
import useMDraggable from 'shared/components/base/m-draggable';
import useScroll from 'shared/composables/scroll';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, toRef, watch } from 'vue';
import { debounce } from 'lodash-es';
import { guid } from 'shared/lib/uuid';
import { upToContainerWithClass } from 'shared/lib/dom';

const props = defineProps({
  draggableItemClass: {
    type: String,
    default: '',
  },
  dragoverItemClass: {
    type: String,
    required: true,
  },
  ghostItemClass: {
    type: String,
    default: '',
  },
  ghostItemStyle: {
    type: Object,
    default: () => ({}),
  },
  recreateKey: {
    type: [String, Array, Object, Boolean, Number],
    default: '',
  },
  dataKey: {
    type: String,
    default: 'id',
  },
  dragBetweenHeight: {
    type: Number,
    default: 8,
  },
  canDragOver: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  canDragOverTop: {
    type: Boolean,
    default: false,
  },
  canDragOverBottom: {
    type: Boolean,
    default: false,
  },
  canDragOverRight: {
    type: Boolean,
    default: false,
  },
  canDragOverLeft: {
    type: Boolean,
    default: false,
  },
  scrollContainerClass: {
    type: String,
    default: '',
  },
  scrollHandler: {
    type: Function,
    default: null,
  },
  ghostItemMovementAxes: {
    type: String,
    default: 'any',
    validator(val) {
      return ['any', 'x', 'y'].includes(val);
    },
  },
});

const scroll = ({ deltaX, deltaY }) => {
  if (props.scrollHandler !== null) {
    props.scrollHandler({ deltaX, deltaY });
    return;
  }

  dragAreaBounds.value.scrollTop += deltaY;
  dragAreaBounds.value.scrollLeft += deltaX;
};
const scrollSvc = useScroll(scroll);

const emit = defineEmits(
  ['over-top', 'over', 'over-bottom', 'over-right', 'over-left', 'cancel', 'set-drag-item', 'click', 'drag-drop'],
);

const root = ref(null);

const dragItem = ref(null);
const dragging = ref(false);
const mouseDown = ref(false);
const offsetX = ref(0);
const offsetY = ref(0);
const clientX = ref(0);
const clientY = ref(0);
const debounced = ref(null);
const mouseMoveFn = ref(null);
const mouseDownListeners = ref([]);
const localGhostItemClass = computed(() => {
  if (props.ghostItemClass === '') {
    return props.draggableItemClass;
  }

  return props.ghostItemClass;
});
const key = computed(() => guid());

const dragArea = ref(document.body);
const dragAreaBounds = ref(document.body);

const setDragAreaBounds = () => {
  dragAreaBounds.value = upToContainerWithClass(root.value, props.scrollContainerClass);
};
onMounted(() => setDragAreaBounds());

const dragAreaBoundsDimensions = computed(() => dragAreaBounds.value.getBoundingClientRect());

const findKey = (element) => {
  if (typeof element.dataset[props.dataKey] === 'undefined') {
    throw new Error(`element doesnt have data ${props.dataKey} installed. dataset: ${JSON.stringify(element.dataset)}`);
  }
  if (Number.isNaN(parseInt(element.dataset[props.dataKey], 10))) {
    return element.dataset[props.dataKey];
  }

  return parseInt(element.dataset[props.dataKey], 10);
};

const { isOver, isOverTop, isOverBottom, isOverLeft, isOverRight } = useMDraggable(props, clientX, clientY);
const setDragOverItem = (event) => {
  const items = Array.from(root.value.querySelectorAll(`.${props.dragoverItemClass}`));
  const r = items.reduce((res, next) => {
    const key = findKey(next);
    if (isOverTop(next)) {
      res.overTop.push(key);
    }
    if (isOverBottom(next)) {
      res.overBottom.push(key);
    }
    if (isOverRight(next)) {
      res.overRight.push(key);
    }
    if (isOverLeft(next)) {
      res.overLeft.push(key);
    }
    if (isOver(next)) {
      res.over.push(key);
    }
    return res;
  }, { overTop: [], overBottom: [], overRight: [], overLeft: [], over: [] });
  emit('over', r.over, event);
  emit('over-top', r.overTop, event);
  emit('over-bottom', r.overBottom, event);
  emit('over-right', r.overRight, event);
  emit('over-left', r.overLeft, event);
};
const setDragItem = (el) => {
  dragItem.value = el;
  dragItem.value.classList.add('-dragging');
  emit('set-drag-item', findKey(el));
};
const unsetDragItem = () => {
  if (dragItem.value !== null) {
    dragItem.value.classList.remove('-dragging');
    dragItem.value = null;
  }
};
const addGhost = (el) => {
  const elCopy = el.cloneNode(true);
  elCopy.style.zIndex = 999;
  elCopy.style.width = `${el.clientWidth}px`;
  elCopy.style.backgroundColor = colors.grey.lighten5;
  Object.keys(props.ghostItemStyle).forEach((key) => {
    elCopy.style[key] = props.ghostItemStyle[key];
  });
  document.getElementById(key.value).appendChild(elCopy);
};
const removeGhost = () => {
  const e = document.getElementById(key.value);
  if (e === null) {
    return;
  }
  e.innerHTML = '';
};

const handleMouseDown = (el) => (event) => {
  if (mouseDown.value) {
    return;
  }
  if (props.disabled) {
    return;
  }
  if (event.which !== 1) {
    return;
  }
  event.stopPropagation();
  event.preventDefault();
  mouseDown.value = true;
  const rect = el.getBoundingClientRect();
  clientX.value = event.clientX;
  clientY.value = event.clientY;
  offsetX.value = event.clientX - rect.left;
  offsetY.value = event.clientY - rect.top;
  mouseMoveFn.value = handleMouseMove(el);
  dragArea.value.addEventListener('mousemove', mouseMoveFn.value);
  dragArea.value.addEventListener('keydown', handleKeyDown);
  document.addEventListener('mouseup', handleMouseUp);
};
const handleMouseMove = (el) => (event) => {
  if (!mouseDown.value) {
    return;
  }

  event.stopPropagation();

  // do not immediately drag an item, but wait for a clear drag movement
  if (!dragging.value && (Math.abs(event.clientY - clientY.value) < 6) && (Math.abs(event.clientX - clientX.value) < 6)) {
    return;
  }

  if (!dragging.value) {
    dragging.value = true;
    addGhost(el);
    setDragItem(el);
  }
  const update = () => {
    if (props.ghostItemMovementAxes === 'x' || props.ghostItemMovementAxes === 'any') {
      clientX.value = event.clientX;
    }
    if (props.ghostItemMovementAxes === 'y' || props.ghostItemMovementAxes === 'any') {
      clientY.value = event.clientY;
    }

    const distanceRight = (dragAreaBoundsDimensions.value.x + dragAreaBoundsDimensions.value.width) - event.clientX;
    const distanceBottom = (dragAreaBoundsDimensions.value.y + dragAreaBoundsDimensions.value.height) - event.clientY;
    const distanceLeft = event.clientX - dragAreaBoundsDimensions.value.x;
    const distanceTop = event.clientY - dragAreaBoundsDimensions.value.y;
    scrollSvc.handleScroll({ distanceTop, distanceRight, distanceBottom, distanceLeft });

    setDragOverItem(event);
  };
  if (debounced.value !== null) {
    debounced.value.cancel();
  }

  debounced.value = debounce(update, 5);
  debounced.value();
};
const cleanup = () => {
  if (dragging.value) {
    dragging.value = false;
    removeGhost();
    unsetDragItem();
  }
  mouseDown.value = false;
  scrollSvc.stop();
  dragArea.value.removeEventListener('mousemove', mouseMoveFn.value);
  dragArea.value.removeEventListener('keydown', handleKeyDown);
  document.removeEventListener('mouseup', handleMouseUp);
};
const handleKeyDown = (event) => {
  if (event.key === 'Escape') {
    emit('cancel');
    cleanup();
  }
};
const handleMouseUp = (event) => {
  event.stopPropagation();
  if (dragging.value) {
    emit('drag-drop');
  } else {
    emit('click');
  }
  cleanup();
};

const selector = (className) => {
  if (props.scrollContainerClass === '') {
    return `.${className}`;
  }
  return `.${props.scrollContainerClass} .${className}`;
};
const addListener = () => {
  if (props.draggableItemClass === '') {
    return;
  }

  if (root.value === null) {
    return;
  }

  const elements = root.value.querySelectorAll(selector(props.draggableItemClass));
  const ghostElements = root.value.querySelectorAll(selector(localGhostItemClass.value));
  if (ghostElements.length !== elements.length) {
    throw new Error('number of ghost elements and drag handles must be the same');
  }

  for (let i = 0; i < elements.length; i++) {
    const f = handleMouseDown(ghostElements[i]);
    elements[i].addEventListener('mousedown', f);
    mouseDownListeners.value.push({ element: elements[i], function: f });
  }
};
const removeListener = () => {
  for (let i = 0; i < mouseDownListeners.value.length; i++) {
    mouseDownListeners.value[i].element.removeEventListener('mousedown', mouseDownListeners.value[i].function);
  }
  mouseDownListeners.value = [];
};

defineExpose({ handleMouseDown });

watch(
  toRef(props, 'recreateKey'),
  () => {
    if (props.disabled) {
      return;
    }
    removeListener();
    nextTick(() => {
      nextTick(() => {
        addListener();
      });
    });
  },
  { deep: true },
);
watch(toRef(props, 'disabled'), (val) => {
  if (!val) {
    nextTick(() => {
      nextTick(() => {
        addListener();
      });
    });
    return;
  }
  removeListener();
});
onMounted(() => {
  if (props.disabled) {
    return;
  }
  nextTick(() => {
    nextTick(() => {
      addListener();
    });
  });
});
onBeforeUnmount(() => {
  removeListener();
});
</script>

<style lang="scss" type="text/scss">
  #drag {
    ._ghost-item {
      position: fixed;
      z-index: 1051;
      pointer-events: none;
      opacity: .6;
    }
  }

  .m-draggable {
    &.-dragging {
      pointer-events: none;
    }
  }
</style>
