import useGoals from '@/composables/goal/goals';
import { aggregationMethod, goalProgressMeasurement } from 'shared/constants.json';
import { camelCase } from 'lodash-es';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { useI18n } from 'vue-i18n';

export default function useProgressMeasurement() {
  const { t } = useI18n();
  const goalSvc = useGoals();

  const progressMeasurementOptions = [
    {
      text: t(`metricForm.${camelCase(goalProgressMeasurement.continuous)}`),
      value: goalProgressMeasurement.continuous,
      icon: 'line-chart',
    },
    {
      text: t(`metricForm.${camelCase(goalProgressMeasurement.alignedItems)}`),
      value: goalProgressMeasurement.alignedItems,
      icon: 'apartment',
    },
    {
      text: t(`metricForm.${camelCase(goalProgressMeasurement.threshold)}`),
      value: goalProgressMeasurement.threshold,
      icon: 'threshold',
    },
    {
      text: t(`metricForm.${camelCase(goalProgressMeasurement.binary)}`),
      value: goalProgressMeasurement.binary,
      icon: 'check-square',
    },
    {
      text: t(`metricForm.${camelCase(goalProgressMeasurement.none)}`),
      value: goalProgressMeasurement.none,
      icon: 'stop',
    },
  ];

  const aggregationMethodOptions = [
    {
      text: t(`metricForm.aggregationMethod.${camelCase(aggregationMethod.relative)}`),
      value: aggregationMethod.relative,
      // icon: '',
    },
    {
      text: t(`metricForm.aggregationMethod.${camelCase(aggregationMethod.absolute)}`),
      value: aggregationMethod.absolute,
      // icon: '',
    },

  ];

  const allowedProgressMeasurementOptions = (option) => {
    if (option === null) {
      return progressMeasurementOptions;
    }

    if (option.allowedMeasurementTypes.length === 0) {
      return progressMeasurementOptions;
    }

    return progressMeasurementOptions.map((o) => ({
      ...o,
      disabled: !option.allowedMeasurementTypes.includes(o.value),
    }));
  };

  const allowedAggregationMethodOptions = () => aggregationMethodOptions;

  const goalStart = (goal) => {
    switch (goal.progressMeasurement) {
      case goalProgressMeasurement.continuous:
        return goal.start;
      case goalProgressMeasurement.threshold:
        return 0;
      case goalProgressMeasurement.binary:
        return 0;
      case goalProgressMeasurement.none:
        return 0;
      case goalProgressMeasurement.alignedItems:
        if (goal.aggregationMethod === aggregationMethod.absolute) {
          const children = goalSvc.selectMultiple(goal.children.map((g) => g.uid));
          const res = children.filter((g) => !isNullOrUndefined(g.paysOnto) && g.paysOnto.map((g) => g.uid).includes(goal.uid)).map((g) => goalStart(g));
          return res.reduce((a, b) => a + b, 0);
        }

        return 0;
      default:
        return 0;
    }
  };

  const goalEnd = (goal) => {
    switch (goal.progressMeasurement) {
      case goalProgressMeasurement.continuous:
        return goal.end;
      case goalProgressMeasurement.threshold:
        return 1;
      case goalProgressMeasurement.binary:
        return 1;
      case goalProgressMeasurement.none:
        return 0;
      case goalProgressMeasurement.alignedItems:
        if (goal.aggregationMethod === aggregationMethod.absolute) {
          const children = goalSvc.selectMultiple(goal.children.map((g) => g.uid));
          const res = children.filter((g) => !isNullOrUndefined(g.paysOnto) && g.paysOnto.map((g) => g.uid).includes(goal.uid)).map((g) => goalEnd(g));
          return res.reduce((a, b) => a + b, 0);
        }

        return 100;
      default:
        return 0;
    }
  };

  const goalMetric = (goal) => {
    switch (goal.progressMeasurement) {
      case goalProgressMeasurement.continuous:
      case goalProgressMeasurement.threshold:
        return goal.metric;
      case goalProgressMeasurement.binary:
        return '%';
      case goalProgressMeasurement.none:
        return null;
      case goalProgressMeasurement.alignedItems:
        if (goal.aggregationMethod === aggregationMethod.absolute) {
          const children = goalSvc.selectMultiple(goal.children.map((g) => g.uid));
          const res = children.filter((g) => !isNullOrUndefined(g.paysOnto) && g.paysOnto.map((g) => g.uid).includes(goal.uid)).map((g) => goalMetric(g)).filter((m) => m !== null);
          return stringMajority(res);
        }

        return '%';
      default:
        return null;
    }
  };

  const stringMajority = (arr) => {
    if (arr.length === 0) {
      return null;
    }

    const init = arr.reduce((res, cur) => ({ ...res, [cur]: 0 }), {});

    const counted = arr.reduce((res, cur) => ({
      ...res,
      [cur]: res[cur] + 1,
    }), init);

    const max = Math.max(...Object.values(counted));
    return arr.filter((el) => counted[el] === max)[0];
  };

  return {
    progressMeasurementOptions,
    allowedProgressMeasurementOptions,
    allowedAggregationMethodOptions,

    goalStart,
    goalEnd,
    goalMetric,
  };
}
