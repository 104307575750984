import useGoals from '@/composables/goal/goals';
import useParamDefaulter from '@/composables/view-params/default';
import useParamEnricher from '@/composables/view-params/enrich';
import useProperties from '@/composables/property/property';
import { computed, watch } from 'vue';
import { copy } from 'shared/lib/copy';

export default function useEnrichParams(repo) {
  const paramDefaulter = useParamDefaulter();
  const propertySvc = useProperties();
  const goalsSvc = useGoals();
  const paramEnricher = useParamEnricher(propertySvc, goalsSvc);

  watch(repo.views, (views) => {
    const toOperate = views.filter((v) => {
      if (v.params === undefined || v.params === null) {
        return false;
      }

      if (v.params.filter === undefined || v.params.filter === null) {
        return false;
      }
      return true;
    });

    const relations = toOperate.map((v) => paramEnricher.goalRelations(v.params.filter)).flat();
    paramEnricher.fetchGoals(relations);
  });

  const views = computed(() => repo.views.value.map((view) => {
    if (view.params === undefined) {
      return view;
    }

    let filter = view.params.filter;
    if (filter !== undefined && filter !== null) {
      filter = paramEnricher.enrich(copy(view.params.filter));
    }

    return {
      ...view,
      params: paramDefaulter.setDefaults({
        ...view.params,
        filter,
      }, view.viewType),
    };
  }));

  return {
    createLoading: repo.createLoading,
    createViews: repo.createViews,

    loadingUpdateView: repo.loadingUpdateView,
    updateView: repo.updateView,

    loadingUpdateMetaData: repo.loadingUpdateMetaData,
    updateMetaData: repo.updateMetaData,

    loadingDeleteView: repo.loadingDeleteView,
    deleteView: repo.deleteView,

    setParams: repo.setParams,
    updateSort: repo.updateSort,
    updateCycles: repo.updateCycles,
    updateLockCyclesOnView: repo.updateLockCyclesOnView,

    resetView: repo.resetView,

    hasUnsavedChanges: repo.hasUnsavedChanges,

    views,
  };
}
