import useDebounce from '@/composables/debounce';
import { intersection } from 'shared/lib/array/array';
import { ref, watch } from 'vue';
import { subscriptionAction } from 'shared/constants.json';

/**
 * @param subscriptionSvc
 * @param {Repo} repo
 * @param options
 */
export default function useDataSync(subscriptionSvc, repo, options = { delay: 0, ignoreNonExistingData: false }) {
  const toQuery = ref([]);
  const subscribe = () => {
    subscriptionSvc.subscribe({
      model: repo.model,
      syncFn: ({ action, ids }) => {
        switch (action) {
          case subscriptionAction.update: {
            if (options.ignoreNonExistingData) {
              const filteredIds = intersection(ids, repo.ids.value.map((id) => parseInt(id, 10)));
              toQuery.value.push(...filteredIds);
              return;
            }
            toQuery.value.push(...ids);
            break;
          }
          case subscriptionAction.create:
            toQuery.value.push(...ids);
            break;
          case subscriptionAction.delete: {
            const filteredIds = intersection(ids, repo.ids.value.map((id) => parseInt(id, 10)));
            repo.deleteMultiple(filteredIds, { commitToRemote: false });
            break;
          }
          default:
            break;
        }
      },
    });
  };

  const { debounce } = useDebounce();

  watch(toQuery, (val) => {
    if (val.length === 0) {
      return;
    }

    const query = () => {
      const querying = [...toQuery.value];
      repo.selectMultiple(querying, { commitToRemote: true }).then((data) => {
        for (let i = 0; i < querying.length; i++) {
          if (!data.map(({ uid }) => uid).includes(querying[i])) {
            repo.deleteSingle(querying[i], { commitToRemote: false });
          }
        }
      });
      toQuery.value = [];
    };
    debounce(query, options.delay);
  }, { deep: true });

  return { subscribe };
}
