import { dataSourceType } from 'shared/constants.json';
import { encodeURLValues } from '@/lib/url';

export const dataSourceIcon = (dataSource) => {
  if (dataSource === null) {
    return '';
  }

  switch (dataSource.type) {
    case dataSourceType.sheets:
      return 'sheets';
    case dataSourceType.jira:
      return 'jira';
    case dataSourceType.excel:
      return 'excel';
    case dataSourceType.hubspot:
      return 'hubspot';
    case dataSourceType.asana:
      return 'asana';
    case dataSourceType.salesforce:
      return 'salesforce';
    case dataSourceType.msPowerBi:
      return 'ms-power-bi';
    case dataSourceType.msPlanner:
      return 'ms-planner';
    default:
      return '';
  }
};

export const dataSourceSignInIcon = (dataSource) => {
  if (dataSource === null) {
    return '';
  }

  switch (dataSource.type) {
    case dataSourceType.sheets:
      return 'google-color';
    case dataSourceType.jira:
      return 'jira';
    case dataSourceType.excel:
    case dataSourceType.msPowerBi:
    case dataSourceType.msPlanner:
      return 'microsoft-color';
    case dataSourceType.hubspot:
      return 'hubspot';
    case dataSourceType.asana:
      return 'asana';
    case dataSourceType.salesforce:
      return 'salesforce';
    default:
      return '';
  }
};

export const connectUrl = (dataSource) => {
  const params = {
    dataSourceId: dataSource.uid,
    origin: '/#/oauth-redirect',
  };

  const targetParams = { oauthRedirect: `/api/v1/data-source/install?${encodeURLValues(params)}` };
  return `/#/oauth-redirect?${encodeURLValues(targetParams)}`;
};
