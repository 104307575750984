import { CREATED_AT, DEFAULT_ATTRIBUTES, ONE_TO_ONE, RESULT, UID } from 'shared/api/query/constants';
import {
  asanaQuery as asanaQueryConfig,
  comment as commentConfig,
  dataSource as dataSourceConfig,
  feedEntry as feedEntryConfig,
  feedbackAnswer as feedbackAnswerConfig,
  feedbackInquiry as feedbackInquiryConfig,
  field as fieldConfig,
  form as formConfig,
  goal as goalConfig,
  hubspotQuery as hubspotQueryConfig,
  jiraQuery as jiraQueryConfig,
  msPlannerQuery as msPlannerQueryConfig,
  msPowerBiQuery as msPowerBiQueryConfig,
  notification as notificationConfig,
  property as propertyConfig,
  propertyValue as propertyValueConfig,
  salesforceQuery as salesforceQueryConfig,
  spreadsheetCell as spreadsheetCellConfig,
  update as updateConfig,
  updateTemplate as updateTemplateConfig,
  user as userConfig,
} from 'shared/api/query/configs.json';
import { commentEdges } from '@/api/query/nebula/comment';
import { reverseEdge } from 'shared/api/query/edges';
import { updateChildren } from '@/api/query/nebula/update';

export const feedEntryChildren = [
  ...DEFAULT_ATTRIBUTES,
  { attr: feedEntryConfig.edges.readAt },
  { attr: feedEntryConfig.edges.fulfilledAt, default: null },
  { attr: feedEntryConfig.edges.type },
  {
    attr: feedEntryConfig.edges.actor,
    model: userConfig.model,
    children: [
      { attr: UID },
    ],
    default: null,
  },
  {
    attr: feedEntryConfig.edges.goal,
    model: goalConfig.model,
    children: [{ attr: UID }, { attr: goalConfig.edges.title, default: '' }],
    default: null,
  },
  {
    attr: feedEntryConfig.edges.update,
    model: updateConfig.model,
    children: updateChildren(),
    default: null,
  },
  {
    attr: feedEntryConfig.edges.feedbackAnswer,
    model: feedbackAnswerConfig.model,
    children: [
      { attr: UID },
      { attr: feedbackAnswerConfig.edges.status },
      {
        attr: reverseEdge(feedbackInquiryConfig.edges.answers),
        alias: 'feedbackInquiry',
        assoc: ONE_TO_ONE,
        model: feedbackInquiryConfig.model,
        children: [
          { attr: UID },
          { attr: feedbackInquiryConfig.edges.message, default: null },
          {
            attr: feedbackInquiryConfig.edges.questions,
            model: fieldConfig.model,
            children: [
              { attr: UID },
              { attr: fieldConfig.edges.title },
              { attr: fieldConfig.edges.type },
            ],
          },
        ],
      },
    ],
    default: null,
  },
  {
    attr: feedEntryConfig.edges.comment,
    model: commentConfig.model,
    children: commentEdges,
    default: null,
  },
  {
    attr: feedEntryConfig.edges.form,
    model: formConfig.model,
    default: null,
    children: [{ attr: UID }, { attr: formConfig.edges.title }],
  },
  {
    attr: feedEntryConfig.edges.notification,
    model: notificationConfig.model,
    default: null,
    children: [
      { attr: UID },
      { attr: notificationConfig.edges.title },
      {
        attr: notificationConfig.edges.updateTemplate,
        default: null,
        model: updateTemplateConfig.model,
        children: [{ attr: UID }],
      },
    ],
  },
  {
    attr: feedEntryConfig.edges.propertyValue,
    model: propertyValueConfig.model,
    default: null,
    children: [
      { attr: UID },
      {
        attr: propertyValueConfig.edges.property,
        default: null,
        model: propertyConfig.model,
        children: [{ attr: UID }],
      },
    ],
  },
  {
    attr: feedEntryConfig.edges.dataSource,
    model: dataSourceConfig.model,
    default: null,
    children: [
      { attr: UID },
      { attr: dataSourceConfig.edges.name, default: '' },
      { attr: dataSourceConfig.edges.type },
    ],
  },
  {
    attr: feedEntryConfig.edges.spreadsheetQuery,
    model: spreadsheetCellConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: feedEntryConfig.edges.asanaQuery,
    model: asanaQueryConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: feedEntryConfig.edges.jiraQuery,
    model: jiraQueryConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: feedEntryConfig.edges.hubspotQuery,
    model: hubspotQueryConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: feedEntryConfig.edges.salesforceQuery,
    model: salesforceQueryConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: feedEntryConfig.edges.msPowerBiQuery,
    model: msPowerBiQueryConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: feedEntryConfig.edges.msPlannerQuery,
    model: msPlannerQueryConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  { attr: feedEntryConfig.edges.dataSourceQueryError, default: null },
];

export const feedEntryList = ({ alias, types, pagination }) => [{
  func: { name: 'type', args: [{ value: feedEntryConfig.model }] },
  alias,
  filter: {
    func: {
      name: 'eq',
      attr: feedEntryConfig.edges.type,
      args: types.map((t) => ({ value: t })),
    },
  },
  model: feedEntryConfig.model,
  order: [{ attr: CREATED_AT, desc: true }],
  children: feedEntryChildren,
  pagination,
  default: [],
}];

export const unreadCount = {
  func: { name: 'type', args: [{ value: feedEntryConfig.model }] },
  alias: RESULT,
  filter: {
    op: 'and',
    child: [
      {
        op: 'not',
        child: [
          {
            func: {
              name: 'has',
              attr: feedEntryConfig.edges.readAt,
            },
          },
        ],
      },
    ],
  },
  model: feedEntryConfig.model,
  children: [{ attr: 'uid' }, { attr: feedEntryConfig.edges.type }],
  default: [],
};
