import { UID } from 'shared/api/query/constants';
import { storageObject as storageObjectConfig } from 'shared/api/query/configs.json';

export const storageObjectEdges = [
  { attr: UID },
  { attr: 'getURL' },
  { attr: 'updateURL' },
  { attr: 'fileName' },
  { attr: storageObjectConfig.edges.blob },
];
