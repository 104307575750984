<template>
  <div>
    <div v-if="isSurvey">
      <m-icon
        size="20"
        type="solution"
      />
    </div>
    <div v-if="isUpdateNotification">
      <m-icon
        size="20"
        type="clock-circle"
      />
    </div>
    <div v-if="isIntegration">
      <m-icon
        size="20"
        type="api"
      />
    </div>
    <div v-if="!isTask">
      <user-avatar
        :user="feedEntry.actor"
        :size="28"
      />
    </div>
  </div>
</template>

<script>
import UserAvatar from 'shared/components/UserAvatar.vue';
import notificationFeedMixin from '@/mixins/notification-feed';

export default {
  name: 'FeedEntryIcon',
  props: {
    feedEntry: {
      type: Object,
      required: true,
    },
  },
  components: { UserAvatar },
  mixins: [notificationFeedMixin],
};
</script>
