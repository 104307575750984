<template>
  <m-focusable
    v-if="property.type === 'description' || property.edgeName === 'description'"
    :class="['goal-property-form-item _editor-content', wrap ? '-wrap' : '']"
    :hide-border="hideBorder"
    :small="small"
    full-width
    :hide-hover="hideHover"
    :read-only="readOnly"
    :disabled="disabled"
    :m-style="mStyle"
  >
    <m-editor
      ref="input"
      :initial-value="description"
      :disabled="disabled || readOnly"
      :allowed-content="allowedDescriptionContent"
      :placeholder="placeholder"
      light-placeholder
      :default-font-size="resolveStyles(mStyle).fontSize"
      @input="updateDescription"
      @blur="$emit('blur')"
    />
  </m-focusable>
  <metric-form
    v-else-if="property.type === 'progressMeasurement'"
    :m-style="mStyle"
    :goal="goal"
    :goal-children="goalChildren"
    :hide-hover="hideHover"
    :read-only="readOnly"
    :disabled="disabled"
  />
  <m-tooltip
    v-else-if="property.type === 'parents'"
    :disabled="!goalPickerService.disabled.value"
    placement="left"
    :mouse-enter-delay="0.5"
  >
    <goal-picker-v2
      v-model:value="selectedParentIds"
      :small="small"
      :excluded-goal-ids="[goal.uid]"
      :disabled-condition="goalPickerService.disabledCondition"
      :hide-placeholder="hidePlaceholder"
      :disabled="goalPickerService.disabled.value"
      :multiple="goalPickerService.multiAlignment"
      :read-only="readOnly"
      :default-view="goalPickerService.defaultView"
      :view-application="goalPickerService.viewApplication"
      :base-filter="goalPickerService.filter"
      :hide-hover="hideHover"
      :hide-border="hideBorder"
      :full-width="fullWidth"
      :placeholder-icon="showPlaceholderIcon ? property.icon : ''"
      :wrap="wrap"
      :m-style="mStyle"
      placement="onTopCenter"
      show-create-view
      show-views-selector
      show-input-field
      :initial-selected-cycles="goal.goalCycle"
      @hide="updateProperties(selectedParentIds)"
    />
    <template #title>
      {{ $t('goalProperties.noParentAllowed') }}
    </template>
  </m-tooltip>
  <goal-cycle-selector
    v-else-if="property.type === 'goalCycle' || property.edgeName === 'goalCycle'"
    ref="input"
    :small="small"
    :value="goal.goalCycle"
    :hide-border="hideBorder"
    show-search
    keep-open-on-click
    :wrap="wrap"
    :full-width="fullWidth"
    :placeholder-icon="showPlaceholderIcon ? property.icon : ''"
    :read-only="readOnly"
    :disabled="disabled"
    :placeholder="placeholder"
    :hide-placeholder="hidePlaceholder"
    :hide-hover="hideHover"
    :m-style="mStyle"
    multiple
    placement="onTopLeft"
    @input="updateProperties"
    @close="$emit('blur')"
  />
  <property-form-item
    v-else
    ref="input"
    :property="property"
    :property-values="goal.properties"
    :show-placeholder-icon="showPlaceholderIcon"
    :placeholder="placeholder"
    :hide-placeholder="hidePlaceholder"
    :disabled="disabled"
    :read-only="readOnly"
    :wrap="wrap"
    :small="small"
    :full-width="fullWidth"
    :hide-hover="hideHover"
    :m-style="mStyle"
    clearable
    :hide-border="hideBorder"
    :popup="popup"
    :match-trigger-width="matchTriggerWidth"
    :restrict-foreign-entity-selection="true"
    @change="updateProperties"
    @blur="$emit('blur')"
  />
</template>

<script>
import GoalCycleSelector from '@/components/goal/cycle/GoalCycleSelector.vue';
import GoalPickerV2 from '@/components/goal/GoalPickerV2.vue';
import MEditor from '@/components/editor/MEditor.vue';
import MetricForm from '@/components/goal/MetricForm.vue';
import PropertyFormItem from '@/components/PropertyFormItem.vue';
import useAccess from '@/composables/access/access';
import useDebounce from '@/composables/debounce';
import { editorNodeType, featureFlag } from 'shared/constants.json';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { isEqual } from 'lodash-es';
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';

export default {
  name: 'GoalPropertyFormItem',
  props: {
    ...mStyleProps,
    property: {
      type: Object,
      required: true,
    },
    goal: {
      type: Object,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    matchTriggerWidth: {
      type: Boolean,
      default: false,
    },
    goalChildren: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    showPlaceholderIcon: {
      type: Boolean,
      default: false,
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    popup: {
      type: Boolean,
      default: false,
    },
    updateProperties: {
      type: Function,
      required: true,
    },
    goalPickerService: {
      type: Object,
      required: true,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MEditor,
    MetricForm,
    GoalPickerV2,
    GoalCycleSelector,
    PropertyFormItem,
  },
  setup() {
    const { accountHasFeature } = useAccess();

    const allowedDescriptionContent = [
      editorNodeType.bulletList,
      editorNodeType.orderedList,
    ];

    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedDescriptionContent.push(editorNodeType.image, editorNodeType.file);
    }

    const { debounce } = useDebounce();
    return { debounce, allowedDescriptionContent };
  },
  emits: ['change-parent', 'blur'],
  data() {
    return {
      description: null,
      selectedParentIds: [],
    };
  },
  computed: {
    goalDescription() {
      return this.goal.description;
    },
  },
  methods: {
    updateDescription(value) {
      this.description = value;
      const update = () => this.updateProperties(value);
      this.debounce(update, 700);
    },
    resolveStyles,
    focus() {
      if (this.property.type === goalConfig.edges.goalCycle || this.property.edgeName === goalConfig.edges.goalCycle) {
        this.$refs.input.show();
        return;
      }
      this.$refs.input.focus();
    },
  },
  watch: {
    goalDescription(val) {
      if (isEqual(val, this.description)) {
        return;
      }
      this.description = val;
    },
  },
  created() {
    this.selectedParentIds = this.goal.parents.map((g) => g.uid);
    this.description = this.goal.description;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .goal-property-form-item._editor-content {
    align-items: flex-start;

    &.-wrap {
      height: 100%;
      max-height: 40rem;
      overflow: auto;
    }

    .m-editor {
      width: 100%;

      p.is-editor-empty {
        min-height: 0;
        padding: 0;
      }
    }
  }
</style>
