import useAccess from '@/composables/access/access';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { UNASSIGNED } from '@/lib/constants';
import { accessGroupFlag, accessPolicyType, goalProgressMeasurement, goalThresholdTargetArea } from 'shared/constants.json';
import { addKeyResultType, addObjectiveType } from '@/lib/goal/modifier/goal-type';
import { computed, ref } from 'vue';
import { copy } from 'shared/lib/copy';
import { copyAccessPolicy } from '@/lib/access-policy';
import { defaultProgressMeasurement, restrictProgressMeasurement } from '@/lib/goal/modifier/progress-measurement';
import { emptyValues } from '@/lib/property';
import {
  enforceAlignmentRule,
  restrictForeignEntityProperties,
  restrictNonWritableProperties,
} from '@/lib/goal/modifier/restrict-properties';
import { getPropertyValuesFromFilter } from '@/lib/property-value';
import { mergePropertyValues } from '@/lib/goal-grouping';
import { setAlignedItemsGroupedBy } from '@/lib/goal/modifier/aligned-items-group-by';

export default function useGoalModifiers(goalTypeProperty, select, modifiers = ref([])) {
  const { properties: goalProperties } = useGoalProperty();
  const { userHasRight } = useAccess();
  const { newGoalDefaultAccessPolicy } = useGoalSettings();
  const hasForeignEntityReference = computed(() => userHasRight([accessGroupFlag.foreignEntityReference]));
  const { myTeamsIds, loggedInUser, peers } = useLoggedInUser();
  const { loggedInUserAccount } = useLoggedInUserAccount();

  const usesMultiGoalAlignment = computed(() => loggedInUserAccount.value.accountSettings.usesMultiGoalAlignment);

  let parents = [];
  const setParents = (p) => {
    parents = p;
  };

  let filter = null;
  const setFilter = (f) => {
    filter = f;
  };

  let selectedCycles = [];
  const setSelectedCycles = (s) => {
    selectedCycles = s;
  };

  const parentsFromParams = (goal) => {
    if (!Array.isArray(parents)) {
      return goal;
    }
    goal.parents = [...goal.parents, ...parents];
    return goal;
  };

  let space = null;
  const setSpace = (o) => {
    space = o;
  };

  const addSpace = (goal) => {
    if (space === null) {
      return goal;
    }
    goal.properties = mergePropertyValues([{
      property: goalProperties.value.find((p) => p.uid === space.property.uid),
      spaces: [space],
    }], goal.properties);
    return goal;
  };

  let option = null;
  const setOption = (o) => {
    option = o;
  };

  const addOption = (goal) => {
    if (option === null) {
      return goal;
    }
    goal.properties = mergePropertyValues([{
      property: goalProperties.value.find((p) => p.uid === option.property.uid),
      selectedOptions: [option],
    }], goal.properties);
    return goal;
  };

  const parentsFromFilter = (goal) => {
    const { parents: filterParents } = getPropertyValuesFromFilter(filter, [], loggedInUser.value);
    if (typeof filterParents === 'undefined') {
      return goal;
    }
    goal.parents = [...goal.parents, ...filterParents];
    return goal;
  };

  const fixParents = (goal) => {
    if (usesMultiGoalAlignment.value || goal.parents.length <= 1) {
      return goal;
    }

    goal.parents = [goal.parents[0]];
    return goal;
  };

  const paysOntoFromParents = (goal) => {
    if (goal.progressMeasurement === goalProgressMeasurement.none) {
      return goal;
    }
    goal.paysOnto = goal.parents.filter((p) => [accessPolicyType.write, accessPolicyType.full].includes(p.accessRight)).map((g) => ({ uid: g.uid }));
    return goal;
  };

  const valuesFromFilter = (goal) => {
    if (goal.parents.length > 0) {
      return propertiesFromParents(goal);
    }

    const { properties } = getPropertyValuesFromFilter(filter, goal.properties, loggedInUser.value);
    goal.properties = properties;
    return goal;
  };

  const propertiesFromParents = (goal) => {
    goal.parents.forEach((p) => {
      goal.properties = mergePropertyValues(p.properties, goal.properties);
    });
    return goal;
  };

  const propertiesModifiers = (goal) => {
    if (duplicate !== null) {
      return [
        restrictNonWritableProperties,
        restrictForeignEntityProperties({ peers, myTeamsIds, hasForeignEntityReference }),
      ].reduce((res, m) => {
        res = m(res);
        return res;
      }, goal);
    }

    return [
      valuesFromFilter,
      addKeyResultType(goalProperties),
      addObjectiveType(goalProperties),
      addSpace,
      addOption,
      restrictNonWritableProperties,
      restrictForeignEntityProperties({ peers, myTeamsIds, hasForeignEntityReference }),
      enforceAlignmentRule(goalTypeProperty.value, select),
    ].reduce((res, m) => {
      res = m(res);
      return res;
    }, goal);
  };

  const setGoalCycles = (goal) => {
    if (duplicate !== null) {
      goal.goalCycle = selectedCycles.filter((c) => c.uid !== UNASSIGNED);
      return goal;
    }

    if (goal.parents.length > 0) {
      goal.goalCycle = goal.parents[0].goalCycle;
      return goal;
    }

    goal.goalCycle = selectedCycles.filter((c) => c.uid !== UNASSIGNED);
    return goal;
  };

  const setAccessPolicy = (goal) => {
    if (typeof goal.accessPolicy !== 'undefined') {
      return goal;
    }
    if (goal.parents.length > 0) {
      goal.accessPolicy = copy(goal.parents[0].accessPolicy);
      return goal;
    }
    goal.accessPolicy = newGoalDefaultAccessPolicy.value;
    return goal;
  };

  let duplicate = null;
  const setDuplicate = (goal) => {
    duplicate = goal;
  };
  const defaultModifier = () => {
    if (duplicate !== null) {
      return {
        ...duplicate,
        uid: undefined,
        rid: duplicate.uid,
        account: { uid: loggedInUserAccount.value.uid },
        creator: { uid: loggedInUser.value.uid },
        accessPolicy: copyAccessPolicy(duplicate.accessPolicy),
        subscriptions: [{ active: true, user: { uid: loggedInUser.value.uid } }],
        parents: duplicate.parents,
        paysOnto: duplicate.paysOnto,
        publishedAt: (new Date()).toISOString(),
        properties: duplicate.properties,
      };
    }

    return {
      account: { uid: loggedInUserAccount.value.uid },
      creator: { uid: loggedInUser.value.uid },
      parents: [],
      paysOnto: [],
      start: 0,
      threshold: 0,
      thresholdTargetArea: goalThresholdTargetArea.above,
      end: 100,
      metric: '%',
      publishedAt: (new Date()).toISOString(),
      properties: emptyValues(goalProperties.value),
    };
  };

  const getModifiers = () => [
    defaultModifier,
    parentsFromFilter,
    parentsFromParams,
    fixParents,
    propertiesModifiers,
    defaultProgressMeasurement,
    restrictProgressMeasurement(goalTypeProperty.value),
    paysOntoFromParents,
    setGoalCycles,
    setAlignedItemsGroupedBy(goalProperties),
    setAccessPolicy,
    ...modifiers.value,
  ];

  return {
    getModifiers,
    setParents,
    setFilter,
    setSelectedCycles,
    setOption,
    setSpace,
    setDuplicate,
  };
}
