import useBulkMutate from '@/nebula/bulk-mutate';
import useRepo from '@/nebula/repo';
import { ONE_TO_ONE, RESULT, UID, VAR } from 'shared/api/query/constants';
import {
  account as accountConfig,
  goal as goalConfig,
  gridPage as gridPageConfig,
  gridPageRow as gridPageRowConfig,
  gridPageTile as gridPageTileConfig,
  gridPageTileIframe as gridPageTileIframeConfig,
  gridPageTileSingleGoal as gridPageTileSingleGoalConfig,
  gridPageTileText as gridPageTileTextConfig,
  savedView as savedViewConfig,
  selectedView as selectedViewConfig,
  user as userConfig,
} from 'shared/api/query/configs.json';
import { gridPageChildren } from '@/api/query/nebula/grid-page';
import { gridPageRowChildren } from '@/api/query/nebula/grid-page-row';
import { gridPageTileChildren } from '@/api/query/nebula/grid-page-tile';
import { gridPageTileIframeChildren } from '@/api/query/nebula/grid-page-tile-iframe';
import { gridPageTileSingleGoalChildren } from '@/api/query/nebula/grid-page-tile-single-goal';
import { gridPageTileTextChildren } from '@/api/query/nebula/grid-page-tile-text';
import { gridPageType } from 'shared/constants.json';
import { reverseEdge } from 'shared/api/query/edges';
import { savedViewChildren } from '@/api/query/nebula/saved-view';
import { selectedViewChildren } from '@/api/query/nebula/selected-view';
import { spreadQueries } from '@/lib/query';

export default function useCustomGridRepo() {
  const { bulkMutate } = useBulkMutate();

  const gridPageRepo = useRepo(gridPageConfig.model);
  const gridPageRowRepo = useRepo(gridPageRowConfig.model);
  const gridPageTileRepo = useRepo(gridPageTileConfig.model);
  const gridPageTileTextRepo = useRepo(gridPageTileTextConfig.model);
  const gridPageTileSingleGoalRepo = useRepo(gridPageTileSingleGoalConfig.model);
  const gridPageTileIframeRepo = useRepo(gridPageTileIframeConfig.model);

  const createGridPage = (entity) => gridPageRepo.createSingle(entity);

  const updateGridPage = (entity) => gridPageRepo.updateSingle(entity);

  const updateGridPageAccessPolicy = (entity) => gridPageRepo.updateSingle(entity, { optimistic: false }).then((data) => {
    if (data === undefined) {
      gridPageRepo.deleteSingle(entity.uid, { commitToRemote: false });
    }
  });

  const updateGridPagesAccessPolicy = (entities) => gridPageRepo.updateMultiple(entities, { optimistic: false }).then((data) => {
    if (data.length !== entities.length) {
      const ids = data.map((g) => g.uid);
      const filtered = entities.filter((e) => !ids.includes(e.uid));
      gridPageRepo.deleteMultiple(filtered.map((i) => i.uid), { commitToRemote: false });
    }

    return data;
  });

  const deleteGridPage = (entity) => gridPageRepo.deleteMultiple([entity.uid]);
  const deleteGridPages = (entities) => gridPageRepo.deleteMultiple(entities.map((entity) => entity.uid));

  const queryGridPage = (id) => gridPageRepo.query([
    {
      alias: 'gridPages',
      func: { name: UID },
      uid: [id],
      model: gridPageConfig.model,
      children: gridPageChildren,
    },
    {
      alias: 'var',
      func: { name: UID },
      uid: [id],
      model: gridPageConfig.model,
      children: [
        {
          attr: reverseEdge(gridPageRowConfig.edges.gridPage),
          model: gridPageRowConfig.model,
          children: [
            { attr: UID, var: 'rows' },
            {
              attr: reverseEdge(gridPageTileConfig.edges.gridPageRow),
              model: gridPageTileConfig.model,
              children: [{ attr: UID, var: 'tiles' }],
            },
          ],
        },
      ],
    },
    {
      alias: 'gridPageRows',
      needsVar: [{ name: 'rows', typ: 1 }],
      func: { name: UID, needsVar: [{ name: 'rows', typ: 1 }] },
      model: gridPageRowConfig.model,
      children: gridPageRowChildren,
    },
    {
      alias: 'gridPageTiles',
      needsVar: [{ name: 'tiles', typ: 1 }],
      func: { name: UID, needsVar: [{ name: 'tiles', typ: 1 }] },
      model: gridPageTileConfig.model,
      children: gridPageTileChildren,
    },
  ]).then((data) => ({
    gridPages: data.gridPages.map((page) => gridPageRepo.selectSingle(page.uid)),
    gridPageRows: data.gridPageRows.map((row) => gridPageRowRepo.selectSingle(row.uid)),
    gridPageTiles: data.gridPageTiles.map((tile) => gridPageTileRepo.selectSingle(tile.uid)),
  }));

  const models = {
    [gridPageType.text]: gridPageTileTextConfig.model,
    [gridPageType.singleGoal]: gridPageTileSingleGoalConfig.model,
    [gridPageType.iframe]: gridPageTileIframeConfig.model,
  };

  const attributes = {
    [gridPageType.text]: gridPageTileTextChildren,
    [gridPageType.singleGoal]: gridPageTileSingleGoalChildren,
    [gridPageType.iframe]: gridPageTileIframeChildren,
  };

  const repoMap = {
    [gridPageType.text]: gridPageTileTextRepo,
    [gridPageType.singleGoal]: gridPageTileSingleGoalRepo,
    [gridPageType.iframe]: gridPageTileIframeRepo,
  };

  const queryGridPageTileByTile = (type, gridPageTile) => gridPageTileRepo.query([
    {
      alias: VAR,
      func: { name: UID },
      uid: [gridPageTile.uid],
      model: gridPageTileConfig.model,
      children: [{
        model: models[type],
        attr: reverseEdge('tile'),
        children: [{ attr: UID, var: 'tile' }],
      }],
    },
    {
      alias: RESULT,
      func: { name: UID, needsVar: [{ name: 'tile', typ: 1 }] },
      needsVar: [{ name: 'tile', typ: 1 }],
      model: models[type],
      children: attributes[type],
    },
  ]).then((data) => repoMap[type].selectSingle(data[RESULT][0].uid));

  const createGridPageTile = (tile, otherTiles = []) => {
    const payloads = {};

    if (tile.gridPageRow.uid === undefined) {
      payloads.createRow = {
        nodes: [tile.gridPageRow],
        model: gridPageRowConfig.model,
        attributes: gridPageRowChildren,
      };
    }

    payloads.createTile = {
      nodes: [tile],
      model: gridPageTileConfig.model,
      attributes: gridPageTileChildren,
    };

    payloads.updateRow = {
      nodes: [tile.gridPageRow],
      model: gridPageRowConfig.model,
      attributes: gridPageRowChildren,
    };

    payloads.updatePage = {
      nodes: [tile.gridPageRow.gridPage],
      model: gridPageConfig.model,
      attributes: gridPageChildren,
    };

    if (otherTiles.length > 0) {
      payloads.updateOtherTiles = {
        nodes: otherTiles,
        model: gridPageTileConfig.model,
        attributes: gridPageTileChildren,
      };
    }

    if (tile.singleGoalTile !== null) {
      payloads.createSingleGoalTile = {
        nodes: [tile.singleGoalTile],
        model: gridPageTileSingleGoalConfig.model,
        attributes: gridPageTileSingleGoalChildren,
      };
    }

    if (tile.textTile !== null) {
      payloads.createTextTile = {
        nodes: [tile.textTile],
        model: gridPageTileTextConfig.model,
        attributes: gridPageTileTextChildren,
      };
    }

    if (tile.iframeTile !== null) {
      payloads.createIframeTile = {
        nodes: [tile.iframeTile],
        model: gridPageTileIframeConfig.model,
        attributes: gridPageTileIframeChildren,
      };
    }

    Object.keys(payloads).forEach((alias) => {
      payloads[alias] = { alias, ...payloads[alias] };
    });

    const bulkPayloads = [
      payloads.createRow,
      payloads.createTile,
      payloads.updateRow,
      payloads.updatePage,
      payloads.updateOtherTiles,
      payloads.createSingleGoalTile,
      payloads.createTextTile,
      payloads.createIframeTile,
    ].filter((p) => p !== undefined);

    return bulkMutate(bulkPayloads).then((response) => gridPageTileRepo.selectSingle(response.data.createTile[0].uid));
  };

  const createSingleGoalTiles = (singleGoalTiles, gridPage) => {
    const payload = {};

    payload.createRows = {
      nodes: singleGoalTiles.map((sgt) => sgt.tile.gridPageRow),
      model: gridPageRowConfig.model,
      attributes: gridPageRowChildren,
    };

    payload.createTiles = {
      nodes: singleGoalTiles.map((sgt) => sgt.tile),
      model: gridPageTileConfig.model,
      attributes: gridPageTileChildren,
    };

    payload.updateRows = {
      nodes: singleGoalTiles.map((sgt) => sgt.tile.gridPageRow),
      model: gridPageRowConfig.model,
      attributes: gridPageRowChildren,
    };

    payload.createSingleGoalTiles = {
      nodes: singleGoalTiles,
      model: gridPageTileSingleGoalConfig.model,
      attributes: attributes[gridPageType.singleGoal],
    };

    payload.updateGridPage = {
      nodes: [gridPage],
      model: gridPageConfig.model,
      attributes: gridPageChildren,
    };

    const bulkPayloads = Object.keys(payload).map((alias) => ({ ...payload[alias], alias }));

    return bulkMutate(bulkPayloads).then((response) => response.data.createSingleGoalTiles.map((sgt) => gridPageTileSingleGoalRepo.selectSingle(sgt.uid)));
  };

  const updateGridPageTile = (type, entity) => repoMap[type].updateSingle(entity);

  const removeGridPageTile = (tile, otherTiles = []) => {
    const payloads = {};

    payloads.removeTile = {
      nodes: [tile],
      model: gridPageTileConfig.model,
      attributes: gridPageTileChildren,
    };

    payloads.updateRow = {
      nodes: [tile.gridPageRow],
      model: gridPageRowConfig.model,
      attributes: gridPageRowChildren,
    };

    if (otherTiles.length > 0) {
      payloads.updateOtherTiles = {
        nodes: otherTiles,
        model: gridPageTileConfig.model,
        attributes: gridPageTileChildren,
      };
    }

    if (tile.gridPageRow.gridPage !== undefined) {
      payloads.updatePage = {
        nodes: [tile.gridPageRow.gridPage],
        model: gridPageConfig.model,
        attributes: gridPageChildren,
      };
    }

    const bulkPayloads = Object.keys(payloads).map((alias) => ({ ...payloads[alias], alias }));
    return bulkMutate(bulkPayloads).then(() => null);
  };

  const moveTile = (tile, otherTiles, otherRows) => {
    const payloads = {};

    if (tile.gridPageRow.uid === undefined) {
      payloads.createRow = {
        nodes: [tile.gridPageRow],
        model: gridPageRowConfig.model,
        attributes: gridPageRowChildren,
      };
    }

    payloads.moveTile = {
      nodes: [tile],
      model: gridPageTileConfig.model,
      attributes: gridPageTileChildren,
    };

    payloads.updateRow = {
      nodes: [tile.gridPageRow],
      model: gridPageRowConfig.model,
      attributes: gridPageRowChildren,
    };

    if (tile.gridPageRow.gridPage !== undefined) {
      payloads.updateGridPage = {
        nodes: [tile.gridPageRow.gridPage],
        model: gridPageConfig.model,
        attributes: gridPageChildren,
      };
    }

    payloads.updateOtherTiles = {
      nodes: otherTiles,
      model: gridPageTileConfig.model,
      attributes: gridPageTileChildren,
    };

    payloads.updateOtherRows = {
      nodes: otherRows,
      model: gridPageRowConfig.model,
      attributes: gridPageRowChildren,
    };

    Object.keys(payloads).forEach((alias) => {
      payloads[alias] = { alias, ...payloads[alias] };
    });

    const bulkPayloads = [
      payloads.createRow,
      payloads.moveTile,
      payloads.updateRow,
      payloads.updateGridPage,
      payloads.updateOtherTiles,
      payloads.updateOtherRows,
    ].filter((p) => p !== undefined);

    return bulkMutate(bulkPayloads).then((response) => gridPageTileRepo.selectSingle(response.data.moveTile[0].uid));
  };

  const updateGridPageTiles = (tiles) => gridPageTileRepo.updateMultiple(tiles);

  const updateGridPageRow = (entity) => gridPageRowRepo.updateSingle(entity);

  const deepGridPageTileChildren = spreadQueries([
    ...gridPageTileChildren,
    {
      attr: gridPageTileConfig.edges.gridPageRow,
      model: gridPageRowConfig.model,
      children: [
        { attr: UID },
        { attr: gridPageRowConfig.edges.tileOrder },
        { attr: gridPageRowConfig.edges.height, default: null },
        {
          attr: gridPageRowConfig.edges.gridPage,
          model: gridPageConfig.model,
          children: [{ attr: UID }, { attr: gridPageConfig.edges.rowOrder }],
        },
      ],
    },
    {
      alias: 'singleGoalTile',
      default: null,
      assoc: ONE_TO_ONE,
      attr: reverseEdge(gridPageTileSingleGoalConfig.edges.tile),
      model: gridPageTileSingleGoalConfig.model,
      children: spreadQueries([
        ...gridPageTileSingleGoalChildren,
        { attr: gridPageTileSingleGoalConfig.edges.tile, model: gridPageTileConfig.model, children: [{ attr: UID }] },
        { attr: gridPageTileSingleGoalConfig.edges.goal, model: goalConfig.model, children: [{ attr: UID }] },
      ]),
    },
    {
      alias: 'textTile',
      default: null,
      assoc: ONE_TO_ONE,
      attr: reverseEdge(gridPageTileTextConfig.edges.tile),
      model: gridPageTileTextConfig.model,
      children: spreadQueries([
        ...gridPageTileTextChildren,
        { attr: gridPageTileTextConfig.edges.tile, model: gridPageTileConfig.model, children: [{ attr: UID }] },
      ]),
    },
    {
      alias: 'iframeTile',
      default: null,
      assoc: ONE_TO_ONE,
      attr: reverseEdge(gridPageTileIframeConfig.edges.tile),
      model: gridPageTileIframeConfig.model,
      children: spreadQueries([
        ...gridPageTileIframeChildren,
        { attr: gridPageTileIframeConfig.edges.tile, model: gridPageTileConfig.model, children: [{ attr: UID }] },
      ]),
    },
    {
      alias: 'savedViews',
      default: [],
      attr: reverseEdge(savedViewConfig.edges.gridPageTile),
      model: savedViewConfig.model,
      children: spreadQueries([
        ...savedViewChildren,
        { attr: savedViewConfig.edges.account, model: accountConfig.model, children: [{ attr: UID }] },
      ]),
    },
    {
      alias: 'selectedView',
      default: null,
      assoc: ONE_TO_ONE,
      attr: reverseEdge(selectedViewConfig.edges.gridPageTile),
      model: selectedViewConfig.model,
      children: spreadQueries([
        ...selectedViewChildren,
        { attr: selectedViewConfig.edges.user, model: userConfig.model, children: [{ attr: UID }] },
      ]),
    },
  ]);

  const queryGridPagesDeep = (gridPages) => gridPageRepo.query([
    {
      alias: 'gridPages',
      func: { name: UID },
      uid: gridPages.map((gridPage) => gridPage.uid),
      model: gridPageConfig.model,
      children: spreadQueries([
        ...gridPageChildren,
        { attr: gridPageConfig.edges.account, model: accountConfig.model, children: [{ attr: UID }] },
        { attr: gridPageConfig.edges.creator, model: userConfig.model, children: [{ attr: UID }] },
        {
          attr: reverseEdge(gridPageRowConfig.edges.gridPage),
          alias: 'rows',
          model: gridPageRowConfig.model,
          children: spreadQueries([
            ...gridPageRowChildren,
            {
              attr: reverseEdge(gridPageTileConfig.edges.gridPageRow),
              alias: 'tiles',
              model: gridPageTileConfig.model,
              children: deepGridPageTileChildren,
              default: [],
            },
          ]),
        },
      ]),
    },
  ]).then((data) => data.gridPages);

  const queryGridPageTileDeep = (tile) => gridPageTileRepo.selectSingle(tile.uid, { attributes: deepGridPageTileChildren, commitToRemote: true });

  const createGridPagesDeep = ({ entities }) => {
    const payload = {};

    payload.createGridPages = {
      nodes: entities,
      model: gridPageConfig.model,
      attributes: gridPageChildren,
    };

    const rows = entities.map((gridPage) => {
      if (Array.isArray(gridPage.rows)) {
        return gridPage.rows;
      }

      return [];
    }).flat();
    let tiles = [];

    if (rows.length > 0) {
      payload.createRows = {
        nodes: rows,
        model: gridPageRowConfig.model,
        attributes: gridPageRowChildren,
      };

      payload.updateGridpage = {
        nodes: entities.map((e) => ({ ...e, accessPolicy: undefined })),
        model: gridPageConfig.model,
        attributes: gridPageChildren,
      };

      tiles = rows.map((row) => row.tiles).flat();
    }

    if (tiles.length > 0) {
      payload.createTiles = {
        nodes: tiles,
        model: gridPageTileConfig.model,
        attributes: gridPageTileChildren,
      };

      payload.updateRows = {
        nodes: rows,
        model: gridPageRowConfig.model,
        attributes: gridPageRowChildren,
      };

      const textTiles = tiles.filter((t) => t.textTile !== null).map((t) => t.textTile);
      const singleGoalTiles = tiles.filter((t) => t.singleGoalTile !== null).map((t) => t.singleGoalTile);
      const iframeTiles = tiles.filter((t) => t.iframeTile !== null).map((t) => t.iframeTile);
      const savedViews = tiles.map((t) => t.savedViews).flat();
      const selectedViews = tiles.filter((t) => t.selectedView !== null).map((t) => t.selectedView);

      if (textTiles.length > 0) {
        payload.createTextTiles = {
          nodes: textTiles,
          model: gridPageTileTextConfig.model,
          attributes: gridPageTileTextChildren,
        };
      }

      if (singleGoalTiles.length > 0) {
        payload.createSingleGoalTiles = {
          nodes: singleGoalTiles,
          model: gridPageTileSingleGoalConfig.model,
          attributes: gridPageTileSingleGoalChildren,
        };
      }

      if (iframeTiles.length > 0) {
        payload.createIframeTiles = {
          nodes: iframeTiles,
          model: gridPageTileIframeConfig.model,
          attributes: gridPageTileIframeChildren,
        };
      }

      if (savedViews.length > 0) {
        payload.createSavedViews = {
          nodes: savedViews,
          model: savedViewConfig.model,
          attributes: savedViewChildren,
        };
      }

      if (selectedViews.length > 0) {
        payload.createSelectedViews = {
          nodes: selectedViews,
          model: selectedViewConfig.model,
          attributes: selectedViewChildren,
        };
      }
    }

    Object.keys(payload).forEach((alias) => {
      payload[alias].alias = alias;
    });

    const bulkPayloads = [
      payload.createGridPages,
      payload.createRows,
      payload.updateGridpage,
      payload.createTiles,
      payload.updateRows,
      payload.createTextTiles,
      payload.createSingleGoalTiles,
      payload.createSavedViews,
      payload.createSelectedViews,
      payload.createIframeTiles,
    ].filter((p) => p !== undefined);

    return bulkMutate(bulkPayloads).then((response) => response.data.createGridPages.map((gridPage) => gridPageRepo.selectSingle(gridPage.uid)));
  };

  const createGridPageTileDeep = ({ tile, otherTiles }) => {
    const payload = {};

    if (tile.gridPageRow.uid === undefined) {
      payload.createRow = {
        nodes: [tile.gridPageRow],
        model: gridPageRowConfig.model,
        attributes: gridPageRowChildren,
      };
    }

    if (tile.gridPageRow.gridPage !== undefined) {
      payload.updateGridPage = {
        nodes: [tile.gridPageRow.gridPage],
        model: gridPageConfig.model,
        attributes: gridPageChildren,
      };
    }

    payload.createTile = {
      nodes: [tile],
      model: gridPageTileConfig.model,
      attributes: gridPageTileChildren,
    };

    payload.updateRow = {
      nodes: [tile.gridPageRow],
      model: gridPageRowConfig.model,
      attributes: gridPageRowChildren,
    };

    if (otherTiles.length > 0) {
      payload.updateOtherTiles = {
        nodes: otherTiles,
        model: gridPageTileConfig.model,
        attributes: gridPageTileChildren,
      };
    }

    if (tile.singleGoalTile !== null) {
      payload.singleGoalTile = {
        nodes: [tile.singleGoalTile],
        model: gridPageTileSingleGoalConfig.model,
        attributes: gridPageTileSingleGoalChildren,
      };
    }

    if (tile.textTile !== null) {
      payload.textTile = {
        nodes: [tile.textTile],
        model: gridPageTileTextConfig.model,
        attributes: gridPageTileTextChildren,
      };
    }

    if (tile.iframeTile !== null) {
      payload.iframeTile = {
        nodes: [tile.iframeTile],
        model: gridPageTileIframeConfig.model,
        attributes: gridPageTileIframeChildren,
      };
    }

    if (tile.savedViews.length > 0) {
      payload.savedViews = {
        nodes: tile.savedViews,
        model: savedViewConfig.model,
        attributes: savedViewChildren,
      };
    }

    if (tile.selectedView !== null) {
      payload.selectedView = {
        nodes: [tile.selectedView],
        model: selectedViewConfig.model,
        attributes: selectedViewChildren,
      };
    }

    Object.keys(payload).forEach((alias) => {
      payload[alias].alias = alias;
    });

    const bulkPayloads = [
      payload.createRow,
      payload.updateGridPage,
      payload.createTile,
      payload.updateRow,
      payload.updateOtherTiles,
      payload.singleGoalTile,
      payload.textTile,
      payload.savedViews,
      payload.selectedView,
      payload.iframeTile,
    ].filter((p) => p !== undefined);

    return bulkMutate(bulkPayloads).then((response) => gridPageTileRepo.selectSingle(response.data.createTile[0].uid));
  };

  return {
    selectSingleGridPage: gridPageRepo.selectSingle,
    gridPages: gridPageRepo.entityList,
    createGridPage,
    updateGridPage,
    updateGridPageAccessPolicy,
    updateGridPagesAccessPolicy,
    deleteGridPage,
    deleteGridPages,
    queryGridPage,

    createGridPageTile,
    createSingleGoalTiles,
    moveTile,
    removeGridPageTile,
    updateGridPageTiles,
    queryGridPageTileDeep,
    createGridPageTileDeep,

    gridPageTileText: gridPageTileTextRepo.entityList,
    queryGridPageTileTextByTile: (gridPageTile) => queryGridPageTileByTile(gridPageType.text, gridPageTile),
    updateGridPageTileText: (gridPageTileText) => updateGridPageTile(gridPageType.text, gridPageTileText),

    queryGridPagesDeep,
    createGridPagesDeep,

    gridPageRows: gridPageRowRepo.entityList,
    updateGridPageRow,

    gridPageTiles: gridPageTileRepo.entityList,

    gridPageTileSingleGoal: gridPageTileSingleGoalRepo.entityList,
    queryGridPageTileSingleGoalByTile: (gridPageTile) => queryGridPageTileByTile(gridPageType.singleGoal, gridPageTile),
    updateGridPageTileSingleGoal: (gridPageTileSingleGoal) => updateGridPageTile(gridPageType.singleGoal, gridPageTileSingleGoal),

    gridPageTileIframe: gridPageTileIframeRepo.entityList,
    queryGridPageTileIframeByTile: (gridPageTile) => queryGridPageTileByTile(gridPageType.iframe, gridPageTile),
    updateGridPageTileIframe: (gridPageTileIframe) => updateGridPageTile(gridPageType.iframe, gridPageTileIframe),
  };
}
