import { UID } from 'shared/api/query/constants';
import {
  goalCycle as goalCycleConfig,
  gridPageTile as gridPageTileConfig,
  planning as planningConfig,
  savedView as savedViewConfig,
  space as spaceConfig,
  user as userConfig,
} from 'shared/api/query/configs.json';

export const savedViewChildren = [
  { attr: UID },
  { attr: savedViewConfig.edges.title, default: '' },
  { attr: savedViewConfig.edges.emoji, default: '' },
  { attr: savedViewConfig.edges.viewApplication },
  { attr: savedViewConfig.edges.viewType },
  { attr: savedViewConfig.edges.params, default: null },
  { attr: savedViewConfig.edges.isPublic, default: false },
  { attr: savedViewConfig.edges.isPublished, default: false },
  { attr: savedViewConfig.edges.passphrase },
  { attr: savedViewConfig.edges.space, model: spaceConfig.model, children: [{ attr: UID }] },
  { attr: savedViewConfig.edges.planning, model: planningConfig.model, children: [{ attr: UID }] },
  { attr: savedViewConfig.edges.gridPageTile, model: gridPageTileConfig.model, children: [{ attr: UID }] },
  { attr: savedViewConfig.edges.goalSort, default: [] },
  {
    attr: savedViewConfig.edges.creator,
    model: userConfig.model,
    children: [{ attr: UID }],
    default: null,
  },
  { attr: savedViewConfig.edges.goalCycles, default: [], model: goalCycleConfig.model, children: [{ attr: UID }] },
  { attr: savedViewConfig.edges.lockCycleOnView, default: false },
  { attr: savedViewConfig.edges.unassignedGoalCycleSelected, default: false },
];
