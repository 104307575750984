import { RESULT, UID } from 'shared/api/query/constants';
import { customDatasourceProperty, dataSourceType } from 'shared/constants.json';
import { dataSource as dataSourceConfig, user as userConfig } from 'shared/api/query/configs.json';

export const dataSourceChildren = [
  { attr: UID },
  { attr: dataSourceConfig.edges.name, default: '' },
  { attr: dataSourceConfig.edges.healthy, default: true },
  { attr: dataSourceConfig.edges.dead, default: false },
  { attr: dataSourceConfig.edges.accessToken, default: '' },
  { attr: dataSourceConfig.edges.type },
  { attr: dataSourceConfig.edges.shared },
  { attr: dataSourceConfig.edges.creator, model: userConfig.model, children: [{ attr: UID }], default: null },
  { attr: dataSourceConfig.edges.apiServer, default: '' },
  { attr: dataSourceConfig.edges.apiToken, default: '' },
  { attr: dataSourceConfig.edges.refreshTokenCreatedAt, default: null },
  { attr: dataSourceConfig.edges.apiUser, default: '' },
  { attr: dataSourceConfig.edges.authenticationType, default: '' },
  { attr: dataSourceConfig.edges.oauthServer, default: '' },
  { attr: dataSourceConfig.edges.oauthv2ClientId, default: '' },
  { attr: dataSourceConfig.edges.oauthv2ClientSecret, default: '' },
];
const dataSourceChildrenByType = (type) => {
  const children = [...dataSourceChildren];
  children.push({ attr: customDatasourceProperty.dataSourceError, default: null });
  switch (type) {
    case dataSourceType.excel:
    case dataSourceType.sheets:
      children.push(...[
        { attr: customDatasourceProperty.spreadsheetDocuments, default: [] },
      ]);
      break;
    case dataSourceType.asana:
      children.push(...[
        { attr: customDatasourceProperty.asanaProjects, default: [] },
        { attr: customDatasourceProperty.asanaWorkspaces, default: [] },
      ]);
      break;
    case dataSourceType.salesforce:
      children.push(...[
        { attr: customDatasourceProperty.salesforceReports, default: [] },
      ]);
      break;
    case dataSourceType.msPowerBi:
      children.push(...[
        { attr: customDatasourceProperty.msPowerBiDatasets, default: [] },
      ]);
      break;
    case dataSourceType.msPlanner:
      children.push(...[
        { attr: customDatasourceProperty.msPlannerPlans, default: [] },
        { attr: customDatasourceProperty.msPlannerBuckets, default: [] },
        { attr: customDatasourceProperty.msPlannerLabels, default: [] },
      ]);
      break;
    case dataSourceType.jira:
    case dataSourceType.hubspot:
    default:
      break;
  }
  return children;
};

export const dataSourceQuery = (dataSource) => [
  {
    alias: RESULT,
    func: { name: UID },
    uid: [dataSource.uid],
    model: dataSourceConfig.model,
    children: dataSourceChildrenByType(dataSource.type),
  },
];
