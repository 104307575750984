import useGoalDefaultProps from '@/composables/saved-views/goal-default-props';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useProperties from '@/composables/property/property';
import { CREATED_AT } from 'shared/api/query/constants';
import { computed } from 'vue';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { propertyType, propertyVisibility } from 'shared/constants.json';
import { shallowCopy } from 'shared/lib/copy';
import { sortByArray } from 'shared/lib/sort';

export default function useAlignedItemsProperties() {
  const { goalSettings, updateGoalSettings } = useGoalSettings();
  const propertySvc = useProperties();
  const { defaultProps } = useGoalDefaultProps();

  const getDirectPropName = (edgeName) => {
    switch (edgeName) {
      case goalConfig.edges.goalCycle:
        return 'goalCycleChildrenVisibility';
      case goalConfig.edges.cachedLastUpdatedAt:
        return 'lastUpdatedAtChildrenVisibility';
      case CREATED_AT:
        return 'createdAtChildrenVisibility';
      default:
        return '';
    }
  };
  const getKey = (p) => {
    if (!p.isDirect) {
      return p.property.uid;
    }

    return getDirectPropName(p.property.edgeName);
  };

  const updateVisibility = (property, value) => {
    const v = value ? propertyVisibility.alwaysVisible : propertyVisibility.alwaysHidden;
    if (typeof property.edgeName !== 'undefined') {
      return updateDirectPropVisibility(property, v);
    }

    return updatePropVisibility(property, v);
  };
  const updateDirectPropVisibility = (property, value) => {
    const key = getDirectPropName(property.edgeName);
    return updateGoalSettings(
      {
        uid: goalSettings.value.uid,
        [key]: value,
      },
    );
  };

  const updatePropVisibility = (property, value) => propertySvc.updateSingle({
    uid: property.uid,
    goalChildrenVisibility: value,
  });

  const childrenProps = computed(() => defaultProps.value
    .filter((p) => p.property.type !== propertyType.status
            && (!p.isDirect || p.isTitle || (p.isDirect && [goalConfig.edges.goalCycle, CREATED_AT, goalConfig.edges.cachedLastUpdatedAt, goalConfig.edges.cachedCalculatedCurrent].includes(p.property.edgeName))))
    .map((p) => {
      if (p.isDirect) {
        if (p.property.edgeName === goalConfig.edges.cachedCalculatedCurrent) {
          return {
            ...p,
            property: {
              ...p.property,
              disableInProps: true,
              noSortInProps: true,
            },
            show: goalSettings.value[getKey(p)] !== propertyVisibility.alwaysHidden,
          };
        }
        return {
          ...p,
          show: goalSettings.value[getKey(p)] !== propertyVisibility.alwaysHidden,
        };
      }

      if (typeof p.property.goalChildrenVisibility === 'undefined') {
        return p;
      }

      return {
        ...p,
        show: p.property.goalChildrenVisibility !== propertyVisibility.alwaysHidden,
      };
    }));
  const childrenPropertyOrder = computed(() => goalSettings.value.childrenPropertyOrder);

  const sortedChildrenProps = computed(() => {
    const propOrder = shallowCopy(childrenPropertyOrder.value);
    if (!propOrder.includes(goalConfig.edges.cachedCalculatedCurrent)) {
      propOrder.unshift({ key: goalConfig.edges.cachedCalculatedCurrent });
    }
    if (!propOrder.includes(goalConfig.edges.title)) {
      propOrder.unshift({ key: goalConfig.edges.title });
    }
    return shallowCopy(childrenProps.value).sort(sortByArray(propOrder, 'key'));
  });

  const updateChildrenPropOrder = (childrenPropertyOrder) => updateGoalSettings({
    uid: goalSettings.value.uid,
    childrenPropertyOrder,
  });

  return {
    updateVisibility,
    updateChildrenPropOrder,
    childrenPropertyOrder,
    childrenProps: sortedChildrenProps,
    sortedChildrenProps,
  };
}
