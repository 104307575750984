<template>
  <div class="feed-entry-item">
    <div class="_top">
      <div class="_user">
        <feed-entry-icon :feed-entry="feedEntry" />
      </div>
      <div class="_right">
        <div class="_title">
          <feed-entry-title :feed-entry="feedEntry" />
        </div>
        <div class="_date">
          {{ formatDate(feedEntry.createdAt) }}
        </div>
      </div>
      <div class="_indicator">
        <div
          v-if="feedEntry.readAt === null"
          class="_circle"
        />
      </div>
    </div>
    <div
      v-if="hasPayload"
      class="_payload"
    >
      <template
        v-if="feedEntry.update !== null"
      >
        <div class="_title">
          <template v-if="feedEntry.update.creator === null">
            {{ $t('user.deleted.name') }}
          </template>
          <template v-else>
            {{ feedEntry.update.creator.firstName }} {{ feedEntry.update.creator.lastName }}
          </template>
        </div>
        <update-feed-item
          :update="feedEntry.update"
          :level="0"
          hide-props
          hide-top
          :editor-font-size="fontSizes[4]"
        />
      </template>
      <notification-comment
        v-else-if="feedEntry.comment !== null"
        :feed-entry="feedEntry"
      />
      <data-source-query-error
        v-else-if="feedEntry.dataSourceQueryError !== null"
        :data-source="feedEntry.dataSource"
        :error="feedEntry.dataSourceQueryError"
      />
      <m-btn
        v-else-if="feedEntry.type === feedEntryType.feedbackReceived"
        :to="{ query: { feedbackAnswerId: feedEntry.feedbackAnswer.uid } }"
      >
        {{ $t('notificationFeed.view') }}
      </m-btn>
    </div>
    <div
      v-if="hasAction"
      class="_payload"
    >
      <div style="display: inline-flex;">
        <div
          v-if="feedEntry.type === feedEntryType.feedbackInquiry && feedEntry.feedbackAnswer.status === feedbackAnswerStatus.declined"
          class="_fulfilled-msg"
        >
          <div class="_msg">
            <div class="_checkmark">
              <m-icon type="close-circle" />
            </div>
            <div class="_text">
              {{ $t('notificationFeed.feedbackStatusDeclined') }}
            </div>
            <m-btn
              :to="{ query: { feedbackAnswerId: feedEntry.feedbackAnswer.uid } }"
            >
              {{ $t('notificationFeed.giveFeedback') }}
            </m-btn>
          </div>
        </div>
        <template
          v-else-if="!isFulfilled"
        >
          <m-btn
            v-for="(action, index) in actions"
            :key="index"
            :to="action.to"
            class="_action"
            @click="handleFeedEntryClick(action)"
          >
            <m-icon
              v-if="action.icon"
              :type="action.icon"
              class="_icon"
            />
            {{ action.title }}
          </m-btn>
        </template>
        <div
          v-else-if="isFulfilled"
          class="_fulfilled-msg"
        >
          <div class="_checkmark">
            <m-icon type="check-circle" />
          </div>
          <div>
            {{ $t(`notificationFeed.actionCompleted.${camelCase(feedEntry.type)}`) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DataSourceQueryError from '@/components/datasource/DataSourceQueryError.vue';
import FeedEntryIcon from '@/components/inbox/FeedEntryIcon.vue';
import FeedEntryTitle from '@/components/inbox/FeedEntryTitle.vue';
import NotificationComment from '@/components/inbox/NotificationComment.vue';
import UpdateFeedItem from '@/components/updates/UpdateFeedItem.vue';
import notificationFeedMixin from '@/mixins/notification-feed';
import { DateTime } from 'luxon';
import { FORM_DETAILS, GOAL_DETAILS, UPDATES_EXPLORER } from '@/route-names';
import { camelCase } from 'lodash-es';
import { connectUrl, dataSourceSignInIcon } from '@/lib/data-source';
import { dataSourceInstallStatus } from '@/constants.json';
import { feedEntryType, feedbackAnswerStatus } from 'shared/constants.json';
import { feedbackAnswer as feedbackAnswerConfig } from 'shared/api/query/configs.json';
import { fontSizes } from 'shared/font-sizes';
import { initOAuthInstall } from '@/lib/oauth-redirect';
import { mapActions } from 'vuex';

export default {
  name: 'FeedEntryItem',
  props: {
    feedEntry: {
      type: Object,
      required: true,
    },
  },
  components: {
    DataSourceQueryError,
    NotificationComment,
    UpdateFeedItem,
    FeedEntryIcon,
    FeedEntryTitle,
  },
  data() {
    return {
      feedEntryType,
      camelCase,
      feedbackAnswerStatus,
      fontSizes,
    };
  },
  computed: {
    hasPayload() {
      const hasPayload = [
        feedEntryType.goalUpdate,
        feedEntryType.goalComment,
        feedEntryType.goalUpdateMention,
        feedEntryType.updateMention,
        feedEntryType.updateComment,
        feedEntryType.goalUpdateComment,
        feedEntryType.feedbackReceived,
      ].includes(this.feedEntry.type);

      const hasPayloadIf = feedEntryType.dataSourceQueryError === this.feedEntry.type && !this.isFulfilled;

      return hasPayload || hasPayloadIf;
    },
    payload() {
      return this.feedEntry.update.message;
    },
    hasAction() {
      return [
        feedEntryType.survey,
        feedEntryType.updateNotification,
        feedEntryType.feedbackInquiry,
        feedEntryType.dataSourceUnhealthy,
        feedEntryType.dataSourceDead,
        feedEntryType.dataSourceQueryError,
      ].includes(this.feedEntry.type);
    },
    isFulfilled() {
      if (this.feedEntry.type === feedEntryType.feedbackInquiry) {
        return [feedbackAnswerStatus.done].includes(this.feedEntry.feedbackAnswer.status);
      }
      return this.feedEntry.fulfilledAt !== null;
    },
    actions() {
      if ([feedEntryType.survey, feedEntryType.updateNotification, feedEntryType.dataSourceQueryError].includes(this.feedEntry.type)) {
        return [
          {
            to: this.feedEntryAction,
            title: this.$t(`notificationFeed.action.${camelCase(this.feedEntry.type)}`),
          },
        ];
      }

      if (this.feedEntry.type === feedEntryType.feedbackInquiry) {
        return [
          {
            to: { query: { feedbackAnswerId: this.feedEntry.feedbackAnswer.uid } },
            title: this.$t('notificationFeed.giveFeedback'),
          },
          {
            onClick: this.declineFeedbackRequest(this.feedEntry.feedbackAnswer, this.feedEntry),
            title: this.$t('notificationFeed.declineFeedbackInquiry'),
          },
        ];
      }

      if ([feedEntryType.dataSourceUnhealthy, feedEntryType.dataSourceDead].includes(this.feedEntry.type)) {
        return [
          {
            onClick: this.refreshConnection(this.feedEntry.dataSource, this.feedEntry),
            title: this.$t(`notificationFeed.action.${camelCase(this.feedEntry.type)}`),
            icon: dataSourceSignInIcon(this.feedEntry.dataSource),
          },
        ];
      }

      return [];
    },
    feedEntryAction() {
      switch (this.feedEntry.type) {
        case feedEntryType.survey:
          return {
            name: FORM_DETAILS,
            params: { id: this.feedEntry.form.uid },
          };
        case feedEntryType.updateNotification:
          if (this.feedEntry.notification.updateTemplate === null) {
            return {
              name: UPDATES_EXPLORER,
              query: { create: '', notificationId: this.feedEntry.notification.uid },
            };
          }
          return {
            name: UPDATES_EXPLORER,
            query: {
              create: '',
              templateId: this.feedEntry.notification.updateTemplate.uid,
              notificationId: this.feedEntry.notification.uid,
            },
          };
        case feedEntryType.dataSourceQueryError:
          return {
            name: GOAL_DETAILS,
            params: { goalId: this.feedEntry.goal.uid },
          };
        default:
          return null;
      }
    },
  },
  methods: {
    ...mapActions(['updateEntityV2']),
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
    },
    declineFeedbackRequest(feedbackAnswer, feedEntry) {
      return () => {
        this.updateEntityV2({
          entity: { ...feedbackAnswer, status: feedbackAnswerStatus.declined },
          model: feedbackAnswerConfig.model,
          attributes: [],
          mutation: 'FEEDBACK_ANSWER_UPDATED',
          options: { feedEntry },
        }).then((response) => {
          if (response.status !== 200) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          }
        });
      };
    },
    refreshConnection(dataSource) {
      return () => {
        initOAuthInstall(connectUrl(dataSource)).then((event) => {
          switch (event.data.status) {
            case dataSourceInstallStatus.error:
            case dataSourceInstallStatus.notAllowed:
              this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
              break;
            case dataSourceInstallStatus.success:
              this.$showSnackbar({ color: 'success', message: this.$t('dataSource.successfullySynced') });
              break;
            default:
              break;
          }
        });
      };
    },
    handleFeedEntryClick(action) {
      if (typeof action.onClick === 'undefined') {
        return;
      }

      action.onClick();
    },
  },
  mixins: [notificationFeedMixin],
};
</script>

<style scoped lang="scss" type="text/scss">
  .feed-entry-item {
    ._top {
      display: flex;
      margin-bottom: 1.2rem;

      ._user {
        display: flex;
        flex: 0 0 5rem;
        justify-content: center;
      }

      ._date {
        font-size: $font-size-4;
        color: $font-color-tertiary;
      }
    }

    ._indicator {
      margin-top: .6rem;
      margin-left: auto;

      ._circle {
        width: .8rem;
        height: .8rem;
        background-color: $primary-color;
        border-radius: .4rem;
      }
    }

    ._payload {
      padding-left: 5rem;

      ._title {
        font-size: $font-size-2;
        color: $font-color-secondary;
      }

      ._fulfilled-msg {
        display: flex;
        flex-direction: row;
        align-items: center;

        ._msg {
          display: flex;
          align-items: center;

          ._text {
            margin-right: 1.2rem;
          }
        }

        ._checkmark {
          display: flex;
          align-items: center;
          margin-right: .8rem;
        }
      }

      ._user {
        font-size: $font-size-4;
        color: $font-color-tertiary;
      }

      ._action {
        margin-right: .6rem;

        ._icon {
          margin-right: 1.2rem;
        }
      }
    }
  }
</style>
