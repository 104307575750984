<template>
  <div class="goal-cycle-list">
    <div class="_items">
      <template
        v-for="(group, index) in cycleGroups"
        :key="index"
      >
        <m-card-group
          :title="group.title"
          :expanded="expandAll === true || index === 0 || groupHasItem(group, selected)"
        >
          <div
            v-if="group.children.length === 0"
            class="_empty"
          >
            {{ $t('goalCycleSelector.emptyGroup') }}
          </div>
          <selector-item
            v-for="item in group.children"
            :key="item.uid"
            :item="item"
            :selected="selected"
            :selectable="selectable"
            :level="0"
            @select="select"
          />
        </m-card-group>
      </template>
      <selector-item
        v-if="withUnassigned"
        :item="unassignedCycle"
        :selected="selected"
        :level="-1"
        :selectable="selectable"
        @select="select"
      />
    </div>
    <template v-if="editable && showLockCycleOption">
      <m-divider xxs />
      <m-card-item
        :tooltip="$t('goalCycleSelector.tooltipLockCycleButton')"
        @click="toggleLockCycle"
      >
        <m-switch
          :value="isCycleLockedOnView"
          class="_item"
          :label="$t('goalCycleSelector.lockCycle')"
          small
        />
      </m-card-item>
    </template>
  </div>
</template>

<script>
import SelectorItem from '@/components/goal/cycle/SelectorItem.vue';
import useUnassignedGoalCycle from '@/composables/goal-cycle/unassigned-goal-cycle';
import { intersection } from 'shared/lib/array/array';
import { routeName } from 'shared/constants.json';

export default {
  name: 'GoalCycleList',
  props: {
    cycleGroups: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    isCycleLockedOnView: {
      type: Boolean,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    withUnassigned: {
      type: Boolean,
      default: false,
    },
    expandAll: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    showLockCycleOption: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { unassignedCycle } = useUnassignedGoalCycle();
    return { unassignedCycle };
  },
  components: { SelectorItem },
  emits: ['select', 'set-lock-cycle-on-view'],
  computed: {
    goalCyclesSettings() {
      return { name: routeName.goalCycles };
    },
  },
  methods: {
    groupHasItem(group, item) {
      return intersection(item.map((c) => c.uid), group.children.map((c) => c.uid)).length > 0;
    },
    select(item) {
      if (!this.selectable) {
        return;
      }

      if (!this.multiple) {
        if (this.selected.map((c) => c.uid).includes(item.uid)) {
          this.$emit('select', []);
          return;
        }
        this.$emit('select', [item]);
        return;
      }

      const otherItems = this.selected.filter((c) => c.uid !== item.uid);
      if (otherItems.length === this.selected.length) {
        this.$emit('select', [...otherItems, item]);
        return;
      }

      this.$emit('select', otherItems);
    },
    toggleLockCycle() {
      this.$emit('set-lock-cycle-on-view', !this.isCycleLockedOnView);
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .goal-cycle-list {
    ._items {
      max-height: 60vh;
      padding-bottom: .4rem;
      overflow: auto;

      @media (max-width: $screen-size-sm) {
        max-height: 100vh;
      }
    }

  }
</style>
