import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useRepo from '@/nebula/repo';
import { jiraQuery as jiraQueryConfig } from 'shared/api/query/configs.json';

export default function useJira() {
  const queryRepo = useRepo(jiraQueryConfig.model);

  const { loggedInUser } = useLoggedInUser();

  const createQuery = (entity) => queryRepo.createSingle({
    ...entity,
    creator: { uid: loggedInUser.value.uid },
  });

  return {
    createQueryLoading: queryRepo.createLoading,
    createQuery,
    updateQueryLoading: queryRepo.updateLoading,
    updateQuery: (entity) => queryRepo.updateSingle(entity, { ignoreResponse: false }),
    deleteQueryLoading: queryRepo.deleteLoading,
    deleteQuery: queryRepo.deleteSingle,
  };
}
