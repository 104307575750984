<template>
  <r-c-layout
    class="onboarding-guide"
    show-back-button
    :resource-center-router="resourceCenterRouter"
  >
    <template #title>
      <h5>
        {{ $t('rcOverview.onboardingGuide.title') }}
      </h5>
      <progress-tag
        :progress="progress"
        show-text
        :style="{ marginLeft: 'auto' }"
      />
    </template>
    <m-content
      padding-small
    >
      <div
        v-for="(group, i) in groupedOnboardingSteps"
        :key="i"
        :style="{ border: `1px solid ${$colors.grey.lighten3}`, borderRadius: '.4rem', marginBottom: '2rem' }"
      >
        <m-content
          :padding-y="7"
          :padding-x="3"
        >
          <sub-heading
            :title="group.title"
            :style="{ marginTop: 0 }"
          />
          <template
            v-for="(step, j) in group.steps"
            :key="j"
          >
            <m-link
              :target="step.link !== undefined ? step.link.target : undefined"
              :href="step.link !== undefined ? step.link.href : undefined"
              :to="step.link !== undefined ? step.link.to : undefined"
              inherit-color
              @click="step.onclick"
            >
              <m-card-item
                icon="check-mark-circle-filled"
                :icon-color="step.done ? $colors.blue.base : rgbaToHex($colors.grey.lighten5)"
                :style="{ borderRadius: '.4rem' }"
              >
                {{ step.title }}
                <m-icon
                  v-if="step.suffixIcon !== undefined"
                  :type="step.suffixIcon"
                  :color="$colors.grey.base"
                  :style="{ marginLeft: '.6rem' }"
                />
                <m-tag
                  v-if="step.videoDuration !== undefined"
                  icon="play-circle-filled"
                  :title="step.videoDuration"
                  :custom-color="{ color: $colors.grey.base, backgroundColor: $colors.grey.lighten4 }"
                  xs
                  :style="{ marginLeft: '.6rem' }"
                />
              </m-card-item>
            </m-link>
          </template>
        </m-content>
      </div>
      <m-btn
        light
        hide-border
        icon="check-circle"
        block
        :loading="markAsDoneLoading"
        @click="markAsDone([onboardingGuideSteps.markAllAsCompleted])"
      >
        {{ $t('onboardingGuide.markAllAsDone') }}
      </m-btn>
    </m-content>
  </r-c-layout>
</template>

<script>
import ProgressTag from '@/components/ProgressTag.vue';
import RCLayout from '@/components/resource-center/RCLayout.vue';
import SubHeading from 'shared/components/SubHeading.vue';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOnboardingActions from '@/composables/onboarding/onboarding-actions';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import useOnboardingSteps from '@/composables/onboarding/onboarding-steps';
import { onboardingGuideSteps } from 'shared/constants.json';
import { rgbaToHex } from 'shared/lib/color';

export default {
  name: 'RcOnboardingGuide',
  props: {
    resourceCenterRouter: {
      type: Object,
      required: true,
    },
  },
  components: { SubHeading, ProgressTag, RCLayout },
  emits: ['close'],
  data() {
    return { rgbaToHex, onboardingGuideSteps };
  },
  computed: {},
  setup(props) {
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const accountSettingsSvc = useAccountSettings();
    const accessSvc = useAccess();

    const onboardingMarker = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);
    const onboardingActions = useOnboardingActions(onboardingMarker, props.resourceCenterRouter);
    const { groupedSteps, progress } = useOnboardingSteps(
      onboardingMarker,
      onboardingActions,
      accountSettingsSvc,
      accessSvc,
    );
    return {
      progress,
      markAsDoneLoading: onboardingMarker.markAsDoneLoading,
      markAsDone: onboardingMarker.markAsDone,
      groupedOnboardingSteps: groupedSteps,
    };
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
