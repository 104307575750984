<template>
  <goals-list-wrapper
    :header-slots="headerSlots"
    :can-create-goals="canCreate"
    :allowed-view-types="allowedViewTypes"
    stretch-content
    :show-create-view="isLoggedInUser"
    show-views-selector
    :base-filter="publishedAtFilter"
    fullscreen
    show-errors
    :default-props="defaultProps"
    :selected-cycles="selectedCycles"
    :is-cycle-locked-on-view="isCycleLockedOnView"
    :change-selected-goal-cycles="changeSelectedGoalCycles"
    :lock-cycle-on-view="lockCycleOnView"
  />
</template>

<script>
import GoalsListWrapper from '@/components/goal/GoalsListWrapper.vue';
import useAccess from '@/composables/access/access';
import useAdaptiveGoalCycle from '@/composables/goal-cycle/adaptive-goal-cycle';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalDefaultProps from '@/composables/saved-views/goal-default-props';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { accessGroupFlag, viewApplication, viewType } from 'shared/constants.json';
import { computed, provide } from 'vue';
import { personalGoalsFilter } from '@/lib/filter/goal/personal-goals-filter';
import { useI18n } from 'vue-i18n';

export default {
  name: 'ProfileGoals',
  props: {
    user: {
      type: Object,
      required: true,
    },
    isLoggedInUser: {
      type: Boolean,
      default: false,
    },
  },
  components: { GoalsListWrapper },
  setup(props) {
    const { userHasRight } = useAccess();
    const { goalCycles } = useGoalCycle();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { publishedAtFilter } = usePublishedAtFilter();
    const i18n = useI18n();

    const propertySvc = useProperties();

    const allowedViewTypes = [viewType.cascade, viewType.tree, viewType.list, viewType.atlas];

    const initialFilter = computed(() => personalGoalsFilter({
      goalProperties: propertySvc.goalProperties.value,
      user: props.user,
      account: { uid: loggedInUserAccount.value.uid },
    }));

    const viewApp = computed(() => {
      if (props.isLoggedInUser) {
        return viewApplication.goalHome;
      }
      return viewApplication.goalUserProfile;
    });

    const defaultView = {
      title: i18n.t('list.cascade'),
      viewType: viewType.cascade,
      viewApplication: viewApp.value,
      params: {
        filter: initialFilter.value,
        order: [],
        props: [],
        wrapCells: true,
      },
    };

    const viewServiceInitService = useViewServiceInit();
    const defaultPropsSvc = useGoalDefaultProps();

    let routeAwareConfig = viewServiceInitService.defaultConfig();
    routeAwareConfig = viewServiceInitService.withDefaultView(routeAwareConfig, defaultView);
    routeAwareConfig = viewServiceInitService.withViewApplication(routeAwareConfig, viewApp.value);
    routeAwareConfig = viewServiceInitService.withUser(routeAwareConfig, { uid: props.user.uid });
    routeAwareConfig = viewServiceInitService.withDefaultProps(routeAwareConfig, defaultPropsSvc.defaultProps);

    const viewSvc = viewServiceInitService.routeAwareViewService(routeAwareConfig);
    provide(VIEWS_SERVICE, viewSvc);

    const { isCycleLockedOnView, goalCycleSvc, lockCycleOnView } = useAdaptiveGoalCycle(viewSvc);

    return {
      goalCycleSvc,
      lockCycleOnView,
      currentView: viewSvc.currentView,
      viewService: viewSvc,
      isCycleLockedOnView,

      publishedAtFilter,
      account: loggedInUserAccount,
      goalCycles,
      userHasRight,

      allowedViewTypes,

      viewApp,
      defaultProps: defaultPropsSvc.defaultProps,
    };
  },
  computed: {
    selectedCycles() {
      return this.goalCycleSvc.composedSelectedCycles.value;
    },
    changeSelectedGoalCycles() {
      return this.goalCycleSvc.changeSelectedGoalCycles;
    },
    headerSlots() {
      const slots = [{ name: SLOTS.CYCLE_SELECTOR }];
      if (this.isLoggedInUser) {
        slots.push({ name: SLOTS.FILTER });
      }
      slots.push(...[{ name: SLOTS.SORTER }, { name: SLOTS.STRING_FILTER }, { name: SLOTS.PROPERTIES }, { name: SLOTS.COMPARE_TO }, { name: SLOTS.PROGRESS_DISPLAY_OPTION }]);
      return slots;
    },
    canCreate() {
      return this.userHasRight([accessGroupFlag.createGoal]);
    },
  },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
</style>
