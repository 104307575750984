<template>
  <page-layout
    :class="['scheduled-reports']"
  >
    <template #topBar>
      <page-top-bar
        :breadcrumbs="breadcrumbs"
        class="_header"
      >
        <template #actions>
          <favorite-button
            v-if="!$store.state.breakpoint.smAndDown"
            :title-suggestion="$t('dashboards.reports')"
          />
          <m-dropdown
            v-else
            v-model:value="showFurtherActions"
            :title="$t('general.actions')"
          >
            <m-btn
              icon="ellipsis"
              hide-border
              fab
              small
            />
            <template #overlay>
              <m-card
                no-padding
                list
              >
                <favorite-button
                  :title-suggestion="$t('dashboards.reports')"
                />
              </m-card>
            </template>
          </m-dropdown>
        </template>
      </page-top-bar>
    </template>
    <scroll-container>
      <m-content
        padding-x="layout"
        class="_header"
      >
        <editable-page-header
          :title="$t('dashboards.reports')"
          disabled
          size="small"
        />
        Table comes here
      </m-content>
    </scroll-container>
  </page-layout>
</template>

<script setup>

import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import { buildIcon } from 'shared/lib/icon';
import { computed, ref } from 'vue';
import { routeName } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const breadcrumbs = computed(() => [
  {
    title: t('navigation.dashboards'),
    icons: [{ value: buildIcon('AppstoreOutlined') }],
    disabled: false,
    to: { name: routeName.dashboards },
  },
  {
    title: t('dashboards.reports'),
    disabled: true,
  },
]);

const showFurtherActions = ref(false);
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
</style>
