<template>
  <div class="integration-settings">
    <page-header
      :title="$t('integrationSettings.title')"
      boxed-xs
      heading="h3"
      underlined
      class="_header"
    />
    <m-content
      boxed-xs
      padding
      class="_content"
    >
      <m-btn
        small
        super-light
        :href="$t('integrationSettings.helpLink')"
        hide-border
        icon="question-circle"
        target="_blank"
        class="_help-btn"
      >
        {{ $t('integrationSettings.help') }}
      </m-btn>
      <m-section
        heading-size="h4"
        :title="$t('integrationSettings.messaging.title')"
        class="_section"
      >
        <slack-switch :disabled="!userHasRight([accessGroupFlag.accountWriteAccess])" />
        <m-divider small />
        <m-s-teams-switch :disabled="!userHasRight([accessGroupFlag.accountWriteAccess])" />
        <m-divider small />
      </m-section>
      <m-section
        heading-size="h4"
        :title="$t('integrationSettings.dataIntegrations.title')"
        class="_section"
      >
        <template
          v-for="(type, index) in types"
          :key="index"
        >
          <settings-switch-item
            :title="$t(`dataSources.${camelCase(type)}`)"
            :icon="kebabCase(type)"
            :sub-title="$t(`integrationSettings.${camelCase(type)}SubTitle`)"
            :value="accountSettings[accountSettingsEdgeByFlag(type)]"
            :clickable="false"
            :disabled="!userHasRight([accessGroupFlag.accountWriteAccess]) || !canToggleFlag(type)"
            @toggle-clicked="toggle(type)"
          >
            <template
              v-if="planRestrictedTypes.includes(type)"
              #after-title
            >
              <plan-tag
                :style="{ display: 'inline-flex', marginLeft: '.6rem' }"
                :feature-flag="type"
              />
            </template>
            <template
              #before-switch
            >
              <m-btn
                :disabled="!canManage[type]"
                hide-border
                small
                light
                :to="{ name: routeName.dataSourceDetails, params: { type} }"
              >
                {{ $t('integrationSettings.manage') }}
              </m-btn>
            </template>
          </settings-switch-item>
          <m-divider
            small
          />
        </template>
        <div class="_sync-days">
          <h5>
            {{ $t('integrationSettings.syncDays') }}
          </h5>
          <div class="_inner">
            <div class="_left">
              <div class="_icon">
                <m-icon
                  type="calendar"
                  :color="$colors.grey.base"
                  size="20"
                />
              </div>
              <div class="_text">
                <div class="_title">
                  {{ $t('integrationSettings.syncDaysTitle') }}
                </div>
                <div class="_description">
                  {{ $t('integrationSettings.syncDaysDescription') }}
                </div>
              </div>
            </div>
            <div class="_right">
              <m-select
                :items="weekdays"
                :value="syncDays"
                multiple
                hide-border
                tags
                nowrap
                :truncate-selected-items="2"
                :disabled="!userHasRight([accessGroupFlag.accountWriteAccess])"
                @input="setSyncDays"
              />
            </div>
          </div>
        </div>
      </m-section>
    </m-content>
  </div>
</template>

<script>
import MSTeamsSwitch from '@/components/integrations/MSTeamsSwitch.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import PlanTag from '@/components/plans/PlanTag.vue';
import SettingsSwitchItem from '@/components/SettingsSwitchItem.vue';
import SlackSwitch from '@/components/integrations/SlackSwitch.vue';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import { accessGroupFlag, dataSourceType, routeName, weekday } from 'shared/constants.json';
import { camelCase, kebabCase, upperFirst } from 'lodash-es';

export default {
  name: 'IntegrationSettings',
  components: { PlanTag, PageHeader, SettingsSwitchItem, SlackSwitch, MSTeamsSwitch },
  data() {
    return { routeName, dataSourceType, camelCase, kebabCase, upperFirst };
  },
  setup() {
    const { userHasRight } = useAccess();

    const {
      accountSettings,
      toggleFlag,
      canToggleFlag,
      canManageFlag,
      updateAccountSettings,
      accountSettingsEdgeByFlag,
    } = useAccountSettings();
    return {
      accountSettings,
      toggleFlag,
      canToggleFlag,
      canManageFlag,
      updateAccountSettings,
      accountSettingsEdgeByFlag,
      userHasRight,
      accessGroupFlag,
    };
  },
  computed: {
    syncDays() {
      const weekdayTranslation = {
        [weekday.monday]: 0,
        [weekday.tuesday]: 1,
        [weekday.wednesday]: 2,
        [weekday.thursday]: 3,
        [weekday.friday]: 4,
        [weekday.saturday]: 5,
        [weekday.sunday]: 6,
      };

      const reverseTranslation = Object.keys(weekdayTranslation).reduce((res, cur) => ({
        ...res,
        [weekdayTranslation[cur]]: cur,
      }), {});

      const s = this.accountSettings.syncDays.map((d) => {
        if (typeof weekdayTranslation[d] === 'undefined') {
          return -1;
        }
        return weekdayTranslation[d];
      });
      s.sort();
      return s.map((d) => reverseTranslation[d]);
    },
    types() {
      return [
        dataSourceType.jira,
        dataSourceType.sheets,
        dataSourceType.excel,
        dataSourceType.asana,
        dataSourceType.hubspot,
        dataSourceType.salesforce,
        dataSourceType.msPowerBi,
        dataSourceType.msPlanner,
      ];
    },
    planRestrictedTypes() {
      return [
        dataSourceType.salesforce,
        dataSourceType.msPowerBi,
        dataSourceType.msPlanner,
      ];
    },
    weekdays() {
      return [
        {
          text: this.$t('rRulePicker.monday'),
          value: weekday.monday,
        },
        {
          text: this.$t('rRulePicker.tuesday'),
          value: weekday.tuesday,
        },
        {
          text: this.$t('rRulePicker.wednesday'),
          value: weekday.wednesday,
        },
        {
          text: this.$t('rRulePicker.thursday'),
          value: weekday.thursday,
        },
        {
          text: this.$t('rRulePicker.friday'),
          value: weekday.friday,
        },
        {
          text: this.$t('rRulePicker.saturday'),
          value: weekday.saturday,
        },
        {
          text: this.$t('rRulePicker.sunday'),
          value: weekday.sunday,
        },
      ];
    },
    canManage() {
      const res = {};
      this.types.forEach((t) => {
        if (!this.userHasRight([accessGroupFlag.accountWriteAccess])) {
          res[t] = this.accountSettings[this.accountSettingsEdgeByFlag(t)] === true;
          return;
        }
        res[t] = this.canManageFlag(t);
      });
      return res;
    },
  },
  methods: {
    setSyncDays(value) {
      const entity = {
        ...this.accountSettings,
        syncDays: value,
      };
      this.updateAccountSettings(entity).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    toggle(type) {
      this.toggleFlag(type).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .integration-settings {
    ._header {
      margin-bottom: 2rem;
    }

    ._help-btn {
      margin-bottom: 3rem;
    }

    ._section {
      margin-bottom: 4rem;
    }

    ._sync-days {
      margin-top: 2rem;

      ._inner {
        display: flex;
        align-items: center;

        ._left {
          display: flex;
          flex: 1 1 auto;
          align-items: center;

          ._icon {
            display: flex;
            flex: 0 0 4rem;
            justify-content: flex-start;
          }

          ._text {
            ._description {
              font-size: $font-size-2;
              color: $font-color-secondary;
            }
          }
        }

        ._right {
          display: flex;
          flex: 1 0 25rem;
          justify-content: flex-end;
          margin-left: auto;
        }
      }
    }
  }

  ._week-day-items {
    display: flex;
    flex-wrap: nowrap;
  }
</style>
