import useInMemoryGoalCycle from '@/composables/goal-cycle/in-memory-goal-cycle';
import usePersistedGoalCycle from '@/composables/goal-cycle/persisted-goal-cycle';
import useViewGoalCycle from '@/composables/goal-cycle/view-goal-cycle';
import { computed } from 'vue';

export default function useAdaptiveGoalCycle(viewSvc, useMemoryGoalCycle = false) {
  const isCycleLockedOnView = computed(() => viewSvc.currentView.value.lockCycleOnView);

  const viewGoalCycleSvc = useViewGoalCycle(viewSvc);

  const persistedGoalCycleSvc = usePersistedGoalCycle();

  const inMemoryGoalCycle = useInMemoryGoalCycle();

  const goalCycleSvc = computed(() => {
    if (isCycleLockedOnView.value) {
      return viewGoalCycleSvc;
    }
    if (useMemoryGoalCycle) {
      return inMemoryGoalCycle;
    }
    return persistedGoalCycleSvc;
  });

  const lockCycleOnView = (value) => {
    viewSvc.updateLockCyclesOnView(viewSvc.currentView.value, value);
    viewGoalCycleSvc.changeSelectedGoalCycles(persistedGoalCycleSvc.selectedGoalCycles.value);
  };

  return {
    isCycleLockedOnView,
    goalCycleSvc,

    lockCycleOnView,
  };
}
