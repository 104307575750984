<template>
  <m-card
    class="spaces-context-menu"
    list
    no-padding
  >
    <m-card-item :clickable="false">
      <div class="_space">
        <m-avatar
          v-if="isEmptyIcon(icon)"
          :size="30"
          :text="space.title"
          :amount-chars="1"
          :color="getColor(space.color)"
          bordered
        />
        <m-icon-display
          v-else
          class="_icon"
          :size="24"
          :icon="icon"
        />
        <div class="_left">
          <div class="_title">
            <m-tooltip
              class="_text"
              placement="bottom"
              :disabled="!isTitleTruncated"
              :mouse-enter-delay=".5"
            >
              <template #title>
                {{ space.title }}
              </template>
              <item-title
                v-model:is-title-truncated="isTitleTruncated"
                :title="space.title"
              />
            </m-tooltip>
            <div class="_actions">
              <m-dropdown
                v-model:value="showMenu"
                :title="$t('general.actions')"
              >
                <m-btn
                  icon="ellipsis"
                  xs
                  fab
                  light
                  hide-border
                  @click.prevent="showMenu = true"
                />
                <template #overlay>
                  <space-nav-menu
                    :space="space"
                    :pin="pin"
                    @close="close"
                  />
                </template>
              </m-dropdown>
            </div>
          </div>
          <div class="_subtext">
            {{ $t("spaceTopBarMenu.members", { count: members.length }) }}
          </div>
        </div>
        <div class="_right">
          <div class="_joined">
            <m-tag
              v-if="joined"
              icon="check-mark"
              :title="$t('spaceStatus.joined')"
              rounded
            />
          </div>
        </div>
      </div>
    </m-card-item>
    <m-divider
      v-if="activeSubspaces.length > 0"
      xxs
    />
    <m-card-item
      v-for="subspace in activeSubspaces"
      :key="subspace.uid"
      class="_subspace"
      @click="openSubspace(subspace)"
    >
      <item-title
        :title="subspace.title"
        :icons="[{ value: buildIconFromEntity(subspace) }]"
      />
    </m-card-item>
  </m-card>
</template>

<script setup>
import ItemTitle from '@/components/ItemTitle.vue';
import SpaceNavMenu from '@/components/spaces/SpaceNavMenu.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePins from '@/composables/pin/pin';
import useSpace from '@/composables/space/space';
import useViewNavigator from '@/composables/saved-views/navigator';
import { GOAL_VIEW } from '@/route-params';
import { buildIconFromEntity, isEmptyIcon } from 'shared/lib/icon';
import { computed, ref, toRef } from 'vue';
import { findInArray } from 'shared/lib/array/array';
import { getColor } from 'shared/lib/color-map';
import { pinType, routeName } from 'shared/constants.json';
import { useRouter } from 'vue-router';

const props = defineProps({
  space: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['close']);

const space = toRef(props, 'space');
const spaceSvc = useSpace(space);
const { icon, members, activeSubspaces } = spaceSvc;
const { loggedInUser } = useLoggedInUser();
const joined = computed(() => findInArray({ haystack: members.value.map(({ user }) => user), needle: loggedInUser.value.uid }) !== null);

const isTitleTruncated = ref(false);

const { pins } = usePins();
const pin = computed(() => {
  if (joined.value && space.value.archivedAt === null) {
    return {
      removable: false,
      space: space.value,
    };
  }
  const p = pins.value.find((p) => p.type === pinType.space && p.space.uid === space.value.uid);
  if (p === undefined) {
    return null;
  }
  return {
    uid: p.uid,
    id: p.uid,
    removable: true,
    space: space.value,
  };
});

const showMenu = ref(false);
const close = () => {
  showMenu.value = false;
  emit('close');
};

const { linkToRoute } = useViewNavigator();
const router = useRouter();
const openSubspace = (subspace) => {
  const link = linkToRoute(undefined, routeName.spaceDetails, GOAL_VIEW, subspace.uid);
  router.push(link.to);
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
.spaces-context-menu {
  ._space {
    display: flex;
    align-items: center;
    gap: 1rem;

    ._left {
      display: flex;
      flex-direction: column;

      ._title {
        display: flex;
        gap: .4rem;

        ._text {
          font-weight: $font-weight-bold;
          max-width: 20rem;
        }
      }

      ._subtext {
        color: $font-color-secondary;
      }
    }

    ._right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
    }
  }
}
</style>
