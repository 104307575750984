import useCleanFilter from '@/composables/saved-views/clean-filter';
import useProperties from '@/composables/property/property';
import useSpaces from '@/composables/space/spaces';
import useUsers from '@/composables/user/users';
import { encodeCachedPropertyUid } from '@/lib/props/cached-property';
import { goal as goalConfig } from 'shared/api/query/configs.json';

export default function useParamCleaner() {
  const propertySvc = useProperties();
  const userSvc = useUsers();
  const spaceSvc = useSpaces();
  const cleanFilterSvc = useCleanFilter(propertySvc, userSvc, spaceSvc);

  const clearProps = (params, defaultProps) => {
    const filtered = params.props.reduce((res, prop) => {
      const f = defaultProps.find((p) => p.key === prop.key);
      if (f === undefined) {
        return res;
      }

      // For direct properties that are hidden on render, prefer value from code than prop.show from DB
      let show = f.isDirect && f.hideInProps ? f.show : prop.show;
      if (f.disableInProps) {
        show = true;
      }

      return [...res, {
        ...prop,
        show,
        isDirect: f.isDirect,
        hideInProps: f.hideInProps,
        disableInProps: f.disableInProps,
        noSortInProps: f.noSortInProps,
        label: f.label,
        property: f.property,
      }];
    }, []);

    const newProps = defaultProps.filter((p) => !params.props.map((vp) => vp.key).includes(p.key));
    return {
      ...params,
      props: [...filtered, ...newProps],
    };
  };

  const setDefaultWidth = (params, defaultProps) => {
    params.props.map((p) => {
      if (typeof p.width !== 'undefined') {
        return p;
      }

      const filtered = defaultProps.filter((prop) => prop.key === p.key);
      if (filtered.length === 0) {
        throw new Error('prop not found');
      }

      p.width = filtered[0].width;
      return p;
    });

    return params;
  };

  const clearFilter = (params, defaultProps) => {
    const filter = cleanFilterSvc.cleanFilter({ filter: params.filter, props: defaultProps });
    return {
      ...params,
      filter,
    };
  };

  const clearOrder = (params, props) => {
    const validAttributes = props.reduce((res, next) => {
      if (next.isDirect) {
        res.edges.push(next.property.edgeName);
        return res;
      }
      res.propIds.push(encodeCachedPropertyUid(next.property.uid));
      return res;
    }, { propIds: [], edges: [] });

    return {
      ...params,
      order: params.order
        .filter((o) => {
          if (o.langs !== undefined && o.langs.length === 1) {
            return validAttributes.propIds.includes(o.langs[0]);
          }
          if (o.attr === goalConfig.edges.cachedGoalCycle) {
            return true;
          }
          return validAttributes.edges.includes(o.attr);
        }),
    };
  };

  const clearGrouping = (params) => {
    // TODO: https://www.notion.so/mooncamp/refactor-Network-View-group-by-status-02cc16caa51b4f4db4a9aff254918386?pvs=4
    if (params.grouping === undefined) {
      return {
        ...params,
        grouping: 'status',
      };
    }
    return params;
  };

  return { clearProps, setDefaultWidth, clearFilter, clearOrder, clearGrouping };
}
