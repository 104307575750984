<template>
  <m-content
    padding-x="layout"
    class="goal-insights-health"
  >
    <m-alert
      v-if="showInfobox && !isPrint"
      v-model:closed="infoBoxClosed"
      class="_infobox"
      type="info"
      closeable
    >
      <div class="_text">
        {{ $t('goalInsightsHealth.infobox.text') }}
      </div>
      <m-btn
        class="_button"
        :href="$t('goalInsightsHealth.infobox.helpCenterLink')"
        target="_blank"
      >
        {{ $t('goalInsightsHealth.infobox.learnMore') }}
        <m-icon
          class="_icon"
          type="external-link"
          :style="{ marginLeft: '.8rem' }"
        />
      </m-btn>
      <m-btn
        class="_button"
        color="primary"
        :to="{ name: routeName.goalTypes }"
      >
        {{ $t('goalInsightsHealth.goToSettings') }}
      </m-btn>
    </m-alert>
    <div
      class="_header"
    >
      <goals-list-header
        :slots="slots"
        :filter-props="defaultProps"
        :allowed-view-types="allowedViewTypes"
        :show-create-view="showCreateView"
        :show-views-selector="showViewsSelector"
        :change-selected-goal-cycles="changeSelectedGoalCycles"
        :lock-cycle-on-view="lockCycleOnView"
        :selected-goal-cycles="selectedCycles"
        :is-cycle-locked-on-view="isCycleLockedOnView"
        show-saved-view-info
        class="_list-header"
      />
      <m-divider none />
    </div>
    <dashboard-group
      class="_overview"
      :title="$t('goalInsightsHealth.groups.overview')"
    >
      <div class="_container -overview">
        <overview-parent-type-card
          class="_metric-card"
          :goal-types="goalTypeProperty.options"
          :selected-cycles="selectedCycles"
          :view-filter="currentFilter"
          :goal-ids-to-observe="goalIdsToObserve"
          @show-goal-list="handleShowGoalList"
        />
        <overview-properties-required-card
          class="_metric-card"
          :goal-types="goalTypeProperty.options"
          :selected-cycles="selectedCycles"
          :view-filter="currentFilter"
          :goal-ids-to-observe="goalIdsToObserve"
          @show-goal-list="handleShowGoalList"
        />
        <overview-measurement-types-card
          class="_metric-card"
          :goal-types="goalTypeProperty.options"
          :selected-cycles="selectedCycles"
          :view-filter="currentFilter"
          :goal-ids-to-observe="goalIdsToObserve"
          @show-goal-list="handleShowGoalList"
        />
      </div>
    </dashboard-group>
    <dashboard-group
      class="_by_goal_type"
      :title="$t('goalInsightsHealth.groups.byGoalType')"
    >
      <dashboard-group
        v-for="goalType in goalTypeProperty.options"
        :key="goalType.uid"
        v-model:expanded="goalTypeExpanded[goalType.uid]"
        expandable
      >
        <template #title>
          <m-tag
            small
            :title="textByLang(goalType.label, userLang)"
            :icon="buildIconFromEntity(goalType)"
            :color="goalType.color"
            automatic-color-fallback
          />
        </template>
        <div
          class="_container"
        >
          <parent-type-card
            v-show="showingCards[`parent-type-card_${goalType.uid}`]"
            :key="`parent-type-card_${goalType.uid}`"
            class="_single-card"
            :goal-type="goalType"
            :selected-cycles="selectedCycles"
            :view-filter="currentFilter"
            @show-goal-list="handleShowGoalList"
            @show="showingCards[`parent-type-card_${goalType.uid}`] = $event"
          />
          <properties-required-card
            v-show="showingCards[`properties-required-card_${goalType.uid}`]"
            :key="`properties-required-card_${goalType.uid}`"
            class="_single-card"
            :goal-type="goalType"
            :selected-cycles="selectedCycles"
            :view-filter="currentFilter"
            @show-goal-list="handleShowGoalList"
            @show="showingCards[`properties-required-card_${goalType.uid}`] = $event"
          />
          <measurement-types-card
            class="_single-card"
            :goal-type="goalType"
            :selected-cycles="selectedCycles"
            :view-filter="currentFilter"
            @show-goal-list="handleShowGoalList"
          />
          <automated-card
            v-show="showingCards[`automated-card_${goalType.uid}`]"
            :key="`automated-card_${goalType.uid}`"
            class="_single-card"
            :goal-type="goalType"
            :selected-cycles="selectedCycles"
            :view-filter="currentFilter"
            @show-goal-list="handleShowGoalList"
            @show="showingCards[`automated-card_${goalType.uid}`] = $event"
          />
        </div>
      </dashboard-group>
    </dashboard-group>
    <dashboard-group
      class="_breakdown"
      :title="$t('goalInsightsHealth.groups.breakdown')"
    >
      <health-table-card
        :goal-types="goalTypeProperty.options"
        :selected-cycles="selectedCycles"
        :view-filter="currentFilter"
        :group-by-property="groupByProperty"
        @show-goal-list="handleShowGoalList"
      />
    </dashboard-group>
    <m-dialog
      v-model:value="showGoalList"
      :max-width="maxDialogWidth"
      max-height="calc(100vh - 20rem)"
      hide-footer
      no-padding
      keep-height
      top="7rem"
      :title="goalListTitle"
    >
      <temp-goal-list
        :id="tempGoalListId"
        :filter="goalListFilter"
        inline-editable
        show-side-menu
        show-right-click-menu
      />
    </m-dialog>
  </m-content>
</template>

<script>
import AutomatedCard from '@/components/goal-insights/health/AutomatedCard.vue';
import DashboardGroup from '@/components/dashboard/DashboardGroup.vue';
import GoalsListHeader from '@/components/goal/GoalsListHeader.vue';
import HealthTableCard from '@/components/goal-insights/health/HealthTableCard.vue';
import MeasurementTypesCard from '@/components/goal-insights/health/MeasurementTypesCard.vue';
import OverviewMeasurementTypesCard from '@/components/goal-insights/health/OverviewMeasurmentTypesCard.vue';
import OverviewParentTypeCard from '@/components/goal-insights/health/OverviewParentTypeCard.vue';
import OverviewPropertiesRequiredCard from '@/components/goal-insights/health/OverviewPropertiesRequiredCard.vue';
import ParentTypeCard from '@/components/goal-insights/health/ParentTypeCard.vue';
import PropertiesRequiredCard from '@/components/goal-insights/health/PropertiesRequiredCard.vue';
import TempGoalList from '@/components/TempGoalList.vue';
import useAccess from '@/composables/access/access';
import useAdaptiveGoalCycle from '@/composables/goal-cycle/adaptive-goal-cycle';
import useDebounce from '@/composables/debounce';
import useExport from '@/composables/export/export';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useProperties from '@/composables/property/property';
import useRouteAwareViews from '@/composables/saved-views/route-aware-views';
import { AND } from 'shared/api/query/filter';
import { EventBus } from '@/lib/event-bus';
import { GoalFilterHandler } from '@/lib/filter/goal/handler';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { applyBaseFilter } from '@/lib/filter/base-filter';
import { buildIconFromEntity } from 'shared/lib/icon';
import { computed, provide } from 'vue';
import { createPropsList } from '@/lib/props';
import { directProperties } from '@/lib/goal/properties';
import { dogma } from '@/api';
import { featureFlag, routeName, viewType } from 'shared/constants.json';
import { getColor } from 'shared/lib/color-map';
import { queryGoalsCount } from '@/api/query/goal-insights-health';
import { savedViewProps } from '@/lib/saved-view/props';
import { showFeatureGate } from '@/lib/feature-gate';
import { textByLang } from 'shared/lib/language';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'GoalInsightsHealth',
  props: {
    ...savedViewProps,
    showViewsSelector: {
      type: Boolean,
      default: false,
    },
    showCreateView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { isPrint } = useExport();
    const { goalCycles } = useGoalCycle();
    const store = useStore();
    const { spaceProperty } = useProperties();
    const { properties: goalProperties } = useGoalProperty();
    const userLang = computed(() => store.getters.userLang);

    const { accountHasFeature } = useAccess();

    const defaultProps = computed(() => createPropsList({
      properties: goalProperties.value,
      directProperties: directProperties((key) => t(key), goalCycles.value),
      userLang: userLang.value,
    }));

    const routeAwareViewsSvc = useRouteAwareViews({
      defaultProps,
      defaultView: props.defaultView,
      application: props.viewApplication,
      readOnly: props.readOnly,
    });

    const { userHasRight } = useAccess();
    provide(VIEWS_SERVICE, routeAwareViewsSvc);

    const { debounce } = useDebounce();
    const { goalTypeProperty } = useGoalTypeProperty();

    const { isCycleLockedOnView, goalCycleSvc, lockCycleOnView } = useAdaptiveGoalCycle(routeAwareViewsSvc);

    return {
      debounce,

      isPrint,

      accountHasFeature,

      defaultProps,
      userLang,
      goalTypeProperty,

      userHasRight,
      currentView: routeAwareViewsSvc.currentView,
      initCurrentView: routeAwareViewsSvc.initCurrentView,

      isCycleLockedOnView,
      lockCycleOnView,
      goalCycleSvc,

      viewService: routeAwareViewsSvc,

      groupByProperty: spaceProperty,
    };
  },
  components: {
    HealthTableCard,
    OverviewMeasurementTypesCard,
    OverviewPropertiesRequiredCard,
    OverviewParentTypeCard,
    PropertiesRequiredCard,
    ParentTypeCard,
    AutomatedCard,
    MeasurementTypesCard,
    DashboardGroup,
    TempGoalList,
    GoalsListHeader,
  },
  data() {
    const showingCards = {};
    ['parent-type-card', 'properties-required-card', 'automated-card'].forEach((cardType) => {
      this.goalTypeProperty.options.forEach((goalType) => {
        const key = `${cardType}_${goalType.uid}`;
        showingCards[key] = false;
      });
    });
    return {
      getColor,
      textByLang,
      goalListFilter: null,
      showGoalList: false,
      goalListTitle: '',
      slots: [{ name: SLOTS.CYCLE_SELECTOR }, { name: SLOTS.FILTER }],
      infoBoxClosed: false,
      goalTypeExpanded: {},
      goalIdsToObserve: [],
      showingCards,
      routeName,
    };
  },
  computed: {
    tempGoalListId() {
      if (this.space !== null) {
        return `${this.space.uid} ${this.goalListTitle}`;
      }
      return this.goalListTitle;
    },
    allowedViewTypes() {
      return [viewType.default];
    },
    maxDialogWidth() {
      if (window.innerWidth > 1400) {
        return '1400px';
      }

      return 'calc(100vw - 15rem)';
    },
    currentFilter() {
      return this.currentView.params.filter;
    },
    gqlFilterObject() {
      const handler = new GoalFilterHandler();
      return handler.Translate(this.currentFilter);
    },
    gqlFilter() {
      return this.gqlFilterObject.filterTree;
    },
    showInfobox() {
      return !this.infoBoxClosed;
    },
    selectedCycles() {
      return this.goalCycleSvc.composedSelectedCycles.value;
    },
    changeSelectedGoalCycles() {
      return this.goalCycleSvc.changeSelectedGoalCycles;
    },
  },
  methods: {
    buildIconFromEntity,
    handleShowGoalList({ filter, title }) {
      this.goalListFilter = filter;
      this.goalListTitle = title;
      this.showGoalList = true;
    },
    initGoalTypeExpanded() {
      this.goalTypeProperty.options.forEach((goalType) => {
        this.goalTypeExpanded[goalType.uid] = this.isPrint;
      });
    },
    refreshGoalTypeExpanded() {
      dogma.query(applyBaseFilter({
        queries: [
          ...queryGoalsCount({
            goalCycles: this.selectedCycles,
            filter: this.gqlFilter,
            varBlocks: this.gqlFilterObject.varBlocks,
            goalTypeOptions: this.goalTypeProperty.options,
          }),
        ],
        operator: AND,
        baseFilter: this.publishedAtFilter,
      })).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.goalIdsToObserve = response.data.goalsTotalIds.map((e) => e.uid);
        if (this.isPrint) {
          return;
        }
        this.goalTypeProperty.options.forEach((goalType) => {
          const goalTypeCount = response.data[`goalsByTypeCount${goalType.uid}`];
          this.goalTypeExpanded[goalType.uid] = goalTypeCount !== undefined ? goalTypeCount[0].count > 0 : true;
        });
      });
    },
    fetch(wait) {
      this.debounce(this.refreshGoalTypeExpanded, wait, 'refreshGoalTypeExpanded');
    },
    redirectToOverview() {
      this.$router.replace('/dashboards/overview');
    },
  },
  watch: {
    selectedCycles(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return;
      }
      this.fetch(1000);
    },
    gqlFilterObject: {
      handler(val, oldVal) {
        if (JSON.stringify(val) === JSON.stringify(oldVal)) {
          return;
        }
        this.fetch(1000);
      },
      deep: true,
    },
  },
  created() {
    EventBus.$on('close-feature-gate', this.redirectToOverview);
    this.initGoalTypeExpanded();
    this.fetch(0);
    this.initCurrentView();
    if (!this.accountHasFeature([featureFlag.healthDashboard])) {
      showFeatureGate(featureFlag.healthDashboard);
    }
  },
  beforeUnmount() {
    EventBus.$off('close-feature-gate', this.redirectToOverview);
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  @import 'shared/assets/scss/_padding.scss';

  .goal-insights-health {
    ._header {
      position: sticky;
      left: 0;
      top: 0;
      background-color: white;
      z-index: 2;

      ._list-header {
        flex: 1 1 auto;
        padding-bottom: .6rem;
      }
    }

    ._overview {
      padding-top: 2rem;
    }

    ._infobox {
      display: flex;
      align-items: center;

      ._text {
        padding-right: 1.2rem;
        margin-right: auto;
        white-space: pre-wrap;
      }

      ._button {
        margin-left: 1.2rem;
      }
    }

    ._container {
      display: grid;
      grid-template-columns: repeat(2, minmax(30rem, 1fr));
      grid-gap: 2.4rem;

      @media (max-width: $screen-size-lg) {
        grid-template-columns: repeat(1, minmax(30rem, 1fr));
      }

      @media (min-width: $screen-size-xl) {
        grid-template-columns: repeat(4, minmax(30rem, 1fr));
      }

      &.-overview {
        grid-template-columns: repeat(3, minmax(15rem, 1fr));

        @media (max-width: $screen-size-lg) {
          grid-template-columns: 1fr;
        }
      }

      ._single-card {
        grid-column: span 1;
      }
    }
  }
</style>
