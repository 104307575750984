import useResourceSettings from '@/composables/logged-in-user-account/resource-settings';
import useSpaces from '@/composables/space/spaces';
import { MANUAL_SORTING } from '@/lib/constants';
import { getValueFromEntity, sortedPropertyElements } from '@/lib/props';
import { propertyType } from 'shared/constants.json';
import { shallowCopy } from 'shared/lib/copy';
import { sortAlphaNumeric, sortByArray, sortByAttr, sortChain, sortDatetime, sortReverseMdlwr } from 'shared/lib/sort';

export default function useSort(attributeMap, properties, propertyValueKey, userSvc) {
  const { resourceSettings } = useResourceSettings();
  const { spaces } = useSpaces();

  const propertiesMap = properties.value.reduce((res, next) => {
    res[`${next.uid}`] = next;
    return res;
  }, {});

  const sortByProperty = (propertyId) => {
    const prop = propertiesMap[propertyId];
    if (prop === undefined) {
      return () => 0;
    }
    switch (prop.type) {
      case propertyType.singleSelect:
      case propertyType.options:
      case propertyType.status: {
        const order = prop.options.sort(sortByArray(prop.propertyOptionOrder));
        const s = sortByArray(order);

        return (a, b) => {
          const aSelectedOptions = sortedPropertyElements(getValueFromEntity({ property: prop }, a, propertyValueKey), prop.propertyOptionOrder.map(({ uid }) => uid));
          const bSelectedOptions = sortedPropertyElements(getValueFromEntity({ property: prop }, b, propertyValueKey), prop.propertyOptionOrder.map(({ uid }) => uid));
          for (let i = 0; i < Math.max(aSelectedOptions.length, bSelectedOptions.length); i++) {
            if (aSelectedOptions[i] === undefined && bSelectedOptions[i] === undefined) {
              return 0;
            }
            if (aSelectedOptions[i] === undefined) {
              return -1;
            }
            if (bSelectedOptions[i] === undefined) {
              return 1;
            }
            const r = s(aSelectedOptions[i], bSelectedOptions[i]);
            if (r === 0) {
              continue;
            }

            return r;
          }

          return 0;
        };
      }
      case propertyType.space: {
        const order = spaces.value.sort(sortByArray(resourceSettings.value.spaceOrder));
        const s = sortByArray(order);
        return (a, b) => {
          const aSelectedSpaces = sortedPropertyElements(getValueFromEntity({ property: prop }, a, propertyValueKey), resourceSettings.value.spaceOrder.map(({ uid }) => uid));
          const bSelectedSpaces = sortedPropertyElements(getValueFromEntity({ property: prop }, b, propertyValueKey), resourceSettings.value.spaceOrder.map(({ uid }) => uid));
          for (let i = 0; i < Math.max(aSelectedSpaces.length, bSelectedSpaces.length); i++) {
            if (aSelectedSpaces[i] === undefined && bSelectedSpaces[i] === undefined) {
              return 0;
            }
            if (aSelectedSpaces[i] === undefined) {
              return -1;
            }
            if (bSelectedSpaces[i] === undefined) {
              return 1;
            }
            const r = s(aSelectedSpaces[i], bSelectedSpaces[i]);
            if (r === 0) {
              continue;
            }

            return r;
          }

          return 0;
        };
      }
      case propertyType.user: {
        return (a, b) => {
          const aElements = shallowCopy(userSvc.selectMultiple(getValueFromEntity({ property: prop }, a, propertyValueKey).map((u) => u.uid)));
          const aSelectedUsers = aElements.sort((a, b) => sortAlphaNumeric(`${a.firstName} ${b.lastName}`, `${b.firstName} ${b.lastName}`));
          const bElements = shallowCopy(userSvc.selectMultiple(getValueFromEntity({ property: prop }, b, propertyValueKey).map((u) => u.uid)));
          const bSelectedUsers = bElements.sort((a, b) => sortAlphaNumeric(`${a.firstName} ${b.lastName}`, `${b.firstName} ${b.lastName}`));
          for (let i = 0; i < Math.max(aSelectedUsers.length, bSelectedUsers.length); i++) {
            if (aSelectedUsers[i] === undefined && bSelectedUsers[i] === undefined) {
              return 0;
            }
            if (aSelectedUsers[i] === undefined) {
              return -1;
            }
            if (bSelectedUsers[i] === undefined) {
              return 1;
            }
            const res = sortAlphaNumeric(`${aSelectedUsers[i].firstName} ${aSelectedUsers[i].lastName}`, `${bSelectedUsers[i].firstName} ${bSelectedUsers[i].lastName}`);
            if (res !== 0) {
              return res;
            }
          }

          return 0;
        };
      }
      case propertyType.date: {
        return (a, b) => {
          const aElement = shallowCopy(getValueFromEntity({ property: prop }, a, propertyValueKey));
          const bElement = shallowCopy(getValueFromEntity({ property: prop }, b, propertyValueKey));
          return sortDatetime(aElement, bElement);
        };
      }
      case propertyType.text:
      case propertyType.url:
      case propertyType.number:
      default: {
        return (a, b) => {
          const aVal = getValueFromEntity({ property: prop }, a, propertyValueKey);
          const bVal = getValueFromEntity({ property: prop }, b, propertyValueKey);
          return sortAlphaNumeric(aVal, bVal);
        };
      }
    }
  };

  const getType = (attr) => {
    if (attributeMap === undefined || attributeMap[attr] === undefined) {
      return undefined;
    }
    return attributeMap[attr].type;
  };
  const getIgnored = (attr) => {
    if (attributeMap === undefined || attributeMap[attr] === undefined || attributeMap[attr].ignored === undefined) {
      return false;
    }
    return attributeMap[attr].ignored;
  };

  const getSorter = (orderItem) => {
    const { attr } = orderItem;

    if (attr === MANUAL_SORTING) {
      return sortByArray(orderItem.sorting);
    }

    switch (getType(attr)) {
      case 'cachedProperty':
        return sortByProperty(orderItem.langs[0].substring(1));
      case 'date':
        return sortByAttr(attr, sortDatetime);
      default:
        return sortByAttr(attr, sortAlphaNumeric);
    }
  };

  const sort = (list, order) => {
    const filteredOrder = order.filter((o) => !getIgnored(o.attr));
    if (filteredOrder.length === 0) {
      return list;
    }

    list.sort(sortChain(filteredOrder.map((o) => sortReverseMdlwr(o.desc)(getSorter(o)))));

    return list;
  };

  return { sort };
}
