import useLocalOverwriteCycles from '@/composables/saved-views/local-overwrite-cycles';
import useLocalOverwriteLockCycleOnView from '@/composables/saved-views/local-overwrite-lock-cycle-on-view';
import useLocalOverwritesParams from '@/composables/saved-views/local-overwrite-params';
import useLocalOverwritesSort from '@/composables/saved-views/local-overwrite-sort';
import { computed } from 'vue';
import { findInArray } from 'shared/lib/array/array';

export default function useViewsRepoLocal(repo, loggedInUserAccount) {
  const localOverwritesParamsSvc = useLocalOverwritesParams(localStorage, loggedInUserAccount);
  const localOverwritesSortSvc = useLocalOverwritesSort(localStorage, loggedInUserAccount);
  const localOverwritesCyclesSvc = useLocalOverwriteCycles(localStorage, loggedInUserAccount);
  const localOverwritesLockCycleOnViewSvc = useLocalOverwriteLockCycleOnView(localStorage, loggedInUserAccount);

  localOverwritesSortSvc.initOverwrites();
  localOverwritesParamsSvc.initOverwrites();
  localOverwritesCyclesSvc.initOverwrites();
  localOverwritesLockCycleOnViewSvc.initOverwrites();

  const views = computed(() => repo.views.value.map(localOverwritesParamsSvc.mergeWithLocalOverwrites).map(localOverwritesCyclesSvc.mergeWithLocalOverwrites).map(localOverwritesLockCycleOnViewSvc.mergeWithLocalOverwrites).map((view) => {
    if (view.isTemp) {
      return localOverwritesSortSvc.mergeWithLocalOverwrites(view);
    }

    return view;
  }));

  const resetView = (id) => {
    localOverwritesParamsSvc.removeLocalOverwrite(views.value.find((v) => v.uid === id));
    localOverwritesCyclesSvc.removeLocalOverwrite(views.value.find((v) => v.uid === id));
    localOverwritesLockCycleOnViewSvc.removeLocalOverwrite(views.value.find((v) => v.uid === id));
    return views.value.find((v) => v.uid === id);
  };

  const updateView = (view) => repo.updateView(view).then((view) => {
    localOverwritesParamsSvc.removeLocalOverwrite(view);
    localOverwritesCyclesSvc.removeLocalOverwrite(view);
    localOverwritesLockCycleOnViewSvc.removeLocalOverwrite(view);
    return view;
  });

  const setParams = (view, params) => {
    localOverwritesParamsSvc.saveOverwrite({ ...view, params });
  };

  const updateSort = (view, sortOperation) => {
    if (view.isTemp) {
      localOverwritesSortSvc.saveOverwrite(view);
      return new Promise((resolve) => { resolve(view); });
    }

    return repo.updateView({ uid: view.uid, goalSort: view.goalSort }, {}, { sortOperation });
  };

  const updateCycles = (view, goalCycles) => {
    localOverwritesCyclesSvc.saveOverwrite({
      ...view,
      goalCycles,
    });
  };

  const updateLockCyclesOnView = (view, lockCycleOnView) => {
    localOverwritesLockCycleOnViewSvc.saveOverwrite({ ...view, lockCycleOnView });
  };

  const hasUnsavedChanges = (view) => {
    const f = findInArray({ haystack: repo.views.value, needle: view.uid });
    if (f === null) {
      return false;
    }

    return localOverwritesParamsSvc.hasLocalOverwrite(f) || localOverwritesCyclesSvc.hasLocalOverwrite(f) || localOverwritesLockCycleOnViewSvc.hasLocalOverwrite(f);
  };

  return {
    createLoading: repo.createLoading,
    createViews: repo.createViews,

    loadingUpdateMetaData: repo.loadingUpdateMetaData,
    updateMetaData: repo.updateMetaData,

    loadingUpdateView: repo.loadingUpdateView,
    updateView,

    loadingDeleteView: repo.loadingDeleteView,
    deleteView: repo.deleteView,

    setParams,
    updateSort,
    updateCycles,
    updateLockCyclesOnView,

    resetView,

    hasUnsavedChanges,

    views,
  };
}
