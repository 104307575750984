import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import usePrintable from '@/composables/printable/printable';
import useSnackBar from '@/composables/snackbar';
import { DEFAULT_SIDEBAR_WIDTH } from '@/lib/constants';
import { computed, ref } from 'vue';
import { logCatch } from '@/lib/logger/logger';
import { printableStatus } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

export default function useExport() {
  const { loggedInUser } = useLoggedInUser();
  const { personalAppSettings } = usePersonalAppSettings(loggedInUser);
  const snackBar = useSnackBar();

  const { t } = useI18n();

  const route = useRoute();
  const isPrint = computed(() => {
    if (route === undefined) {
      return false;
    }
    return route.query.print !== undefined;
  });

  const exportWidth = () => {
    if (personalAppSettings.value.desktopSidebarHidden) {
      return document.body.clientWidth;
    }
    return document.body.clientWidth - DEFAULT_SIDEBAR_WIDTH;
  };

  async function downloadFile(url, fileName) {
    const response = await fetch(url);
    if (!response.ok) throw new Error('Network response was not ok');

    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(link.href);
  }

  const printableSvc = usePrintable();
  const pdfExportLoading = ref(false);
  const saveAsPDF = (printable) => {
    pdfExportLoading.value = true;
    return printableSvc.createSingle({ ...printable, localStorage: JSON.stringify(localStorage), creator: { uid: loggedInUser.value.uid }, width: exportWidth() }).then(async (data) => {
      if (data.status !== printableStatus.done) {
        snackBar.error(t('error.pdfExport'));
        return;
      }
      await downloadFile(data.storageObject.getURL, data.storageObject.fileName);
      pdfExportLoading.value = false;
    }).catch(logCatch(() => {
      pdfExportLoading.value = false;
      snackBar.error(t('error.pdfExport'));
    }));
  };
  return { isPrint, saveAsPDF, pdfExportLoading };
}
