import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import { camelCase } from 'lodash-es';
import { computed } from 'vue';
import { dataSourceIcon } from '@/lib/data-source';
import { dataSourceType, goalProgressMeasurement } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

export default function useGoalDatasource(goal) {
  const { accountSettings } = useAccountSettings();
  const i18n = useI18n();

  const query = computed(() => {
    if (![goalProgressMeasurement.continuous, goalProgressMeasurement.threshold].includes(goal.value.progressMeasurement)) {
      return null;
    }

    if ((accountSettings.value.usesSheets || accountSettings.value.usesExcel) && goal.value.spreadsheetCell !== null) {
      return goal.value.spreadsheetCell;
    }
    if (accountSettings.value.usesJira && goal.value.jiraQuery !== null) {
      return goal.value.jiraQuery;
    }
    if (accountSettings.value.usesHubspot && goal.value.hubspotQuery !== null) {
      return goal.value.hubspotQuery;
    }
    if (accountSettings.value.usesAsana && goal.value.asanaQuery !== null) {
      return goal.value.asanaQuery;
    }
    if (accountSettings.value.usesSalesforce && goal.value.salesforceQuery !== null) {
      return goal.value.salesforceQuery;
    }
    if (accountSettings.value.usesMSPowerBI && goal.value.msPowerBiQuery !== null) {
      return goal.value.msPowerBiQuery;
    }
    if (accountSettings.value.usesMSPlanner && goal.value.msPlannerQuery !== null) {
      return goal.value.msPlannerQuery;
    }
    return null;
  });

  const dataSource = computed(() => {
    if (query.value !== null) {
      return query.value.dataSource;
    }
    return null;
  });

  const type = computed(() => {
    if (![goalProgressMeasurement.continuous, goalProgressMeasurement.threshold].includes(goal.value.progressMeasurement)) {
      return null;
    }

    if (goal.value.spreadsheetCell !== null) {
      if (goal.value.spreadsheetCell.dataSource !== null) {
        return goal.value.spreadsheetCell.dataSource.type;
      }
      return dataSourceType.sheets;
    }
    if (goal.value.jiraQuery !== null) {
      return dataSourceType.jira;
    }
    if (goal.value.hubspotQuery !== null) {
      return dataSourceType.hubspot;
    }
    if (goal.value.asanaQuery !== null) {
      return dataSourceType.asana;
    }
    if (goal.value.salesforceQuery !== null) {
      return dataSourceType.salesforce;
    }
    if (goal.value.msPowerBiQuery !== null) {
      return dataSourceType.msPowerBi;
    }
    if (goal.value.msPlannerQuery !== null) {
      return dataSourceType.msPlanner;
    }

    return null;
  });

  const title = computed(() => {
    if (type.value !== null) {
      return i18n.t(`dataSources.${camelCase(type.value)}`);
    }

    return '';
  });

  const icon = computed(() => {
    if (type.value !== null) {
      return dataSourceIcon({ type: type.value });
    }

    return '';
  });

  return {
    query,
    dataSource,
    type,
    title,
    icon,
  };
}
