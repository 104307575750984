import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useRepo from '@/nebula/repo';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { computed, ref } from 'vue';
import { dataSourceAuthenticationType, dataSourceType } from 'shared/constants.json';
import { dataSource as dataSourceConfig } from 'shared/api/query/configs.json';
import { dataSourceQuery } from '@/api/query/nebula/data-source';
import { useI18n } from 'vue-i18n';

export default function useDataSources() {
  const { t } = useI18n();
  const { loggedInUserAccount } = useLoggedInUserAccount();
  const { loggedInUser } = useLoggedInUser();
  const repo = useRepo(dataSourceConfig.model);

  const defaultAuthenticationTypes = {
    [dataSourceType.sheets]: dataSourceAuthenticationType.oauth,
    [dataSourceType.jira]: dataSourceAuthenticationType.apiToken,
    [dataSourceType.excel]: dataSourceAuthenticationType.oauth,
    [dataSourceType.hubspot]: dataSourceAuthenticationType.oauth,
    [dataSourceType.asana]: dataSourceAuthenticationType.oauth,
    [dataSourceType.salesforce]: dataSourceAuthenticationType.oauth,
    [dataSourceType.msPowerBi]: dataSourceAuthenticationType.oauth,
    [dataSourceType.msPlanner]: dataSourceAuthenticationType.oauth,
  };

  const dataSourceName = (dataSource) => {
    if (dataSource === null) {
      return t('dataSource.nonSelected');
    }
    if (dataSource.name === '') {
      return t('dataSource.untitled');
    }
    return dataSource.name;
  };

  const dataSourcesByType = (type) => computed(() => repo.entityList.value.filter((d) => d.type === type));

  const createDataSource = (dataSource) => repo.createSingle({
    ...dataSource,
    account: { uid: loggedInUserAccount.value.uid },
    creator: { uid: loggedInUser.value.uid },
    authenticationType: defaultAuthenticationTypes[dataSource.type],
  }).then((dataSource) => {
    EventBus.$emit(EVENTS.INTEGRATION.DATASOURCE_CREATED, { dataSource });
    return dataSource;
  });

  const deleteDataSource = (dataSource) => repo.deleteSingle(dataSource.uid).then(() => {
    EventBus.$emit(EVENTS.INTEGRATION.DATASOURCE_DELETED, { dataSource });
  });

  const fetchDataSourceLoading = ref(false);
  const fetchDataSource = (dataSource) => {
    fetchDataSourceLoading.value = true;
    const q = dataSourceQuery(dataSource);
    return repo.query(q).then((data) => {
      fetchDataSourceLoading.value = false;
      return data.result[0];
    });
  };

  return {
    dataSourceName,
    createDataSourceLoading: repo.createLoading,
    createDataSource,
    updateDataSourceLoading: repo.updateLoading,
    updateDataSource: repo.updateSingle,
    deleteDataSourceLoading: repo.deleteLoading,
    deleteDataSource,
    fetchDataSourceLoading,
    fetchDataSource,
    selectSingle: repo.selectSingle,

    dataSources: repo.entityList,
    dataSourcesByType,
  };
}
