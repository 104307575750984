import { CREATED_AT, DEFAULT_ATTRIBUTES, RESULT, TYPE, UID } from 'shared/api/query/constants';
import {
  goalActivity as goalActivityConfig,
  goal as goalConfig,
  property as propertyConfig,
  propertyOption as propertyOptionConfig,
  propertyValue as propertyValueConfig,
  update as updateConfig,
  user as userConfig,
} from 'shared/api/query/configs.json';
import { propertyValueEdges } from '@/api/query/nebula/property-value';
import { reverseEdge } from 'shared/api/query/edges';

export const goalActivityChildren = [
  ...DEFAULT_ATTRIBUTES,
  { attr: goalActivityConfig.edges.current, default: 0 },
  { attr: goalActivityConfig.edges.previousCurrent },
  { attr: goalActivityConfig.edges.customCreatedAt },
  { attr: goalActivityConfig.edges.message, default: null },
  {
    attr: goalActivityConfig.edges.properties,
    model: propertyValueConfig.model,
    children: [
      { attr: UID },
      {
        attr: propertyValueConfig.edges.selectedOptions,
        model: propertyOptionConfig.model,
        children: [
          { attr: UID },
        ],
      },
      {
        attr: propertyValueConfig.edges.property,
        model: propertyConfig.model,
        children: [{ attr: UID }],
      },
    ],
  },
  {
    attr: goalActivityConfig.edges.previousProperties,
    model: propertyValueConfig.model,
    children: [
      { attr: UID },
      {
        attr: propertyValueConfig.edges.selectedOptions,
        model: propertyOptionConfig.model,
        children: [
          { attr: UID },
        ],
      },
    ],
  },
  {
    attr: goalActivityConfig.edges.goal,
    model: goalConfig.model,
    children: [
      { attr: UID },
      { attr: goalConfig.edges.metric },
      { attr: goalConfig.edges.cachedCurrent },
      { attr: goalConfig.edges.title },
      { attr: goalConfig.edges.icon },
      { attr: goalConfig.edges.progressMeasurement },
      { attr: goalConfig.edges.start },
      { attr: goalConfig.edges.accessRight },
      { attr: goalConfig.edges.end },
      {
        attr: goalConfig.edges.properties,
        default: [],
        model: propertyValueConfig.model,
        children: propertyValueEdges,
      },
    ],
  },
];

const updatesVar = { name: 'updates_via_goal_activities', typ: 1 };
export const goalActivityList = ({
  pagination,
  filter = null,
  varBlocks = [],
  order = [],
  alias = RESULT,
}) => [
  {
    model: goalActivityConfig.model,
    alias,
    func: { name: TYPE, args: [{ value: goalActivityConfig.model }] },
    order,
    filter,
    pagination,
    default: [],
    children: [
      ...goalActivityChildren,
      {
        alias: 'updates',
        attr: reverseEdge(updateConfig.edges.goalActivities),
        model: updateConfig.model,
        children: [
          {
            attr: UID,
            var: updatesVar.name,
          },
        ],
      },
    ],
  },
  {
    alias: 'updates',
    needsVar: [updatesVar],
    func: {
      name: 'uid',
      needsVar: [updatesVar],
    },
    model: updateConfig.model,
    children: [
      { attr: UID },
      { attr: CREATED_AT },
      { attr: updateConfig.edges.generated, default: false },
      {
        attr: updateConfig.edges.creator,
        model: userConfig.model,
        children: [{ attr: UID }],
        default: null,
      },
    ],
  },
  ...varBlocks,
];
