<template>
  <div
    class="goal-list-item"
    :data-id="goal.uid"
  >
    <list-item
      :entity="goal"
      :props="props"
      :has-children="hasChildren"
      :selected="selected"
      :selected-secondary="selectedSecondary"
      :read-only="readOnly"
      :show-progress-diff="showProgressDiff"
      :progress-display-option="progressDisplayOption"
      :progress-course-diff="progressCourseDiff"
      @select="openInModal"
      @prop-clicked="openInModal"
      @contextmenu.prevent="$emit('selection-right-click', $event, goal)"
    />
    <m-dialog
      v-model:value="showUpdateProgressModal"
      hide-footer
      :center="$store.state.breakpoint.smAndDown"
      :fullscreen-on-mobile="false"
      :body-style="{ overflow: 'visible' }"
      :max-width="$modalSizes.lg"
    >
      <goal-update-editor
        :key="goal.uid"
        :goal="goal"
        :goal-children="[goal]"
        allow-goal-activity
        auto-add-goal-activity
        @created="showUpdateProgressModal = false"
      />
    </m-dialog>
  </div>
</template>

<script>
import GoalUpdateEditor from '@/components/goal/GoalUpdateEditor.vue';
import ListItem from '@/components/list/ListItem.vue';
import useGoals from '@/composables/goal/goals';
import useOpenPeekMode from '@/composables/goal/open-peek-mode';
import { toRef } from 'vue';

export default {
  name: 'GoalListItem',
  props: {
    entityId: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    selectedSecondary: {
      type: Boolean,
      default: false,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showProgressDiff: {
      type: Boolean,
      default: false,
    },
    progressCourseDiff: {
      type: Array,
      default: () => [],
    },
    progressDisplayOption: { type: String, default: undefined },
  },
  emits: ['goals-duplicated', 'create', 'selection-right-click', 'select-row'],
  setup(props) {
    const { selectMultiple, selectSingle } = useGoals();
    const peekModeSvc = useOpenPeekMode(toRef(props, 'readOnly'));
    return {
      selectMultiple,
      selectSingle,
      peekModeSvc,
    };
  },
  components: { ListItem, GoalUpdateEditor },
  data() {
    return {
      showUpdateProgressModal: false,
      childrenLoaded: false,
    };
  },
  computed: {
    goal() {
      return this.selectSingle(this.entityId);
    },
    hasChildren() {
      return this.goal.children.length > 0;
    },
    children() {
      const ids = this.goal.children.map((g) => g.uid);
      return this.selectMultiple(ids);
    },
  },
  methods: {
    openInModal() {
      this.peekModeSvc.openGoal({ goalId: this.goal.uid });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .goal-list-item {
    ._children {
      margin-left: 2rem;

      ._item {
        padding: .2rem 0;
      }
    }
  }
</style>
