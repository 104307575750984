import useRepo from '@/nebula/repo';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import { feedEntry as feedEntryConfig } from 'shared/api/query/configs.json';
import { feedEntryType } from 'shared/constants.json';
import { unreadCount as unreadCountQuery } from '@/api/query/nebula/feed-entry';

export default function useInbox() {
  const repo = useRepo(feedEntryConfig.model);

  const unreadNotifications = computed(() => repo.entityList.value
    .filter((e) => e.readAt === undefined && types.notifications.includes(e.type)));
  const unreadTasks = computed(() => repo.entityList.value
    .filter((e) => e.readAt === undefined && types.tasks.includes(e.type)));
  const unreadNotificationCount = computed(() => unreadNotifications.value.length);
  const unreadTaskCount = computed(() => unreadTasks.value.length);
  const unreadCount = computed(() => unreadNotificationCount.value + unreadTaskCount.value);

  const types = {
    tasks: [
      feedEntryType.survey,
      feedEntryType.updateNotification,
      feedEntryType.feedbackInquiry,
      feedEntryType.dataSourceUnhealthy,
      feedEntryType.dataSourceDead,
      feedEntryType.dataSourceQueryError,
    ],
    notifications: [
      feedEntryType.feedbackReceived,
      feedEntryType.feedbackDeclined,
      feedEntryType.goalComment,
      feedEntryType.goalUpdateMention,
      feedEntryType.goalPropertyValueReferenced,
      feedEntryType.goalUpdate,
      feedEntryType.goalUpdateComment,
      feedEntryType.updateComment,
      feedEntryType.updateMention,
    ],
  };

  const tasks = computed(() => repo.entityList.value.filter((fe) => types.tasks.includes(fe.type) && fe.actor !== undefined));

  const notifications = computed(() => repo.entityList.value.filter((fe) => types.notifications.includes(fe.type) && fe.actor !== undefined));

  const countUnread = () => repo.query(
    [unreadCountQuery],
  );

  const markAsRead = () => {
    const entities = repo.entityList.value
      .filter((fe) => fe.readAt === undefined)
      .map((e) => ({ uid: e.uid, readAt: DateTime.local().toISO() }));
    if (entities.length === 0) {
      return new Promise((resolve) => { resolve(); });
    }
    return repo.updateMultiple(entities);
  };

  const updateFeedbackAnswerFeedEntry = (feedbackAnswer) => {
    const entities = tasks.value.filter((task) => {
      if (task.type !== feedEntryType.updateNotification) {
        return false;
      }

      return task.feedbackAnswer !== null && task.feedbackAnswer.uid === feedbackAnswer.uid;
    }).map((e) => ({ uid: e.uid, status: feedbackAnswer.status }));
    repo.updateMultiple(entities, { commitToRemote: false });
  };

  return {
    types,
    countUnread,
    unreadCount,
    unreadTasks,
    unreadTaskCount,
    unreadNotifications,
    unreadNotificationCount,
    markAsRead,
    tasks,
    notifications,
    query: repo.query,
    updateFeedbackAnswerFeedEntry,
  };
}
