import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useMSPlannerDatasourceRepo from '@/composables/integrations/ms-planner/ms-planner-datasource-repo';
import useRepo from '@/nebula/repo';
import { customDatasourceProperty } from 'shared/constants.json';
import { msPlannerQuery as msPlannerQueryConfig } from 'shared/api/query/configs.json';

export default function useMSPlanner() {
  const queryRepo = useRepo(msPlannerQueryConfig.model);
  const datasourceRepo = useMSPlannerDatasourceRepo();

  const { loggedInUser } = useLoggedInUser();

  const createQuery = (entity) => queryRepo.createSingle({
    ...entity,
    creator: { uid: loggedInUser.value.uid },
  });

  const getPlanDetails = (datasource, plan) => datasourceRepo.getSingle({
    ...datasource,
    [customDatasourceProperty.msPlannerPlans]: [plan],
  });

  return {
    createQueryLoading: queryRepo.createLoading,
    createQuery,
    updateQueryLoading: queryRepo.updateLoading,
    updateQuery: (entity) => queryRepo.updateSingle(entity, { ignoreResponse: false }),
    deleteQueryLoading: queryRepo.deleteLoading,
    deleteQuery: queryRepo.deleteSingle,
    getPlanDetailsLoading: datasourceRepo.getSingleLoading,
    getPlanDetails,
  };
}
