import useLocalOverwrite from '@/composables/saved-views/local-overwrite';
import { savedView as savedViewConfig } from 'shared/api/query/configs.json';

const SAVED_VIEW_LOCAL_OVERWRITE_LOCK_CYCLE = 'saved_view_local_overwrite_cycle_lock';

export default function useLocalOverwriteLockCycleOnView(localStorage, loggedInUserAccount) {
  const localStorageKeyLockCycleOnView = `${loggedInUserAccount.value.uid}_${SAVED_VIEW_LOCAL_OVERWRITE_LOCK_CYCLE}`;

  const localOverwriteLockCycleOnView = useLocalOverwrite(savedViewConfig.edges.lockCycleOnView, localStorageKeyLockCycleOnView, localStorage);

  const hasLocalOverwrite = (originalView) => hasLocalOverwriteLockCycle(originalView);

  const hasLocalOverwriteLockCycle = (originalView) => {
    const localCycleLock = localOverwriteLockCycleOnView.findLocalOverwrite(originalView);
    if (localCycleLock === undefined) {
      return false;
    }
    return originalView.lockCycleOnView !== localCycleLock;
  };

  const saveOverwrite = (view) => {
    localOverwriteLockCycleOnView.saveOverwrite({ ...view, lockCycleOnView: view.lockCycleOnView });
  };

  const removeLocalOverwrite = (view) => {
    localOverwriteLockCycleOnView.removeLocalOverwrite(view);
  };

  const initOverwrites = () => {
    localOverwriteLockCycleOnView.initOverwrites();
  };

  const mergeWithLocalOverwrites = (view) => localOverwriteLockCycleOnView.mergeWithLocalOverwrites(view);

  return {
    saveOverwrite,
    removeLocalOverwrite,
    mergeWithLocalOverwrites,
    initOverwrites,

    hasLocalOverwrite,
  };
}
