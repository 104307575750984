<template>
  <div class="asana-form">
    <m-content
      v-if="!documentsLoading && hasError"
      :padding-top="0"
      :padding-x="11"
      :padding-bottom="2"
    >
      <data-source-query-error
        :data-source="dataSource"
        :error="error"
      />
    </m-content>
    <template v-if="!hasConnectionError">
      <m-content
        :padding-top="0"
        :padding-x="11"
        :padding-bottom="2"
      >
        <div class="_item">
          <div class="_label">
            {{ $t('asanaForm.selectWorkspaceLabel') }}
          </div>
          <m-select
            v-model:value="localQuery.workspaceID"
            :items="dataSource[customDatasourceProperty.asanaWorkspaces]"
            value-key="gid"
            item-text="name"
            full-width
            match-trigger-width
            show-search
            @change="resetProjectSelect"
          />
        </div>
        <template v-if="showProjectSelection">
          <div class="_item">
            <div class="_label">
              {{ $t('asanaForm.selectProjectLabel') }}
            </div>
            <m-select
              v-model:value="localQuery.projectID"
              :items="dataSource[customDatasourceProperty.asanaProjects]"
              value-key="gid"
              item-text="name"
              full-width
              :loading="documentsLoading"
              match-trigger-width
              show-search
              @change="resetTaskSelect"
            />
          </div>
        </template>
        <template v-if="showTaskSelection">
          <m-content>
            <m-radio-group
              :value="localQuery.operationMode"
              :options="operationModes"
              inline
              @change="updateOperationMode"
            />
          </m-content>
          <m-content>
            <div class="_operation-mode-description">
              {{ $t(`asanaForm.operationModes.${camelCase(localQuery.operationMode)}.description`) }}
            </div>
          </m-content>
        </template>
        <template v-if="showTaskSelection">
          <template v-if="localQuery.operationMode === asanaOperationMode.singleTask">
            <div class="_item">
              <div class="_label">
                {{ $t('asanaForm.task') }}
              </div>
              <m-select
                v-model:value="localQuery.task"
                :loading="documentsLoading"
                :items="tasks"
                return-object
                value-key="gid"
                item-text="name"
                full-width
                match-trigger-width
                show-search
                tags
                automatic-color
                :placeholder="$t('asanaForm.selectTask')"
              />
            </div>
            <div class="_item">
              <div class="_label">
                {{ $t('asanaForm.completionMeasuredBy') }}
              </div>
              <div class="_section-form">
                <asana-section-form
                  :loading="documentsLoading"
                  :asana-query="localQuery"
                  :sections="localQuery.taskCompletionSections"
                  :data-source="dataSource"
                  @submit-section="(section) => submitSection('taskCompletionSections', section)"
                  @submit-status="(section) => submitStatus('taskCompletionSections', section)"
                  @add-section="(section) => addSection('taskCompletionSections', section)"
                  @del-section="(index) => delSection('taskCompletionSections', index)"
                />
              </div>
            </div>
          </template>
          <template v-if="localQuery.operationMode === asanaOperationMode.countOfTasks">
            <div
              class="_item"
            >
              <div class="_label">
                {{ $t('asanaForm.selectAssignedTo') }}
              </div>
              <m-select
                v-model:value="localQuery.countAssignedTo"
                :loading="documentsLoading"
                :items="members"
                return-object
                value-key="gid"
                item-text="name"
                full-width
                match-trigger-width
                show-search
                multiple
                tags
                automatic-color
                :placeholder="$t('asanaForm.emptyPlaceholder')"
              />
            </div>
            <div class="_section-sel">
              <div class="_and-operator">
                {{ $t('asanaForm.and') }}
              </div>
              <div class="_section-form">
                <asana-section-form
                  :loading="documentsLoading"
                  :asana-query="localQuery"
                  :sections="localQuery.countSections"
                  :data-source="dataSource"
                  @submit-section="(section) => submitSection('countSections', section)"
                  @submit-status="(section) => submitStatus('countSections', section)"
                  @add-section="(section) => addSection('countSections', section)"
                  @del-section="(index) => delSection('countSections', index)"
                />
              </div>
            </div>
          </template>
          <div
            v-if="localQuery.operationMode === asanaOperationMode.overallProgress"
            class="_overall-progress"
          >
            <div class="_input-wrapper">
              <div class="_label">
                <span class="_inner">
                  {{ $t('asanaForm.countTasks') }}
                  <div class="_help">
                    <m-tooltip>
                      <span><m-icon
                        type="question-circle"
                        size="12"
                      /></span>
                      <template #title>
                        {{ $t('asanaForm.countTasksHint') }}
                      </template>
                    </m-tooltip>
                  </div>
                </span>
              </div>
              <m-dropdown
                title=" "
                block
              >
                <m-content
                  padding-xs
                  class="_task-selection"
                >
                  <asana-task-selection-trigger
                    :sections="localQuery.sections"
                    :assigned-to="localQuery.assignedTo"
                  />
                </m-content>
                <template #overlay>
                  <m-card
                    padding
                    :style="{width: '40rem'}"
                  >
                    <m-content :padding-bottom="8">
                      <div class="_label">
                        {{ $t('asanaForm.selectAssignedTo') }}
                      </div>
                      <m-select
                        v-model:value="localQuery.assignedTo"
                        :loading="documentsLoading"
                        :items="members"
                        return-object
                        value-key="gid"
                        item-text="name"
                        full-width
                        match-trigger-width
                        show-search
                        multiple
                        tags
                        automatic-color
                        :placeholder="$t('asanaForm.emptyPlaceholder')"
                      />
                    </m-content>
                    <div class="_section-sel">
                      <div class="_and-operator">
                        {{ $t('asanaForm.and') }}
                      </div>
                      <div class="_section-form">
                        <asana-section-form
                          :loading="documentsLoading"
                          :asana-query="localQuery"
                          :sections="localQuery.sections"
                          :data-source="dataSource"
                          @submit-section="(section) => submitSection('sections', section)"
                          @submit-status="(section) => submitStatus('sections', section)"
                          @add-section="(section) => addSection('sections', section)"
                          @del-section="(index) => delSection('sections', index)"
                        />
                      </div>
                    </div>
                  </m-card>
                </template>
              </m-dropdown>
            </div>
            <div class="_divider">
              /
            </div>
            <div class="_input-wrapper">
              <div class="_label">
                {{ $t('asanaForm.totalTasks') }}
              </div>
              <m-dropdown
                :title="$t('asanaForm.totalTasks')"
                block
              >
                <m-content
                  padding-xs
                  class="_task-selection"
                >
                  <asana-task-selection-trigger
                    :sections="localQuery.overallSections"
                    :assigned-to="localQuery.overallAssignedTo"
                  />
                </m-content>
                <template #overlay>
                  <m-card
                    padding
                    :style="{width: '40rem'}"
                  >
                    <m-content :padding-bottom="8">
                      <div class="_label">
                        {{ $t('asanaForm.selectAssignedTo') }}
                      </div>
                      <m-select
                        v-model:value="localQuery.overallAssignedTo"
                        :loading="documentsLoading"
                        :items="members"
                        return-object
                        value-key="gid"
                        item-text="name"
                        full-width
                        match-trigger-width
                        show-search
                        multiple
                        tags
                        automatic-color
                        :placeholder="$t('asanaForm.emptyPlaceholder')"
                      />
                    </m-content>

                    <div class="_section-sel">
                      <div class="_and-operator">
                        {{ $t('asanaForm.and') }}
                      </div>
                      <div class="_section-form">
                        <asana-section-form
                          :loading="documentsLoading"
                          :asana-query="localQuery"
                          :sections="localQuery.overallSections"
                          :data-source="dataSource"
                          @submit-section="(section) => submitSection('overallSections', section)"
                          @submit-status="(section) => submitStatus('overallSections', section)"
                          @add-section="(section) => addSection('overallSections', section)"
                          @del-section="(index) => delSection('overallSections', index)"
                        />
                      </div>
                    </div>
                  </m-card>
                </template>
              </m-dropdown>
            </div>
          </div>
        </template>
        <div
          v-if="localQuery.projectID !== null"
          class="_item"
          :style="{ marginTop: '2rem' }"
        >
          <always-sync-checkbox v-model:value="localQuery.alwaysSync" />
        </div>
      </m-content>
      <m-divider xs />
      <template v-if="localQuery.uid === undefined">
        <m-content padding-small>
          <div class="_actions">
            <div class="_btns">
              <m-btn
                class="_btn"
                @click="$emit('close')"
              >
                {{ $t('general.cancel') }}
              </m-btn>
              <m-btn
                color="primary"
                class="_btn"
                :loading="mutateLoading"
                :disabled="!isValid"
                @click="save"
              >
                {{ $t('general.save') }}
              </m-btn>
            </div>
          </div>
        </m-content>
      </template>
      <template v-else>
        <m-card-item
          icon="save"
          :loading="mutateLoading"
          :padding-x="8"
          :disabled="!isValid"
          @click="save"
        >
          {{ $t('general.save') }}
        </m-card-item>
        <m-card-item
          icon="sync"
          :loading="syncLoading"
          :padding-x="8"
          :disabled="!isValid || hasError"
          @click="syncNow"
        >
          {{ $t('dataSource.syncNow') }}
        </m-card-item>
        <m-card-item
          icon="delete"
          :padding-x="8"
          :loading="deleteLoading"
          @click="deleteAsanaQuery"
        >
          {{ $t('general.delete') }}
        </m-card-item>
      </template>
    </template>
  </div>
</template>

<script>
import AlwaysSyncCheckbox from '@/components/datasource/AlwaysSyncCheckbox.vue';
import AsanaSectionForm from '@/components/datasource/AsanaSectionForm.vue';
import AsanaTaskSelectionTrigger from '@/components/datasource/AsanaTaskSelectionTrigger.vue';
import DataSourceQueryError from '@/components/datasource/DataSourceQueryError.vue';
import useAsana from '@/composables/integrations/asana/asana';
import { asanaCompletionStatus, asanaOperationMode, customDatasourceProperty, dataSourceQueryErrorSource, dataSourceQueryGenericError } from 'shared/constants.json';
import { camelCase } from 'lodash-es';
import { computed } from 'vue';
import { copy } from 'shared/lib/copy';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { logCatch } from '@/lib/logger/logger';

const defaultAsanaQuery = () => ({
  projectID: null,
  workspaceID: null,
  assignedTo: [],
  sections: [{ status: asanaCompletionStatus.any, gid: 'global', name: '' }],
  overallAssignedTo: [],
  overallSections: [{ status: asanaCompletionStatus.any, gid: 'global', name: '' }],
  countAssignedTo: [],
  countSections: [{ status: asanaCompletionStatus.any, gid: 'global' }],
  task: null,
  taskCompletionSections: [{ status: asanaCompletionStatus.completed, gid: 'global', name: '' }],
  operationMode: asanaOperationMode.all[0],
});

export default {
  name: 'AsanaForm',
  props: {
    dataSource: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
      default: () => null,
    },
    goal: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'deleted'],
  components: { DataSourceQueryError, AsanaSectionForm, AsanaTaskSelectionTrigger, AlwaysSyncCheckbox },
  setup() {
    const {
      createQuery, createQueryLoading, updateQuery, updateQueryLoading, deleteQuery, deleteQueryLoading,
      getProject: getAsanaProject,
      getProjectDetails: getAsanaProjectDetails,
      getProjectLoading: getAsanaProjectLoading,
    } = useAsana();

    return {
      createQuery,
      updateQuery,
      deleteQuery,
      getAsanaProject,
      getAsanaProjectDetails,
      mutateLoading: computed(() => createQueryLoading.value || updateQueryLoading.value),
      deleteLoading: deleteQueryLoading,
      documentsLoading: getAsanaProjectLoading,
    };
  },
  data() {
    return {
      localQuery: {
        projectID: null,
        ...defaultAsanaQuery(),
      },
      camelCase,
      syncLoading: false,
      projectsLoading: false,
      asanaOperationMode,
      customDatasourceProperty,
    };
  },
  computed: {
    hasConnectionError() {
      return [dataSourceQueryGenericError.incompleteSetup, dataSourceQueryGenericError.unauthenticated].includes(this.dataSource[customDatasourceProperty.dataSourceError]);
    },
    hasDataSourceError() {
      return !isNullOrUndefined(this.dataSource[customDatasourceProperty.dataSourceError]);
    },
    hasError() {
      return this.hasDataSourceError || this.localError || !isNullOrUndefined(this.query?.error);
    },
    error() {
      if (this.hasDataSourceError) {
        return this.dataSource[customDatasourceProperty.dataSourceError];
      }
      if (this.localError) {
        return this.localError;
      }
      if (this.hasError) {
        return this.query?.error;
      }
      return null;
    },
    localError() {
      switch (this.localQuery.operationMode) {
        case asanaOperationMode.singleTask:
          if (this.localQuery.task !== null && typeof this.tasks.find((t) => t.gid === this.localQuery.task.gid) === 'undefined') {
            return { source: dataSourceQueryErrorSource.asana, type: 'taskNotFound' };
          }
          return null;
        case asanaOperationMode.countOfTasks:
          if (!this.localQuery.countAssignedTo.every((user) => typeof this.members.find((u) => u.gid === user.gid) !== 'undefined')) {
            return { source: dataSourceQueryErrorSource.asana, type: 'userNotFound' };
          }
          if (!this.localQuery.countSections.every((section) => section.gid === 'global' || typeof this.sections.find((s) => s.gid === section.gid) !== 'undefined')) {
            return { source: dataSourceQueryErrorSource.asana, type: 'sectionNotFound' };
          }
          return null;
        case asanaOperationMode.overallProgress:
          if (!this.localQuery.assignedTo.every((user) => typeof this.members.find((u) => u.gid === user.gid) !== 'undefined')) {
            return { source: dataSourceQueryErrorSource.asana, type: 'userNotFound' };
          }
          if (!this.localQuery.overallAssignedTo.every((user) => typeof this.members.find((u) => u.gid === user.gid) !== 'undefined')) {
            return { source: dataSourceQueryErrorSource.asana, type: 'userNotFound' };
          }
          if (!this.localQuery.sections.every((section) => section.gid === 'global' || typeof this.sections.find((s) => s.gid === section.gid) !== 'undefined')) {
            return { source: dataSourceQueryErrorSource.asana, type: 'sectionNotFound' };
          }
          if (!this.localQuery.overallSections.every((section) => section.gid === 'global' || typeof this.sections.find((s) => s.gid === section.gid) !== 'undefined')) {
            return { source: dataSourceQueryErrorSource.asana, type: 'sectionNotFound' };
          }
          return null;
        default:
          return null;
      }
    },
    isValid() {
      return !(this.localQuery.workspaceID === null
          || this.localQuery.projectID === null);
    },
    operationModes() {
      return asanaOperationMode.all.map((mode) => ({
        value: mode,
        label: this.$t(`asanaForm.operationModes.${camelCase(mode)}.label`),
      }));
    },
    selectedProject() {
      return this.localQuery.projectID;
    },
    selectedWorkspace() {
      return this.localQuery.workspaceID;
    },
    tasks() {
      if (this.localQuery.projectID === null) {
        return [];
      }

      const project = this.dataSource[customDatasourceProperty.asanaProjects].find((p) => p.gid === this.localQuery.projectID);
      if (project === undefined) {
        return [];
      }

      if (project.tasks === null || project.tasks === undefined) {
        return [];
      }

      return project.tasks;
    },
    members() {
      if (this.localQuery.projectID === null) {
        return [];
      }

      const project = this.dataSource[customDatasourceProperty.asanaProjects].find((p) => p.gid === this.localQuery.projectID);
      if (project === undefined) {
        return this.localQuery.assignedTo;
      }

      if (project.members === null || project.members === undefined) {
        return [];
      }

      return project.members;
    },
    sections() {
      if (this.localQuery.projectID === null) {
        return [];
      }

      const project = this.dataSource[customDatasourceProperty.asanaProjects].find((p) => p.gid === this.localQuery.projectID);
      if (project === undefined) {
        return this.localQuery.sections;
      }

      if (project.sections === null || project.sections === undefined) {
        return [];
      }

      return [
        ...project.sections.map((s, index) => ({ ...s, completed: false, index })),
        ...project.sections.map((s, index) => ({
          ...s,
          name: `${s.name} - ${this.$t('asanaForm.completed')}`,
          completed: true,
          index: index + project.sections.length,
        })),
        { name: this.$t('asanaForm.completed'), completed: true, index: project.sections.length },
      ];
    },
    projectName() {
      if (this.localQuery.projectID === null) {
        return null;
      }

      const project = this.dataSource[customDatasourceProperty.asanaProjects].find((p) => p.gid === this.localQuery.projectID);
      if (project === undefined) {
        return this.localQuery.projectName;
      }

      return project.name;
    },
    workspaceName() {
      if (this.localQuery.workspaceID === null) {
        return null;
      }

      const workspace = this.dataSource[customDatasourceProperty.asanaWorkspaces].find((w) => w.gid === this.localQuery.workspaceID);
      if (typeof workspace === 'undefined') {
        return this.localQuery.workspaceName;
      }

      return workspace.name;
    },
    showTaskSelection() {
      if (this.localQuery.workspaceID === null) {
        return false;
      }

      if (this.localQuery.projectID === null) {
        return false;
      }

      if (this.documentsLoading) {
        return false;
      }

      return this.dataSource[customDatasourceProperty.asanaProjects].length > 0;
    },
    showProjectSelection() {
      return this.localQuery.workspaceID !== null;
    },
  },
  methods: {
    resetProjectSelect() {
      this.localQuery = {
        ...this.localQuery,
        ...defaultAsanaQuery(),
        workspaceID: this.localQuery.workspaceID,
      };
    },
    resetTaskSelect() {
      this.localQuery = {
        ...this.localQuery,
        ...defaultAsanaQuery(),
        workspaceID: this.localQuery.workspaceID,
        projectID: this.localQuery.projectID,
      };
    },
    save() {
      if (this.localQuery.uid === undefined) {
        this.createAsanaQuery(this.localQuery);
        return;
      }

      this.updateAsanaQuery(this.localQuery);
    },
    createAsanaQuery(asanaQuery) {
      this.createQuery({
        ...asanaQuery,
        projectName: this.projectName,
        workspaceName: this.workspaceName,
        dataSource: { uid: this.dataSource.uid },
        goal: { uid: this.goal.uid },
      }).then((query) => {
        this.localQuery = copy(query);
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    updateAsanaQuery(asanaQuery) {
      this.updateQuery({
        ...asanaQuery,
        projectName: this.projectName,
        workspaceName: this.workspaceName,
        dataSource: { uid: this.dataSource.uid },
      }).then((query) => {
        this.localQuery = copy(query);
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    syncNow() {
      this.syncLoading = true;
      this.updateQuery(this.goal.asanaQuery).then((query) => {
        this.localQuery = copy(query);
        this.$showSnackbar({ color: 'success', message: this.$t('dataSource.successfullySynced') });
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      })).finally(() => {
        this.syncLoading = false;
      });
    },
    getProjects(project) {
      this.getAsanaProject(this.dataSource, project).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    getProjectDetails(project) {
      this.getAsanaProjectDetails(this.dataSource, project).then(() => {
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    deleteAsanaQuery() {
      const deleteMethod = () => this.deleteQuery(this.goal.asanaQuery.uid).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.deleted') });
        this.$emit('deleted');
        this.$emit('close');
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });

      this.$confirm({
        title: this.$t('dataSource.deleteConnectionPrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
    updateOperationMode(value) {
      this.localQuery.operationMode = value;
    },
    submitSection(key, { index, section }) {
      const res = { ...this.localQuery[key][index], gid: section.gid, name: section.name };
      this.localQuery[key].splice(index, 1, res);
    },
    submitStatus(key, { index, status }) {
      this.localQuery[key].splice(index, 1, { ...this.localQuery[key][index], status });
    },
    addSection(key, section) {
      this.localQuery[key].push(section);
    },
    delSection(key, index) {
      this.localQuery[key].splice(index, 1);
    },
  },
  watch: {
    selectedProject(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      if (newVal !== null && newVal !== undefined) {
        this.getProjectDetails({ gid: newVal });
      }
    },
    selectedWorkspace(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      if (newVal !== null && newVal !== undefined) {
        this.getProjects({ workspace: newVal });
      }
    },
  },
  created() {
    if (this.query !== null) {
      this.localQuery = copy(this.query);
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._section-sel {
    display: flex;

    ._and-operator {
      flex: 0 0 4rem;
      margin-top: 3.6rem;
    }

    ._section-form {
      flex: 1 1 auto;
    }
  }

  ._label {
    margin-bottom: .4rem;
    font-size: $font-size-2;
    color: $font-color-secondary;

    ._inner {
      position: relative;

      ._help {
        position: absolute;
        top: .1rem;
        right: -1.6rem;
        cursor: help;
      }
    }
  }

  .asana-form {
    ._operation-selection {
      display: flex;
      margin-bottom: 1.2rem;
    }

    ._operation-mode-description {
      margin-bottom: 1.2rem;
      font-size: $font-size-2;
      color: $font-color-secondary;
    }

    ._item {
      margin-bottom: 1.4rem;

      ._label {
        margin-bottom: .4rem;
        color: $font-color-secondary;
      }

      &._single-switch {
        margin-top: 2rem;
      }

      ._link {
        display: flex;

        ._icon {
          margin-left: .4rem;
        }
      }
    }

    ._actions {
      display: flex;

      ._btns {
        display: flex;
        margin-left: auto;

        ._btn {
          margin-left: .4rem;
        }
      }
    }

    ._overall-progress {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      ._input-wrapper {
        display: flex;
        flex: 0 0 calc(50% - 2rem);
        flex-direction: column;
        max-width: calc(50% - 2rem);

        ._task-selection {
          display: flex;
          align-items: center;
          width: 100%;
          height: $input-height-default;
          cursor: pointer;
          border: 1px solid $input-border-color;
          border-radius: $default-border-radius;

          &:hover {
            border-color: $primary-color;
          }
        }
      }

      ._divider {
        display: flex;
        flex: 0 0 4rem;
        align-items: center;
        justify-content: center;
        margin-bottom: .4rem;
      }
    }
  }
</style>
