import useAsanaDatasourceRepo from '@/composables/integrations/asana/asana-datasource-repo';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useRepo from '@/nebula/repo';
import { asanaQuery as asanaQueryConfig } from 'shared/api/query/configs.json';
import { customDatasourceProperty } from 'shared/constants.json';

export default function useAsana() {
  const queryRepo = useRepo(asanaQueryConfig.model);
  const datasourceRepo = useAsanaDatasourceRepo();

  const { loggedInUser } = useLoggedInUser();

  const createQuery = (entity) => queryRepo.createSingle({
    ...entity,
    creator: { uid: loggedInUser.value.uid },
  });

  const getProject = (datasource, project) => datasourceRepo.getSingle({
    ...datasource,
    [customDatasourceProperty.asanaProjects]: [project],
  });

  const getProjectDetails = (datasource, project) => datasourceRepo.getSingleDetails({
    ...datasource,
    [customDatasourceProperty.asanaProjects]: [project],
  });

  return {
    createQueryLoading: queryRepo.createLoading,
    createQuery,
    updateQueryLoading: queryRepo.updateLoading,
    updateQuery: (entity) => queryRepo.updateSingle(entity, { ignoreResponse: false }),
    deleteQueryLoading: queryRepo.deleteLoading,
    deleteQuery: queryRepo.deleteSingle,
    getProjectLoading: datasourceRepo.getSingleLoading,
    getProject,
    getProjectDetailsLoading: datasourceRepo.getSingleDetailsLoading,
    getProjectDetails,
  };
}
