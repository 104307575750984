<template>
  <div
    :class="['goal-cycle-navigator', inCardMenu ? '-mobile' : '']"
  >
    <div
      v-if="!inCardMenu && !readOnly"
      class="_left"
    >
      <m-btn
        :disabled="previous === null"
        hide-border
        small
        @click.stop="handlePrevious"
      >
        <m-icon
          type="chevron-left"
          :color="$colors.grey.base"
        />
      </m-btn>
    </div>
    <component
      :is="inCardMenu ? 'm-card-item' : 'm-btn'"
      :disabled="readOnly"
      small
      hide-border
      class="_btn"
      :icon="inCardMenu ? 'retweet' : ''"
      :light="!inCardMenu"
      @click="$emit('click-display')"
    >
      <div class="_inner">
        {{ buttonText }}
        <m-tooltip class="_tooltip">
          <m-icon
            v-if="isCycleLockedOnView && !readOnly"
            :color="$colors.grey.base"
            type="lock"
          />
          <template #title>
            {{ $t('goalCycleSelector.tooltipLockCycleIcon') }}
          </template>
        </m-tooltip>
      </div>
    </component>

    <div
      v-if="!inCardMenu"
      class="_right"
    >
      <m-btn
        v-if="!readOnly"
        :disabled="next === null"
        hide-border
        small
        @click.stop="handleNext"
      >
        <m-icon
          type="chevron-right"
          :color="$colors.grey.base"
        />
      </m-btn>
    </div>
  </div>
</template>

<script>
import { UNASSIGNED } from '@/lib/constants';

export default {
  name: 'GoalCycleNavigator',
  props: {
    inCardMenu: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isCycleLockedOnView: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['click-display', 'previous', 'next'],
  computed: {
    buttonText() {
      if (this.selected.length === 0) {
        return this.$t('goalCycleSelector.all');
      }

      const unassignedItem = this.selected.find((c) => c.uid === UNASSIGNED);

      const filtered = this.items.filter((g) => this.selected.map((c) => c.uid).includes(g.uid));
      if (filtered.length === 0 && unassignedItem === undefined) {
        return this.$t('goalCycleSelector.noneSelected');
      }
      if (unassignedItem !== undefined) {
        filtered.push(unassignedItem);
      }

      if (filtered.length < 3) {
        return filtered.map((c) => this.abbreviate(c.title, 10)).join(', ');
      }

      return `${this.abbreviate(filtered[0].title, 10)}, +${filtered.length - 1} ${this.$t('goalCycleSelector.more')}`;
    },
    previous() {
      if (this.selected.length === 0) {
        if (this.items.length > 0) {
          return this.items[0];
        }
        return null;
      }

      let previousIndex = -1;
      const selectedUids = this.selected.map((c) => c.uid);
      for (let i = this.items.length - 1; i >= 0; i--) {
        if (selectedUids.includes(this.items[i].uid)) {
          previousIndex = i - 1;
        }
      }

      if (previousIndex <= -1) {
        return null;
      }
      return this.items[previousIndex];
    },
    next() {
      if (this.selected.length === 0) {
        if (this.items.length > 0) {
          return this.items[this.items.length - 1];
        }
        return null;
      }

      let nextIndex = -1;
      const selectedUids = this.selected.map((c) => c.uid);
      for (let i = 0; i < this.items.length; i++) {
        if (selectedUids.includes(this.items[i].uid)) {
          nextIndex = i + 1;
        }
      }

      if (nextIndex === -1 || nextIndex >= this.items.length) {
        return null;
      }
      return this.items[nextIndex];
    },
  },
  methods: {
    handlePrevious() {
      if (this.previous !== null) {
        this.$emit('previous', [this.previous]);
      }
    },
    handleNext() {
      if (this.next !== null) {
        this.$emit('next', [this.next]);
      }
    },
    abbreviate(text, maxLength) {
      if (text.length > maxLength) {
        return `${text.substring(0, maxLength)}...`;
      }
      return text;
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .goal-cycle-navigator {
    display: flex;

    ._btn {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .tooltip {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      ._inner {
        display: flex;
        align-items: center;

        ._tooltip {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          margin-left: 0.5rem
        }
      }
    }

    &.-mobile {
      width: 100%;

      ._btn {
        max-width: 100%;
      }
    }
  }
</style>
