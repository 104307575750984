import useViewCollections from '@/composables/saved-views/view-collections';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { computed } from 'vue';
import { findViews } from '@/lib/saved-view/saved-view';
import { shallowCopy } from 'shared/lib/copy';
import { sortByArray } from 'shared/lib/sort';

export default function useViews({
  application,
  space = null,
  planning = null,
  user = null,
  gridPageTile = null,
  selectedViewsSvc,
  changeSelectedView,
  repo,
}) {
  const selectedViewForApplication = selectedViewsSvc.getSelectedViewForApplication({
    application,
    space,
    planning,
    user,
    gridPageTile,
  });

  const hasSavedViews = computed(() => repo.views.value.length > 0);

  // selected view has the following order for selecting a view:
  // 1. if no saved view, select default
  // 2. if a selected view for this view application + property option combination exists, choose this view
  // 3. Select the first saved view
  const initCurrentView = () => {
    if (!hasSavedViews.value) {
      changeSelectedView({ uid: 0 });
      return;
    }

    let view;
    if (selectedViewForApplication.value.uid !== 0) {
      view = repo.views.value.find((v) => selectedViewForApplication.value.view.uid === v.uid);
    }

    if (view === undefined) {
      view = repo.views.value[0];
    }

    try {
      changeSelectedView(view);
    } catch (e) { /* do nothing, handling faulty user input */
    }
  };

  const createViews = (views, viewOrder) => repo.createViews(views, viewOrder)
    .then((views) => {
      views.forEach(() => {
        EventBus.$emit(EVENTS.VIEW.VIEW_CREATED);
      });
      return views;
    });

  const viewCollectionSvc = useViewCollections();
  const viewCollection = computed(() => {
    const viewCollections = findViews({
      views: viewCollectionSvc.viewCollections.value,
      application,
      space,
      planning,
      user,
      gridPageTile,
    });
    if (viewCollections.length === 0) {
      return null;
    }
    return viewCollections[0];
  });

  const deleteView = (view) => repo.deleteView(view).then(() => {
    EventBus.$emit(EVENTS.VIEW.VIEW_DELETED);
  });

  const views = computed(() => {
    if (viewCollection.value === null) {
      return repo.views.value;
    }
    const cp = shallowCopy(repo.views.value);
    cp.sort(sortByArray(viewCollection.value.viewOrder));
    return cp;
  });

  return {
    createLoading: repo.createLoading,
    createViews,

    loadingUpdateView: repo.loadingUpdateView,
    updateView: repo.updateView,

    loadingUpdateMetaData: repo.loadingUpdateMetaData,
    updateMetaData: repo.updateMetaData,

    loadingDeleteView: repo.loadingDeleteView,
    deleteView,

    setParams: repo.setParams,
    updateSort: repo.updateSort,
    updateCycles: repo.updateCycles,
    updateLockCyclesOnView: repo.updateLockCyclesOnView,

    resetView: repo.resetView,

    hasUnsavedChanges: repo.hasUnsavedChanges,

    views,
    viewCollection,

    initCurrentView,
    selectedView: selectedViewForApplication,
  };
}
